import { useAdminUpdateHolidayMutation, _Holiday } from '@generated/graphql'

import { Controller, useForm } from 'react-hook-form'

import { toast } from 'react-toastify'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { ErrorMsg } from '@component/ErrorMsg'
import { useCallback, useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { DevTool } from '@hookform/devtools'
import { nextTick } from 'process'

const formatForDatelocal = (day: Date) => {
  return dayjs(day).format('YYYY-MM-DDTHH:mm')
}
const schema = z.object({
  name: z.string().min(1),
  start_at: z.date(),
  end_at: z.date(),
  is_holiday: z.boolean(),
  is_stop_using: z.boolean(),
})

type RecoilType = {
  open: boolean
  holiday?: _Holiday
  onClose?: () => void
}
const recoilHoriday = atom<RecoilType>({
  key: 'recoilHoriday',
  default: {
    open: false,
  },
})

const useOpenHolidayUpdateForm = () => {
  const setRecoil = useSetRecoilState(recoilHoriday)
  const cb = useCallback(
    (holiday?: _Holiday, onClose?: () => void) => {
      setRecoil({
        open: true,
        holiday,
        onClose,
      })
    },
    [setRecoil],
  )
  return cb
}

type FieldInput = z.infer<typeof schema>
const UpdateHolidayForm = () => {
  const r = useRecoilValue(recoilHoriday)
  if (r.open) {
    return <UpdateHolidayFormBody {...r} />
  } else {
    return null
  }
}
const UpdateHolidayFormBody = ({ holiday, onClose }: { holiday?: _Holiday; onClose?: () => void }) => {
  const [open, setOpen] = useState(false)
  const reset = useResetRecoilState(recoilHoriday)

  const [updateHoliday] = useAdminUpdateHolidayMutation()

  const closeMe = () => {
    onClose?.()
    reset()
  }

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FieldInput>({
    resolver: zodResolver(schema),
    defaultValues: holiday
      ? { ...holiday }
      : { start_at: dayjs().startOf('day').toDate(), end_at: dayjs().endOf('day').toDate() },
  })

  const onSubmit = handleSubmit(async (form) => {
    if (window.confirm('データを更新します。よろしいですか？')) {
      try {
        const { data } = await updateHoliday({
          variables: {
            id: holiday ? String(holiday?.id) : undefined,
            input: {
              ...form,
            },
          },
        })
        if (data?._updateHoliday?.id) {
          toast.success('更新しました')
          closeMe()
        } else {
          toast.error(`更新に失敗しました`)
        }
      } catch (errors) {
        toast.error(`更新に失敗しました`)
      }
    }
  })

  useEffect(() => {
    nextTick(() => {
      setOpen(true)
    })
  }, [setOpen])
  return (
    <>
      <input type="checkbox" checked={open} readOnly className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle" onClick={closeMe}>
        <div className="modal-box p-3" onClick={(e) => e.stopPropagation()}>
          <div className="p-4 rounded-lg">
            {holiday ? (
              <div className="text-xl my-2">
                [{holiday.id}] {holiday.name} を更新
              </div>
            ) : (
              <div className="text-xl my-2">祝日 休館日時を追加</div>
            )}
            <form onSubmit={onSubmit}>
              <div className="flex gap-2 justify-evenly">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">祝日名</span>
                  </label>
                  <input type="text" {...register('name')} className="input input-bordered" />
                </div>
              </div>
              <div className="flex gap-2 justify-evenly">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">開始</span>
                  </label>
                  <Controller
                    name="start_at"
                    control={control}
                    render={(props) => (
                      <input
                        type="datetime-local"
                        {...register('start_at', { valueAsDate: true })}
                        value={formatForDatelocal(props.field.value)}
                        className="input input-bordered"
                      />
                    )}
                  />
                  <ErrorMsg error={errors.start_at} />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">終了</span>
                  </label>
                  <Controller
                    name="end_at"
                    control={control}
                    render={(props) => (
                      <input
                        type="datetime-local"
                        {...register('end_at', { valueAsDate: true })}
                        className="input input-bordered"
                        value={formatForDatelocal(props.field.value)}
                      />
                    )}
                  />
                  <ErrorMsg error={errors.end_at} />
                </div>
              </div>
              <div className="flex gap-2 justify-evenly">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">祝日</span>
                  </label>
                  <input type="checkbox" className="checkbox" {...register('is_holiday')} />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">休館</span>
                  </label>
                  <input type="checkbox" className="checkbox" {...register('is_stop_using')} />
                </div>
              </div>
              <input type="submit" className={`btn btn-warning my-4 w-full`} value="保存" />
              <div className={`btn my-4 w-full`} onClick={closeMe}>
                キャンセル
              </div>
            </form>
          </div>
        </div>
        <DevTool control={control} />
      </div>
    </>
  )
}
export { UpdateHolidayForm, useOpenHolidayUpdateForm }
