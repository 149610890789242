import { useAdminDeleteHolidayMutation, useHolidaysQuery } from '@generated/graphql'
import { formatYMDHM } from '@util/format'
import { toast } from 'react-toastify'

import { UpdateHolidayForm, useOpenHolidayUpdateForm } from './form/Holiday'

const AdminHolidaysView = () => {
  const openForm = useOpenHolidayUpdateForm()
  const { data: { _holidays } = {}, refetch } = useHolidaysQuery({
    variables: {},
    fetchPolicy: 'network-only',
  })
  const [deleteMutation] = useAdminDeleteHolidayMutation()

  if (!_holidays) {
    return null
  }
  // const submit = async (id: string, auth: boolean, is_Company: boolean = false) => {
  //   if (!id) {
  //     return
  //   }
  //   if (window.confirm(auth ? '承認します' : '却下します')) {
  //     await mutation({ variables: { input: { id, auth, is_Company } } })
  //     toast.success('更新しました。')
  //     await refetch()
  //   }
  // }
  return (
    <div className="container mx-auto">
      {/* <AdminBuyView /> */}
      <div className="p-4 mb-4">
        <div className="flex items-center">
          <div className="font-bold flex-1">祝日 休館設定</div>
        </div>

        <div className="p-4">
          {_holidays.length > 0 ? (
            <table className="table w-full table-compact">
              <thead>
                <tr>
                  <td>ID</td>
                  <td>祝日名</td>
                  <td>開始日時</td>
                  <td>終了日時</td>
                  <td>祝日扱い</td>
                  <td>休館</td>
                  <td>
                    <div
                      className="btn btn-sm"
                      onClick={() => {
                        openForm(undefined, () => {
                          refetch()
                        })
                      }}
                    >
                      追加
                    </div>
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {_holidays.map((h) => {
                  return (
                    <tr className="border-2 py-2" key={h.id}>
                      <td className="text-xs ">{h.id}</td>
                      <td className="text-xs ">{h.name}</td>
                      <td className="text-xs ">{formatYMDHM(h.start_at)}</td>
                      <td className="text-xs ">{formatYMDHM(h.end_at)}</td>
                      <td>
                        <input className="checkbox checkbox-sm" type="checkbox" checked={h.is_holiday} />
                      </td>
                      <td>
                        <input className="checkbox  checkbox-sm" type="checkbox" checked={h.is_stop_using} />
                      </td>
                      <td>
                        <div
                          className="btn btn-sm btn-primary"
                          onClick={() => {
                            openForm(h, () => {
                              refetch()
                            })
                          }}
                        >
                          編集
                        </div>
                      </td>
                      <td>
                        <div
                          className="btn btn-sm btn-error"
                          onClick={async () => {
                            if (window.confirm(`[${h.name}]を削除します よろしいですか？`)) {
                              await deleteMutation({ variables: { id: h.id } })
                              toast.success('削除しました')
                              refetch()
                            }
                          }}
                        >
                          削除
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <div>
              設定無し{' '}
              <div
                className="btn btn-sm"
                onClick={() => {
                  openForm(undefined, () => {
                    refetch()
                  })
                }}
              >
                追加
              </div>
            </div>
          )}
        </div>
      </div>
      <UpdateHolidayForm />
    </div>
  )
}
export { AdminHolidaysView }
