import { FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { ErrorMsg } from '@component/ErrorMsg'

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { DevTool } from '@hookform/devtools'
import axios from 'axios'
import { ENV } from '@util/env'
import { LoadingHander } from '@component/LoadingHandle'

const schema = z
  .object({
    email: z.string().max(150).email({ message: 'メールアドレス形式で入力してください' }).nonempty('必須です'),
    password: z
      .string()
      .min(8, '8文字以上で設定してください')
      .max(20, '20文字以下で設定してください')
      .nonempty('必須です')
      .regex(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*\.]{8,20}$/, { message: '半角英数字を混ぜてください' }),
    password_confirm: z.string().nonempty('必須です'),
    zipcode: z.string().regex(/^[0-9]{7}$/, { message: '数字で7桁です' }),
    address: z.string().max(100).nonempty('必須です'),
    tel: z.string().regex(/^[0-9]{10,11}$/, { message: '数字で10〜11桁です' }),
    mei: z.string().max(20).nonempty('必須です'),
    mei_kana: z.string().max(20).nonempty('必須です'),
    sei: z.string().max(20).nonempty('必須です'),
    sei_kana: z.string().max(20).nonempty('必須です'),
    company_name: z.string().max(60).nullish(),
    csrf: z.string(),
  })
  .refine(
    (data) => {
      return data.password === data.password_confirm
    },
    { message: 'パスワードと異なっています', path: ['password_confirm'] },
  )
  .refine(
    (data) => {
      return data.email !== data.password
    },
    { message: 'メールアドレスとパスワードは同じものを指定できません', path: ['password_confirm'] },
  )

const schemaUpdate = z.object({
  zipcode: z.string().regex(/^[0-9]{7}$/, { message: '数字で7桁です' }),
  address: z.string().max(100).nonempty('必須です'),
  tel: z.string().regex(/^[0-9]{10,11}$/, { message: '数字で10〜11桁です' }),
  mei: z.string().max(20).nonempty('必須です'),
  mei_kana: z.string().max(20).nonempty('必須です'),
  sei: z.string().max(20).nonempty('必須です'),
  sei_kana: z.string().max(20).nonempty('必須です'),
  company_name: z.string().max(60).nullish(),
  csrf: z.string(),
})

export type UserFormData = z.infer<typeof schema> & z.infer<typeof schemaUpdate>

const UserDataForm: FC<{
  onSubmit: (formData: UserFormData) => Promise<void>
  me?: UserFormData
  loading?: boolean
}> = ({ onSubmit: submit, me, loading }) => {
  const switchRef = useRef<HTMLInputElement>(null!)
  // const [updateMutation, { loading }] = useUpdateMeMutation()
  // const [registMutation, { loading: registLoading }] = useRegisterMutation()
  const [open, setOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    setValue,
    control,
  } = useForm<UserFormData>({
    resolver: zodResolver(me ? schemaUpdate : schema),
    defaultValues: { ...me },
  })

  const execute = async () => {
    trigger().then((isValid) => {
      if (isValid) {
        switchRef.current.checked = true
      }
    })
  }
  // const onSubmit = submit ? handleSubmit(submit) : () => {}
  const onSubmit = async (form: UserFormData) => {
    await submit(form)
    if (switchRef.current) {
      switchRef.current.checked = false
    }
  }

  useEffect(() => {
    axios.post<string>(`${ENV.API_HOST_URL}/api/crons/session`).then(({ data }) => {
      console.log({ csrf: data })
      setValue('csrf', data)
      setLoaded(true)
    })
  }, [setValue])

  if (!loaded) {
    return <LoadingHander />
  }

  return (
    <>
      <div className="my-4 text-lg font-bold">{me ? '登録情報編集' : '新規会員登録'}</div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-2">
        {!me && (
          <>
            <div className="form-control">
              <label className="label">
                <span className="label-text require">メールアドレス</span>
              </label>
              <input {...register('email')} className="input input-bordered" maxLength={150} />
              <ErrorMsg error={errors.email} />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text require">パスワード</span>
              </label>
              <input type="password" {...register('password')} className="input input-bordered" maxLength={20} />
              <ErrorMsg error={errors.password} />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text require">パスワード確認</span>
              </label>
              <input
                type="password"
                {...register('password_confirm')}
                className="input input-bordered"
                maxLength={20}
              />
              <ErrorMsg error={errors.password_confirm} />
            </div>
            <input type="hidden" {...register('csrf')} />
          </>
        )}
        <div className="md:grid grid-cols-3 gap-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text require">郵便番号</span>
              <span className="text-gray-500 text-sm mx-4">ハイフンなしの７桁の数字</span>
            </label>
            <input {...register('zipcode')} className="input input-bordered" maxLength={7} />
            <ErrorMsg error={errors.zipcode} />
          </div>

          <div className="form-control col-span-2">
            <label className="label">
              <span className="label-text require">住所</span>
              <span className="text-gray-500 text-sm mx-4">法人の場合は法人の所在地を入力</span>
            </label>
            <input {...register('address')} className="input input-bordered" maxLength={100} />
            <ErrorMsg error={errors.address} />
          </div>
        </div>
        <div className="form-control my-4">
          <label className="label">
            <span className="label-text require">電話番号（日中連絡がとれる番号を入力）</span>
            <span className="text-gray-500 text-sm mx-4">ハイフンなしの10〜11桁の数字</span>
          </label>
          <input {...register('tel')} className="input input-bordered" maxLength={11} />
          <ErrorMsg error={errors.tel} />
        </div>

        <div className="md:grid grid-cols-2 gap-4">
          <div className="form-control">
            <label className="label">
              <span className="label-text require">姓</span>
            </label>
            <input {...register('sei')} className="input input-bordered" maxLength={12} />
            <ErrorMsg error={errors.sei} />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text require">名</span>
            </label>
            <input {...register('mei')} className="input input-bordered" maxLength={12} />
            <ErrorMsg error={errors.mei} />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text require">セイ</span>
            </label>
            <input {...register('sei_kana')} className="input input-bordered" maxLength={12} />
            <ErrorMsg error={errors.sei_kana} />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text require">メイ</span>
            </label>
            <input {...register('mei_kana')} className="input input-bordered" maxLength={12} />
            <ErrorMsg error={errors.mei_kana} />
          </div>
        </div>
        <div className="form-control my-4">
          <label className="label">
            <span className="label-text">法人名・屋号</span>
          </label>
          <input {...register('company_name')} className="input input-bordered" maxLength={60} />
          <ErrorMsg error={errors.company_name} />
        </div>
        {me && (
          <div className={`text-sm collapse ${open && 'collapse-open'}`}>
            <div className="collapse-title link" onClick={() => setOpen((item) => !item)}>
              ※メールアドレスを変更したい場合{' '}
            </div>
            <div className="collapse-content">
              <div className="p-2">
                <div>
                  メールアドレスを変更したい場合は、
                  <a
                    href="mailto:info@kamishihoro.com?subject=【メールアドレスの変更希望】&body=①氏名%0D%0A②法人名または屋号名%0D%0A③変更前のメールアドレス%0D%0A④変更後のメールアドレス"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    info@kamishihoro.com
                  </a>
                  宛に次の必要事項をご記入の上、メールを送ってください。管理者側で変更手続きを行います。
                </div>
                <div className="py-2">
                  <div>タイトル：【メールアドレスの変更希望】</div>
                  <div>
                    本文：
                    <ul className="pl-4">
                      <li>①氏名</li>
                      <li>②法人名または屋号名</li>
                      <li>③変更前のメールアドレス</li>
                      <li>④変更後のメールアドレス</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="form-control py-4 mt-10">
          <label htmlFor="my-modal-" className="btn btn-primary modal-button" onClick={execute}>
            登録
          </label>
          <input type="checkbox" id="my-modal-2" className="modal-toggle" ref={switchRef} />
          <div className="modal">
            <div className="modal-box">
              <p>登録します。よろしいですか。</p>
              <div className="modal-action">
                <button type="submit" className={`btn btn-primary ${loading && 'loading'}`}>
                  登録
                </button>

                <label htmlFor="my-modal-2" className={`btn ${loading && 'btn-disabled'}`}>
                  戻る
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
      <DevTool control={control} />
    </>
  )
}
export { UserDataForm }
