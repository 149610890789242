import { useServiceQuery } from '@generated/graphql'
import { marked } from 'marked'
import { useMemo } from 'react'
const KiyakuView = () => {
  const { data } = useServiceQuery()
  const service = useMemo(() => {
    return data?.service?.data?.attributes
  }, [data])

  if (!service) {
    return null
  }
  return (
    <div className="container mx-auto">
      <div>
        <div className="p-4">
          <div className="my-4 font-bold" id="kiyaku">
            利用規約
          </div>
          <div
            className="px-4"
            dangerouslySetInnerHTML={{
              __html: marked.parse(service?.kiyaku?.split('\n').join('<br />') || ''),
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export { KiyakuView }
