import {
  Enum_Reserve_Status,
  PaymentType,
  ReserveStatus,
  useAdminUpdateReserveAndVisitMutation,
  useAdminUpdateReserveMutation,
} from '@generated/graphql'

import { Controller, useForm } from 'react-hook-form'

import { toast } from 'react-toastify'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { ErrorMsg } from '@component/ErrorMsg'
import { useCallback, useEffect } from 'react'
import { ReserveStatusLabel } from '@util/format'
import dayjs from 'dayjs'
import { DevTool } from '@hookform/devtools'

const schema = z.object({
  is_used: z.boolean().nullish(),
  status: z.nativeEnum(Enum_Reserve_Status).nullish(),
  start_at: z.date(),
  end_at: z.date(),
  price: z.number(),
  memo: z.string(),
})

export type recoilUpdateReserveBody = {
  open: boolean
  reserve_id?: string
  is_used?: boolean
  memo?: string
  status?: Enum_Reserve_Status
  payment_type: PaymentType
  price: number
  start_at: Date
  end_at: Date
  visit_id?: number
  onClose?: () => void
}
const recoilUpdateReserve = atom<recoilUpdateReserveBody>({
  key: 'recoilUpdateReserve',
  default: {
    open: false,
    payment_type: PaymentType.Invoice,
    start_at: new Date(),
    end_at: new Date(),
    price: 0,
  },
})

const useOpenUpdateForm = () => {
  const setRecoil = useSetRecoilState(recoilUpdateReserve)
  const cb = useCallback(
    (data: Omit<recoilUpdateReserveBody, 'open'>) => {
      setRecoil({
        ...data,
        open: true,
      })
    },
    [setRecoil],
  )
  return cb
}

type FieldInput = z.infer<typeof schema>
const UpdateReserveForm = () => {
  const recoil = useRecoilValue(recoilUpdateReserve)
  const reset = useResetRecoilState(recoilUpdateReserve)
  const [updateReserve] = useAdminUpdateReserveMutation()
  const [updateReserveAndVisit] = useAdminUpdateReserveAndVisitMutation()

  const closeMe = () => {
    recoil.onClose?.()
    reset()
  }

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<FieldInput>({
    resolver: zodResolver(schema),
    defaultValues: { memo: recoil.memo, start_at: recoil.start_at, end_at: recoil.end_at, price: recoil.price },
  })
  const selectStatus = watch('status')

  const onSubmit = handleSubmit(async (form) => {
    if (window.confirm('データを更新します。よろしいですか？')) {
      try {
        // const status =
        //   recoil.payment_type === PaymentType.Invoice
        //     ? form.status
        //     : form.is_used
        //     ? Enum_Reserve_Status.Charged
        //     : undefined
        const status = form.status
        const startAt = dayjs(form.start_at).add(-9, 'hour').toDate()
        const endAt = dayjs(form.end_at).add(-9, 'hour').toDate()

        if (recoil.visit_id) {
          const { data } = await updateReserveAndVisit({
            variables: {
              id: String(recoil.reserve_id),
              data: {
                isUsed: form.is_used,
                status,
                memo: form.memo,
                startAt,
                endAt,
                price: recoil.payment_type === PaymentType.Invoice ? form.price : undefined,
              },
              visit_id: String(recoil.visit_id),
              visitData: { startAt, endAt, needCalendarUpdate: true },
            },
          })
        } else {
          const { data } = await updateReserve({
            variables: {
              id: String(recoil.reserve_id),
              data: {
                isUsed: form.is_used,
                status,
                memo: form.memo,
                startAt,
                endAt,
                price: recoil.payment_type === PaymentType.Invoice ? form.price : undefined,
              },
            },
          })
        }

        toast.success('更新しました')
        closeMe()
      } catch (errors) {
        toast.error(`更新に失敗しました`)
      }
    }
  })
  useEffect(() => {
    if (selectStatus && [Enum_Reserve_Status.Canceled, Enum_Reserve_Status.Settled].includes(selectStatus)) {
      setValue('is_used', false)
    }
  }, [selectStatus, setValue])
  useEffect(() => {
    recoil.payment_type === PaymentType.Card && setValue('is_used', recoil.is_used)
    setValue('status', recoil.status)
    setValue('start_at', recoil.start_at)
    setValue('end_at', recoil.end_at)
    setValue('price', recoil.price)
    setValue('memo', recoil.memo ?? '')
  }, [recoil, setValue])

  return (
    <>
      <input type="checkbox" checked={recoil.open} readOnly className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle" onClick={closeMe}>
        <div className="modal-box p-3" onClick={(e) => e.stopPropagation()}>
          <div className="p-4 rounded-lg">
            <div className="text-xl my-2">予約ID {recoil.reserve_id} を更新</div>
            <form onSubmit={onSubmit}>
              <div className="form-control">
                <select className="select select-bordered w-full max-w-xs" {...register('status')}>
                  <option value={ReserveStatus.Reserved}>{ReserveStatusLabel[ReserveStatus.Reserved]}</option>
                  <option value={ReserveStatus.Used}>{ReserveStatusLabel[ReserveStatus.Used]}</option>
                  <option value={ReserveStatus.Charged}>{ReserveStatusLabel[ReserveStatus.Charged]}</option>
                  <option value={ReserveStatus.Settled}>{ReserveStatusLabel[ReserveStatus.Settled]}</option>
                  <option value={ReserveStatus.Canceled}>{ReserveStatusLabel[ReserveStatus.Canceled]}</option>
                </select>
              </div>
              {recoil.payment_type === PaymentType.Card &&
                selectStatus &&
                ![Enum_Reserve_Status.Canceled, Enum_Reserve_Status.Settled].includes(selectStatus) && (
                  <div className="form-control">
                    <div className="flex items-center my-4">
                      <input type="checkbox" {...register('is_used')} className="checkbox" />
                      <div className="mx-4">カード決済する</div>
                    </div>
                    <ErrorMsg error={errors.is_used} />
                  </div>
                )}
              <div className="flex gap-2 justify-evenly">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">開始</span>
                  </label>
                  <Controller
                    name="start_at"
                    control={control}
                    render={(props) => (
                      <input
                        type="datetime-local"
                        {...register('start_at', { valueAsDate: true })}
                        value={dayjs(props.field.value).format('YYYY-MM-DD HH:mm')}
                        className="input input-bordered"
                      />
                    )}
                  />
                  <ErrorMsg error={errors.start_at} />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">終了</span>
                  </label>
                  <Controller
                    name="end_at"
                    control={control}
                    render={(props) => (
                      <input
                        type="datetime-local"
                        {...register('end_at', { valueAsDate: true })}
                        className="input input-bordered"
                        value={dayjs(props.field.value).format('YYYY-MM-DD HH:mm')}
                      />
                    )}
                  />
                  <ErrorMsg error={errors.end_at} />
                </div>
              </div>
              {recoil.payment_type === PaymentType.Invoice && (
                <div>
                  <div className="form-control w-full">
                    <label className="label">
                      <span className="label-text">請求金額</span>
                    </label>
                    <input
                      type="number"
                      className="input input-bordered text-right"
                      {...register('price', { valueAsNumber: true })}
                    />
                  </div>
                </div>
              )}
              <div className="form-control">
                <label className="label">
                  <span className="label-text">メモ</span>
                </label>
                <textarea {...register('memo')} className="textarea textarea-bordered" />
                <ErrorMsg error={errors.memo} />
              </div>
              <input type="submit" className={`btn btn-warning my-4 w-full`} value="保存" />
              <div className={`btn my-4 w-full`} onClick={closeMe}>
                キャンセル
              </div>
            </form>
          </div>
        </div>
        <DevTool control={control} />
      </div>
    </>
  )
}
export { UpdateReserveForm, useOpenUpdateForm }
