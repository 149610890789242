import { useCancelReserveMutation, useCancelVisitMutation } from '@generated/graphql'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'

const ReserveCancelButton: FC<{ reserve_id: string; onFinish: () => void }> = ({ reserve_id, onFinish }) => {
  const [open, setOpen] = useState(false)
  const [cancelMutation, { loading }] = useCancelReserveMutation()
  const finish = async () => {
    try {
      await cancelMutation({ variables: { input: { id: reserve_id } } })

      toast.success('購入をキャンセルしました')
      onFinish?.()
    } catch (e) {
      toast.error(`${e}`)
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <label
        className={`btn btn-warning btn-xs mx-2`}
        onClick={() => {
          setOpen(true)
        }}
      >
        購入キャンセル
      </label>

      <input type="checkbox" className="modal-toggle" checked={open} readOnly />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <p>購入をキャンセルしますか？</p>
          <div className="modal-action">
            <label className={`btn btn-primary ${loading && 'loading'}`} onClick={finish}>
              キャンセル
            </label>
            {!loading && (
              <label
                className="btn"
                onClick={() => {
                  setOpen(false)
                }}
              >
                戻る
              </label>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const VisitCancelButton: FC<{ visit_id: string; onFinish?: () => Promise<any> }> = ({ visit_id, onFinish }) => {
  const [open, setOpen] = useState(false)
  const [cancelVisitMutation, { loading }] = useCancelVisitMutation()
  const finish = async () => {
    try {
      await cancelVisitMutation({ variables: { input: { id: visit_id } } })

      toast.success('利用予約をキャンセルしました')
      onFinish?.()
    } catch (e) {
      toast.error(`${e}`)
    } finally {
      setOpen(false)
    }
  }

  return (
    <>
      <label
        className={`btn btn-warning btn-xs mx-2`}
        onClick={() => {
          setOpen(true)
        }}
      >
        来店キャンセル
      </label>

      <input type="checkbox" className="modal-toggle" checked={open} readOnly />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box">
          <p>来店をキャンセルしますか？</p>
          <div className="modal-action">
            <label className={`btn btn-primary ${loading && 'loading'}`} onClick={finish}>
              キャンセル
            </label>
            {!loading && (
              <label
                onClick={() => {
                  setOpen(false)
                }}
                className="btn"
              >
                戻る
              </label>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
export { ReserveCancelButton, VisitCancelButton }
