import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ReserveStatus, useMeQuery } from '@generated/graphql'
import { formatYMDHM } from '@util/format'
import dayjs from 'dayjs'
import { ReserveCancelButton } from './buttons'

const BuyView = () => {
  const { data: { _me: me } = {}, refetch } = useMeQuery({ fetchPolicy: 'network-only' })

  const Parts = useMemo(() => {
    if (!me?.reserves) {
      return []
    }

    return [
      {
        title: '購入一覧',
        // targets: me?.reserves.filter((reserve) => now.isBefore(reserve.end_at)),
        targets: me?.reserves.filter((i) => {
          return i.buyer_user_id === Number(me?.id)
        }),
        Msg: () => (
          <div className="my-2">
            購入がありません
            <Link className="btn btn-primary btn-sm ml-4" to={'/reserve'}>
              購入する
            </Link>
          </div>
        ),
      },
    ]
  }, [me])

  return (
    <div className="container mx-auto px-2">
      <div>
        {Parts.map(({ title, targets, Msg }, idx) => {
          const lastOfToday = dayjs().endOf('day')
          return (
            <div className="p-4" key={idx}>
              <div className="font-bold text-lg">{title}</div>
              {targets.length === 0 ? (
                <Msg />
              ) : (
                <div className="w-full overflow-scroll max-h-[60vh] border-2">
                  <table className="table table-compact w-full">
                    <thead className="sticky top-0 z-20">
                      <tr>
                        <td>購入日時</td>
                        <td>プラン名</td>
                        <td>
                          金額
                          <br />
                          支払い方法
                        </td>
                        <td>※キャンセルは前日まで</td>
                      </tr>
                    </thead>
                    <tbody>
                      {targets
                        .sort((before, after) => {
                          return before.created_at <= after.created_at ? 1 : -1
                        })
                        .map((reserve) => {
                          return (
                            <tr
                              key={reserve.id}
                              className={`${
                                reserve.status === ReserveStatus.Canceled ? 'bg-gray-200 text-gray-500' : 'bg-white'
                              }`}
                            >
                              <td className="text-xs bg-transparent">{formatYMDHM(reserve.created_at)}</td>
                              <td className="bg-transparent">
                                <div className="">
                                  {reserve?.plan?.name}
                                  <br />
                                  {formatYMDHM(reserve.start_at)} 〜 {formatYMDHM(reserve.end_at)}
                                </div>
                              </td>
                              <td className="bg-transparent">
                                {reserve.price.toLocaleString()} 円<br />
                                {reserve.payment_type === 'invoice' ? '請求書' : 'クレジットカード'}
                              </td>
                              <td className="bg-transparent">
                                {reserve.status !== ReserveStatus.Canceled &&
                                  dayjs(reserve.end_at).isAfter(lastOfToday) &&
                                  !reserve.plan.with_visit && (
                                    <>
                                      {!reserve.is_authed && (
                                        <div className="btn btn-disabled btn-xs mx-2">利用審査中</div>
                                      )}
                                    </>
                                  )}
                                {reserve.status === ReserveStatus.Reserved && (
                                  <>
                                    {dayjs(reserve.start_at).isAfter(lastOfToday) ? (
                                      <ReserveCancelButton reserve_id={reserve.id} onFinish={refetch} />
                                    ) : (
                                      <div>利用中</div>
                                    )}
                                  </>
                                )}
                                {reserve.cardPaymentDetail?.settle && <span>決済済み</span>}
                                {reserve.status === ReserveStatus.Canceled && <span>キャンセル済み</span>}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                    <tfoot className="sticky bottom-0 z-20">
                      <tr>
                        <th className="text-xs" colSpan={4}>
                          {targets.length}件
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
export { BuyView }
