import { useNavigate } from 'react-router-dom'
import { UserDataForm } from './UserDataForm'

import { toast } from 'react-toastify'
import { useRegisterMutation } from '@generated/graphql'

const RegistUser = () => {
  const navigate = useNavigate()
  const [registMutation, { loading }] = useRegisterMutation()
  return (
    <>
      <div className="container mx-auto px-2">
        <UserDataForm
          // onSubmit={() => {
          //   toast.success(
          //     '仮登録が完了し、登録されたメールアドレスに確認のメールを送信しました。届いたメールにあるリンクをクリックして登録を完了してください。',
          //   )
          //   navigate('/regist/confirm')
          // }}
          loading={loading}
          onSubmit={async (form) => {
            const { password_confirm, password, email, ...sendData } = form
            try {
              const { sei_kana: seiKana, mei_kana: meiKana, company_name: companyName, ...newData } = sendData
              await registMutation({
                variables: {
                  user: {
                    ...newData,
                    email,
                    seiKana,
                    meiKana,
                    companyName,
                    password,
                    username: email, //v4(),
                  },
                },
              })
              toast.success(
                '仮登録が完了し、登録されたメールアドレスに確認のメールを送信しました。届いたメールにあるリンクをクリックして登録を完了してください。',
              )
              navigate('/regist/confirm')
            } catch (e: any) {
              const message =
                'Error: Email is already taken' === e.toString()
                  ? 'すでに登録済みのメールアドレスです。他のアドレスを使用してください。'
                  : e.toString()
              toast.error(message)
            }
          }}
        />
      </div>
    </>
  )
}

export { RegistUser }
