import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddSubscriptionInput = {
  access_code: Scalars['String'];
};

export type AuthSubscriptionInput = {
  auth: Scalars['Boolean'];
  id: Scalars['ID'];
  is_Company: Scalars['Boolean'];
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type CardPaymentDetail = {
  __typename?: 'CardPaymentDetail';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  paymentOrderId: Scalars['String'];
  reserveId: Scalars['Int'];
  settle: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardPaymentDetailEntity = {
  __typename?: 'CardPaymentDetailEntity';
  attributes?: Maybe<CardPaymentDetail>;
  id?: Maybe<Scalars['ID']>;
};

export type CardPaymentDetailEntityResponse = {
  __typename?: 'CardPaymentDetailEntityResponse';
  data?: Maybe<CardPaymentDetailEntity>;
};

export type CardPaymentDetailEntityResponseCollection = {
  __typename?: 'CardPaymentDetailEntityResponseCollection';
  data: Array<CardPaymentDetailEntity>;
  meta: ResponseCollectionMeta;
};

export type CardPaymentDetailFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CardPaymentDetailFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CardPaymentDetailFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CardPaymentDetailFiltersInput>>>;
  paymentOrderId?: InputMaybe<StringFilterInput>;
  reserveId?: InputMaybe<IntFilterInput>;
  settle?: InputMaybe<BooleanFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CardPaymentDetailInput = {
  paymentOrderId?: InputMaybe<Scalars['String']>;
  reserveId?: InputMaybe<Scalars['Int']>;
  settle?: InputMaybe<Scalars['Boolean']>;
};

export type CardPaymentLog = {
  __typename?: 'CardPaymentLog';
  cardPaymentDetailId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  log: Scalars['JSON'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CardPaymentLogEntity = {
  __typename?: 'CardPaymentLogEntity';
  attributes?: Maybe<CardPaymentLog>;
  id?: Maybe<Scalars['ID']>;
};

export type CardPaymentLogEntityResponse = {
  __typename?: 'CardPaymentLogEntityResponse';
  data?: Maybe<CardPaymentLogEntity>;
};

export type CardPaymentLogEntityResponseCollection = {
  __typename?: 'CardPaymentLogEntityResponseCollection';
  data: Array<CardPaymentLogEntity>;
  meta: ResponseCollectionMeta;
};

export type CardPaymentLogFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CardPaymentLogFiltersInput>>>;
  cardPaymentDetailId?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  log?: InputMaybe<JsonFilterInput>;
  not?: InputMaybe<CardPaymentLogFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CardPaymentLogFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CardPaymentLogInput = {
  cardPaymentDetailId?: InputMaybe<Scalars['Int']>;
  log?: InputMaybe<Scalars['JSON']>;
};

export type Category = {
  __typename?: 'Category';
  createdAt?: Maybe<Scalars['DateTime']>;
  isOnlyTop: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  plans?: Maybe<PlanRelationResponseCollection>;
  sort: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CategoryPlansArgs = {
  filters?: InputMaybe<PlanFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  attributes?: Maybe<Category>;
  id?: Maybe<Scalars['ID']>;
};

export type CategoryEntityResponse = {
  __typename?: 'CategoryEntityResponse';
  data?: Maybe<CategoryEntity>;
};

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection';
  data: Array<CategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isOnlyTop?: InputMaybe<BooleanFilterInput>;
  message?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  plans?: InputMaybe<PlanFiltersInput>;
  sort?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryInput = {
  isOnlyTop?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  plans?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type Coupon = {
  __typename?: 'Coupon';
  code: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  is_deleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CouponEntity = {
  __typename?: 'CouponEntity';
  attributes?: Maybe<Coupon>;
  id?: Maybe<Scalars['ID']>;
};

export type CouponEntityResponse = {
  __typename?: 'CouponEntityResponse';
  data?: Maybe<CouponEntity>;
};

export type CouponEntityResponseCollection = {
  __typename?: 'CouponEntityResponseCollection';
  data: Array<CouponEntity>;
  meta: ResponseCollectionMeta;
};

export type CouponFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CouponFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  is_deleted?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CouponFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CouponFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CouponInput = {
  code?: InputMaybe<Scalars['String']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export enum Enum_Plan_Unit {
  Day = 'day',
  Minutes = 'minutes',
  Month = 'month',
  Year = 'year'
}

export enum Enum_Reserve_Paymenttype {
  Card = 'card',
  Coupon = 'coupon',
  Invoice = 'invoice'
}

export enum Enum_Reserve_Status {
  Canceled = 'canceled',
  Charged = 'charged',
  Reserved = 'reserved',
  Settled = 'settled',
  Used = 'used'
}

export type Email = {
  __typename?: 'Email';
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EmailEntity = {
  __typename?: 'EmailEntity';
  attributes?: Maybe<Email>;
  id?: Maybe<Scalars['ID']>;
};

export type EmailEntityResponse = {
  __typename?: 'EmailEntityResponse';
  data?: Maybe<EmailEntity>;
};

export type EmailEntityResponseCollection = {
  __typename?: 'EmailEntityResponseCollection';
  data: Array<EmailEntity>;
  meta: ResponseCollectionMeta;
};

export type EmailFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<EmailFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<EmailFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EmailFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type EmailInput = {
  body?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = CardPaymentDetail | CardPaymentLog | Category | Coupon | Email | Holiday | I18NLocale | Item | Plan | Reserve | Service | SocialLoginOnetime | SocialLoginSite | SocialLoginToken | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | Visit;

export type Holiday = {
  __typename?: 'Holiday';
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  isHoliday: Scalars['Boolean'];
  isStopUsing: Scalars['Boolean'];
  name: Scalars['String'];
  startAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HolidayEntity = {
  __typename?: 'HolidayEntity';
  attributes?: Maybe<Holiday>;
  id?: Maybe<Scalars['ID']>;
};

export type HolidayEntityResponse = {
  __typename?: 'HolidayEntityResponse';
  data?: Maybe<HolidayEntity>;
};

export type HolidayEntityResponseCollection = {
  __typename?: 'HolidayEntityResponseCollection';
  data: Array<HolidayEntity>;
  meta: ResponseCollectionMeta;
};

export type HolidayFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<HolidayFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  endAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isHoliday?: InputMaybe<BooleanFilterInput>;
  isStopUsing?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<HolidayFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<HolidayFiltersInput>>>;
  startAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type HolidayInput = {
  endAt?: InputMaybe<Scalars['DateTime']>;
  end_at: Scalars['DateTime'];
  isHoliday?: InputMaybe<Scalars['Boolean']>;
  isStopUsing?: InputMaybe<Scalars['Boolean']>;
  is_holiday: Scalars['Boolean'];
  is_stop_using: Scalars['Boolean'];
  name: Scalars['String'];
  startAt?: InputMaybe<Scalars['DateTime']>;
  start_at: Scalars['DateTime'];
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type Item = {
  __typename?: 'Item';
  amount: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  detail?: Maybe<Scalars['String']>;
  images?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  plans?: Maybe<PlanRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ItemImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ItemPlansArgs = {
  filters?: InputMaybe<PlanFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ItemEntity = {
  __typename?: 'ItemEntity';
  attributes?: Maybe<Item>;
  id?: Maybe<Scalars['ID']>;
};

export type ItemEntityResponse = {
  __typename?: 'ItemEntityResponse';
  data?: Maybe<ItemEntity>;
};

export type ItemEntityResponseCollection = {
  __typename?: 'ItemEntityResponseCollection';
  data: Array<ItemEntity>;
  meta: ResponseCollectionMeta;
};

export type ItemFiltersInput = {
  amount?: InputMaybe<IntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ItemFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  detail?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ItemFiltersInput>>>;
  plans?: InputMaybe<PlanFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ItemInput = {
  amount?: InputMaybe<Scalars['Int']>;
  detail?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  plans?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ItemRelationResponseCollection = {
  __typename?: 'ItemRelationResponseCollection';
  data: Array<ItemEntity>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _addSubscription?: Maybe<Scalars['Boolean']>;
  _authSubscription?: Maybe<Scalars['Boolean']>;
  _cancelReserve?: Maybe<Scalars['Boolean']>;
  _cancelVisit?: Maybe<Scalars['Boolean']>;
  _createReserve?: Maybe<_MyReserve>;
  _createVisit?: Maybe<Scalars['Boolean']>;
  _deleteHoliday?: Maybe<_Holiday>;
  _onetimeLogin?: Maybe<OnetimeLogin>;
  _socialLogin?: Maybe<_SocialLoginSite>;
  _updateHoliday?: Maybe<_Holiday>;
  _updateMe?: Maybe<Scalars['Boolean']>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createCardPaymentDetail?: Maybe<CardPaymentDetailEntityResponse>;
  createCardPaymentLog?: Maybe<CardPaymentLogEntityResponse>;
  createCategory?: Maybe<CategoryEntityResponse>;
  createCoupon?: Maybe<CouponEntityResponse>;
  createEmail?: Maybe<EmailEntityResponse>;
  createHoliday?: Maybe<HolidayEntityResponse>;
  createItem?: Maybe<ItemEntityResponse>;
  createPlan?: Maybe<PlanEntityResponse>;
  createReserve?: Maybe<ReserveEntityResponse>;
  createSocialLoginOnetime?: Maybe<SocialLoginOnetimeEntityResponse>;
  createSocialLoginSite?: Maybe<SocialLoginSiteEntityResponse>;
  createSocialLoginToken?: Maybe<SocialLoginTokenEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  createVisit?: Maybe<VisitEntityResponse>;
  deleteCardPaymentDetail?: Maybe<CardPaymentDetailEntityResponse>;
  deleteCardPaymentLog?: Maybe<CardPaymentLogEntityResponse>;
  deleteCategory?: Maybe<CategoryEntityResponse>;
  deleteCoupon?: Maybe<CouponEntityResponse>;
  deleteEmail?: Maybe<EmailEntityResponse>;
  deleteHoliday?: Maybe<HolidayEntityResponse>;
  deleteItem?: Maybe<ItemEntityResponse>;
  deletePlan?: Maybe<PlanEntityResponse>;
  deleteReserve?: Maybe<ReserveEntityResponse>;
  deleteService?: Maybe<ServiceEntityResponse>;
  deleteSocialLoginOnetime?: Maybe<SocialLoginOnetimeEntityResponse>;
  deleteSocialLoginSite?: Maybe<SocialLoginSiteEntityResponse>;
  deleteSocialLoginToken?: Maybe<SocialLoginTokenEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteVisit?: Maybe<VisitEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateCardPaymentDetail?: Maybe<CardPaymentDetailEntityResponse>;
  updateCardPaymentLog?: Maybe<CardPaymentLogEntityResponse>;
  updateCategory?: Maybe<CategoryEntityResponse>;
  updateCoupon?: Maybe<CouponEntityResponse>;
  updateEmail?: Maybe<EmailEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateHoliday?: Maybe<HolidayEntityResponse>;
  updateItem?: Maybe<ItemEntityResponse>;
  updatePlan?: Maybe<PlanEntityResponse>;
  updateReserve?: Maybe<ReserveEntityResponse>;
  updateService?: Maybe<ServiceEntityResponse>;
  updateSocialLoginOnetime?: Maybe<SocialLoginOnetimeEntityResponse>;
  updateSocialLoginSite?: Maybe<SocialLoginSiteEntityResponse>;
  updateSocialLoginToken?: Maybe<SocialLoginTokenEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateVisit?: Maybe<VisitEntityResponse>;
  upload: UploadFileEntityResponse;
};


export type Mutation_AddSubscriptionArgs = {
  input: AddSubscriptionInput;
};


export type Mutation_AuthSubscriptionArgs = {
  input: AuthSubscriptionInput;
};


export type Mutation_CancelReserveArgs = {
  reserve: ReservesCancelInput;
};


export type Mutation_CancelVisitArgs = {
  visit: VisitCancelInput;
};


export type Mutation_CreateReserveArgs = {
  reserve: ReservesCreateInput;
};


export type Mutation_CreateVisitArgs = {
  visit: VisitCreateInput;
};


export type Mutation_DeleteHolidayArgs = {
  id: Scalars['ID'];
};


export type Mutation_OnetimeLoginArgs = {
  input: OnetimeLoginInput;
};


export type Mutation_SocialLoginArgs = {
  input: SocialLoginInput;
};


export type Mutation_UpdateHolidayArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: HolidayInput;
};


export type Mutation_UpdateMeArgs = {
  input: UpdateUserInput;
  user_id?: InputMaybe<Scalars['ID']>;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreateCardPaymentDetailArgs = {
  data: CardPaymentDetailInput;
};


export type MutationCreateCardPaymentLogArgs = {
  data: CardPaymentLogInput;
};


export type MutationCreateCategoryArgs = {
  data: CategoryInput;
};


export type MutationCreateCouponArgs = {
  data: CouponInput;
};


export type MutationCreateEmailArgs = {
  data: EmailInput;
};


export type MutationCreateHolidayArgs = {
  data: HolidayInput;
};


export type MutationCreateItemArgs = {
  data: ItemInput;
};


export type MutationCreatePlanArgs = {
  data: PlanInput;
};


export type MutationCreateReserveArgs = {
  data: ReserveInput;
};


export type MutationCreateSocialLoginOnetimeArgs = {
  data: SocialLoginOnetimeInput;
};


export type MutationCreateSocialLoginSiteArgs = {
  data: SocialLoginSiteInput;
};


export type MutationCreateSocialLoginTokenArgs = {
  data: SocialLoginTokenInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationCreateVisitArgs = {
  data: VisitInput;
};


export type MutationDeleteCardPaymentDetailArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCardPaymentLogArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEmailArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteHolidayArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReserveArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSocialLoginOnetimeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSocialLoginSiteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSocialLoginTokenArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVisitArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUpdateCardPaymentDetailArgs = {
  data: CardPaymentDetailInput;
  id: Scalars['ID'];
};


export type MutationUpdateCardPaymentLogArgs = {
  data: CardPaymentLogInput;
  id: Scalars['ID'];
};


export type MutationUpdateCategoryArgs = {
  data: CategoryInput;
  id: Scalars['ID'];
};


export type MutationUpdateCouponArgs = {
  data: CouponInput;
  id: Scalars['ID'];
};


export type MutationUpdateEmailArgs = {
  data: EmailInput;
  id: Scalars['ID'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateHolidayArgs = {
  data: HolidayInput;
  id: Scalars['ID'];
};


export type MutationUpdateItemArgs = {
  data: ItemInput;
  id: Scalars['ID'];
};


export type MutationUpdatePlanArgs = {
  data: PlanInput;
  id: Scalars['ID'];
};


export type MutationUpdateReserveArgs = {
  data: ReserveInput;
  id: Scalars['ID'];
};


export type MutationUpdateServiceArgs = {
  data: ServiceInput;
};


export type MutationUpdateSocialLoginOnetimeArgs = {
  data: SocialLoginOnetimeInput;
  id: Scalars['ID'];
};


export type MutationUpdateSocialLoginSiteArgs = {
  data: SocialLoginSiteInput;
  id: Scalars['ID'];
};


export type MutationUpdateSocialLoginTokenArgs = {
  data: SocialLoginTokenInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUpdateVisitArgs = {
  data: VisitInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type OnetimeLogin = {
  __typename?: 'OnetimeLogin';
  jwt: Scalars['String'];
  site: _SocialLoginSite;
};

export type OnetimeLoginInput = {
  onetime_token: Scalars['String'];
  site_token: Scalars['String'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type PaginationInput = {
  direction?: InputMaybe<OrderDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['String']>;
};

export enum PaymentType {
  Card = 'card',
  Coupon = 'coupon',
  Invoice = 'invoice'
}

export type Plan = {
  __typename?: 'Plan';
  availableUserCount: Scalars['Int'];
  category?: Maybe<CategoryEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deadlineDays: Scalars['Int'];
  deptId?: Maybe<Scalars['String']>;
  detail: Scalars['String'];
  invoiceDetailName?: Maybe<Scalars['String']>;
  isNeedParentVisit: Scalars['Boolean'];
  isTouzitsuYoyaku?: Maybe<Scalars['Boolean']>;
  isVisible: Scalars['Boolean'];
  items?: Maybe<ItemRelationResponseCollection>;
  limitPrice?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  unit: Enum_Plan_Unit;
  unitAmount: Scalars['Float'];
  unitPrice: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  withVisit: Scalars['Boolean'];
};


export type PlanItemsArgs = {
  filters?: InputMaybe<ItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PlanEntity = {
  __typename?: 'PlanEntity';
  attributes?: Maybe<Plan>;
  id?: Maybe<Scalars['ID']>;
};

export type PlanEntityResponse = {
  __typename?: 'PlanEntityResponse';
  data?: Maybe<PlanEntity>;
};

export type PlanEntityResponseCollection = {
  __typename?: 'PlanEntityResponseCollection';
  data: Array<PlanEntity>;
  meta: ResponseCollectionMeta;
};

export type PlanFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PlanFiltersInput>>>;
  availableUserCount?: InputMaybe<IntFilterInput>;
  category?: InputMaybe<CategoryFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deadlineDays?: InputMaybe<IntFilterInput>;
  deptId?: InputMaybe<StringFilterInput>;
  detail?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  invoiceDetailName?: InputMaybe<StringFilterInput>;
  isNeedParentVisit?: InputMaybe<BooleanFilterInput>;
  isTouzitsuYoyaku?: InputMaybe<BooleanFilterInput>;
  isVisible?: InputMaybe<BooleanFilterInput>;
  items?: InputMaybe<ItemFiltersInput>;
  limitPrice?: InputMaybe<IntFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PlanFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PlanFiltersInput>>>;
  sort?: InputMaybe<IntFilterInput>;
  unit?: InputMaybe<StringFilterInput>;
  unitAmount?: InputMaybe<FloatFilterInput>;
  unitPrice?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  withVisit?: InputMaybe<BooleanFilterInput>;
};

export type PlanInput = {
  availableUserCount?: InputMaybe<Scalars['Int']>;
  category?: InputMaybe<Scalars['ID']>;
  deadlineDays?: InputMaybe<Scalars['Int']>;
  deptId?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  invoiceDetailName?: InputMaybe<Scalars['String']>;
  isNeedParentVisit?: InputMaybe<Scalars['Boolean']>;
  isTouzitsuYoyaku?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  limitPrice?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Scalars['Int']>;
  unit?: InputMaybe<Enum_Plan_Unit>;
  unitAmount?: InputMaybe<Scalars['Float']>;
  unitPrice?: InputMaybe<Scalars['Int']>;
  withVisit?: InputMaybe<Scalars['Boolean']>;
};

export type PlanRelationResponseCollection = {
  __typename?: 'PlanRelationResponseCollection';
  data: Array<PlanEntity>;
};

export type Query = {
  __typename?: 'Query';
  _Plans?: Maybe<Array<_Category>>;
  _adminVisits?: Maybe<Array<_AdminVisit>>;
  _adminVisitsCsv?: Maybe<Array<_VisitsCsv>>;
  _coupon?: Maybe<_Coupon>;
  _dayVisits?: Maybe<Array<_ReserveVisit>>;
  _faceauthed?: Maybe<Scalars['Boolean']>;
  _holidays?: Maybe<Array<_Holiday>>;
  _invoices?: Maybe<Array<_Invoice>>;
  _me?: Maybe<UsersPermissionsMe>;
  _planItemCount: Scalars['Int'];
  _reserves?: Maybe<Array<_Reserve>>;
  _social?: Maybe<_SocialLoginSite>;
  _subscriptions?: Maybe<Array<_Subscription>>;
  cardPaymentDetail?: Maybe<CardPaymentDetailEntityResponse>;
  cardPaymentDetails?: Maybe<CardPaymentDetailEntityResponseCollection>;
  cardPaymentLog?: Maybe<CardPaymentLogEntityResponse>;
  cardPaymentLogs?: Maybe<CardPaymentLogEntityResponseCollection>;
  categories?: Maybe<CategoryEntityResponseCollection>;
  category?: Maybe<CategoryEntityResponse>;
  coupon?: Maybe<CouponEntityResponse>;
  coupons?: Maybe<CouponEntityResponseCollection>;
  email?: Maybe<EmailEntityResponse>;
  emails?: Maybe<EmailEntityResponseCollection>;
  holiday?: Maybe<HolidayEntityResponse>;
  holidays?: Maybe<HolidayEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  item?: Maybe<ItemEntityResponse>;
  items?: Maybe<ItemEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  plan?: Maybe<PlanEntityResponse>;
  plans?: Maybe<PlanEntityResponseCollection>;
  reserve?: Maybe<ReserveEntityResponse>;
  reserves?: Maybe<ReserveEntityResponseCollection>;
  service?: Maybe<ServiceEntityResponse>;
  socialLoginOnetime?: Maybe<SocialLoginOnetimeEntityResponse>;
  socialLoginOnetimes?: Maybe<SocialLoginOnetimeEntityResponseCollection>;
  socialLoginSite?: Maybe<SocialLoginSiteEntityResponse>;
  socialLoginSites?: Maybe<SocialLoginSiteEntityResponseCollection>;
  socialLoginToken?: Maybe<SocialLoginTokenEntityResponse>;
  socialLoginTokens?: Maybe<SocialLoginTokenEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  visit?: Maybe<VisitEntityResponse>;
  visits?: Maybe<VisitEntityResponseCollection>;
};


export type Query_AdminVisitsArgs = {
  reserve_id?: InputMaybe<Scalars['ID']>;
};


export type Query_CouponArgs = {
  coupon_code: Scalars['String'];
};


export type Query_DayVisitsArgs = {
  input: DayVisitsInput;
};


export type Query_HolidaysArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
};


export type Query_InvoicesArgs = {
  input?: InputMaybe<SearchInvoiceInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type Query_PlanItemCountArgs = {
  plan_id: Scalars['String'];
};


export type Query_ReservesArgs = {
  plan_id: Scalars['String'];
};


export type Query_SocialArgs = {
  site_token: Scalars['String'];
};


export type QueryCardPaymentDetailArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCardPaymentDetailsArgs = {
  filters?: InputMaybe<CardPaymentDetailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCardPaymentLogArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCardPaymentLogsArgs = {
  filters?: InputMaybe<CardPaymentLogFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCouponArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCouponsArgs = {
  filters?: InputMaybe<CouponFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryEmailArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryEmailsArgs = {
  filters?: InputMaybe<EmailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHolidayArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryHolidaysArgs = {
  filters?: InputMaybe<HolidayFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryItemsArgs = {
  filters?: InputMaybe<ItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPlanArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPlansArgs = {
  filters?: InputMaybe<PlanFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReserveArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReservesArgs = {
  filters?: InputMaybe<ReserveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySocialLoginOnetimeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySocialLoginOnetimesArgs = {
  filters?: InputMaybe<SocialLoginOnetimeFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySocialLoginSiteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySocialLoginSitesArgs = {
  filters?: InputMaybe<SocialLoginSiteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySocialLoginTokenArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySocialLoginTokensArgs = {
  filters?: InputMaybe<SocialLoginTokenFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryVisitArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryVisitsArgs = {
  filters?: InputMaybe<VisitFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Reserve = {
  __typename?: 'Reserve';
  accessCode?: Maybe<Scalars['String']>;
  buyer?: Maybe<UsersPermissionsUserEntityResponse>;
  coupon_id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  invoiceAddress?: Maybe<Scalars['String']>;
  isAuthed?: Maybe<Scalars['Boolean']>;
  isUsed: Scalars['Boolean'];
  is_forstop?: Maybe<Scalars['Boolean']>;
  memo?: Maybe<Scalars['String']>;
  paymentType: Enum_Reserve_Paymenttype;
  plan?: Maybe<PlanEntityResponse>;
  price: Scalars['Int'];
  purpose?: Maybe<Scalars['String']>;
  startAt: Scalars['DateTime'];
  status: Enum_Reserve_Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type ReserveUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReserveEntity = {
  __typename?: 'ReserveEntity';
  attributes?: Maybe<Reserve>;
  id?: Maybe<Scalars['ID']>;
};

export type ReserveEntityResponse = {
  __typename?: 'ReserveEntityResponse';
  data?: Maybe<ReserveEntity>;
};

export type ReserveEntityResponseCollection = {
  __typename?: 'ReserveEntityResponseCollection';
  data: Array<ReserveEntity>;
  meta: ResponseCollectionMeta;
};

export type ReserveFiltersInput = {
  accessCode?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ReserveFiltersInput>>>;
  buyer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  coupon_id?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  endAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  invoiceAddress?: InputMaybe<StringFilterInput>;
  isAuthed?: InputMaybe<BooleanFilterInput>;
  isUsed?: InputMaybe<BooleanFilterInput>;
  is_forstop?: InputMaybe<BooleanFilterInput>;
  memo?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ReserveFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReserveFiltersInput>>>;
  paymentType?: InputMaybe<StringFilterInput>;
  plan?: InputMaybe<PlanFiltersInput>;
  price?: InputMaybe<IntFilterInput>;
  purpose?: InputMaybe<StringFilterInput>;
  startAt?: InputMaybe<DateTimeFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type ReserveInput = {
  accessCode?: InputMaybe<Scalars['String']>;
  buyer?: InputMaybe<Scalars['ID']>;
  coupon_id?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  invoiceAddress?: InputMaybe<Scalars['String']>;
  isAuthed?: InputMaybe<Scalars['Boolean']>;
  isUsed?: InputMaybe<Scalars['Boolean']>;
  is_forstop?: InputMaybe<Scalars['Boolean']>;
  memo?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<Enum_Reserve_Paymenttype>;
  plan?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<Scalars['Int']>;
  purpose?: InputMaybe<Scalars['String']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Enum_Reserve_Status>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type ReserveRelationResponseCollection = {
  __typename?: 'ReserveRelationResponseCollection';
  data: Array<ReserveEntity>;
};

export enum ReserveStatus {
  Canceled = 'canceled',
  Charged = 'charged',
  Reserved = 'reserved',
  Settled = 'settled',
  Used = 'used'
}

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type SearchInvoiceInput = {
  status?: InputMaybe<Array<ReserveStatus>>;
};

export type Service = {
  __typename?: 'Service';
  aboutTeiki?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  detail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  images?: Maybe<UploadFileRelationResponseCollection>;
  kiyaku?: Maybe<Scalars['String']>;
  mapUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  tel?: Maybe<Scalars['String']>;
  tokuteisho?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ServiceImagesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ServiceEntity = {
  __typename?: 'ServiceEntity';
  attributes?: Maybe<Service>;
  id?: Maybe<Scalars['ID']>;
};

export type ServiceEntityResponse = {
  __typename?: 'ServiceEntityResponse';
  data?: Maybe<ServiceEntity>;
};

export type ServiceInput = {
  aboutTeiki?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  detail?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  kiyaku?: InputMaybe<Scalars['String']>;
  mapUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tel?: InputMaybe<Scalars['String']>;
  tokuteisho?: InputMaybe<Scalars['String']>;
};

export type SocialLoginInput = {
  request_id: Scalars['String'];
  site_token: Scalars['String'];
};

export type SocialLoginOnetime = {
  __typename?: 'SocialLoginOnetime';
  createdAt?: Maybe<Scalars['DateTime']>;
  onetime_token: Scalars['String'];
  social_login_site_id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user_id: Scalars['Int'];
};

export type SocialLoginOnetimeEntity = {
  __typename?: 'SocialLoginOnetimeEntity';
  attributes?: Maybe<SocialLoginOnetime>;
  id?: Maybe<Scalars['ID']>;
};

export type SocialLoginOnetimeEntityResponse = {
  __typename?: 'SocialLoginOnetimeEntityResponse';
  data?: Maybe<SocialLoginOnetimeEntity>;
};

export type SocialLoginOnetimeEntityResponseCollection = {
  __typename?: 'SocialLoginOnetimeEntityResponseCollection';
  data: Array<SocialLoginOnetimeEntity>;
  meta: ResponseCollectionMeta;
};

export type SocialLoginOnetimeFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SocialLoginOnetimeFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SocialLoginOnetimeFiltersInput>;
  onetime_token?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SocialLoginOnetimeFiltersInput>>>;
  social_login_site_id?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user_id?: InputMaybe<IntFilterInput>;
};

export type SocialLoginOnetimeInput = {
  onetime_token?: InputMaybe<Scalars['String']>;
  social_login_site_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

export type SocialLoginSite = {
  __typename?: 'SocialLoginSite';
  auth_callback_url: Scalars['String'];
  auth_redirect_url: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  secret_key: Scalars['String'];
  site_token: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SocialLoginSiteEntity = {
  __typename?: 'SocialLoginSiteEntity';
  attributes?: Maybe<SocialLoginSite>;
  id?: Maybe<Scalars['ID']>;
};

export type SocialLoginSiteEntityResponse = {
  __typename?: 'SocialLoginSiteEntityResponse';
  data?: Maybe<SocialLoginSiteEntity>;
};

export type SocialLoginSiteEntityResponseCollection = {
  __typename?: 'SocialLoginSiteEntityResponseCollection';
  data: Array<SocialLoginSiteEntity>;
  meta: ResponseCollectionMeta;
};

export type SocialLoginSiteFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SocialLoginSiteFiltersInput>>>;
  auth_callback_url?: InputMaybe<StringFilterInput>;
  auth_redirect_url?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SocialLoginSiteFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SocialLoginSiteFiltersInput>>>;
  secret_key?: InputMaybe<StringFilterInput>;
  site_token?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SocialLoginSiteInput = {
  auth_callback_url?: InputMaybe<Scalars['String']>;
  auth_redirect_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  secret_key?: InputMaybe<Scalars['String']>;
  site_token?: InputMaybe<Scalars['String']>;
};

export type SocialLoginToken = {
  __typename?: 'SocialLoginToken';
  createdAt?: Maybe<Scalars['DateTime']>;
  social_login_site_id: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user_id: Scalars['Int'];
  user_token: Scalars['String'];
};

export type SocialLoginTokenEntity = {
  __typename?: 'SocialLoginTokenEntity';
  attributes?: Maybe<SocialLoginToken>;
  id?: Maybe<Scalars['ID']>;
};

export type SocialLoginTokenEntityResponse = {
  __typename?: 'SocialLoginTokenEntityResponse';
  data?: Maybe<SocialLoginTokenEntity>;
};

export type SocialLoginTokenEntityResponseCollection = {
  __typename?: 'SocialLoginTokenEntityResponseCollection';
  data: Array<SocialLoginTokenEntity>;
  meta: ResponseCollectionMeta;
};

export type SocialLoginTokenFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SocialLoginTokenFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SocialLoginTokenFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SocialLoginTokenFiltersInput>>>;
  social_login_site_id?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user_id?: InputMaybe<IntFilterInput>;
  user_token?: InputMaybe<StringFilterInput>;
};

export type SocialLoginTokenInput = {
  social_login_site_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
  user_token?: InputMaybe<Scalars['String']>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum Unit {
  Day = 'day',
  Minutes = 'minutes',
  Month = 'month',
  Year = 'year'
}

export type UpdateUserInput = {
  address: Scalars['String'];
  company_name?: InputMaybe<Scalars['String']>;
  mei: Scalars['String'];
  mei_kana: Scalars['String'];
  sei: Scalars['String'];
  sei_kana: Scalars['String'];
  tel: Scalars['String'];
  zipcode: Scalars['String'];
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  address: Scalars['String'];
  blocked?: Maybe<Scalars['Boolean']>;
  company_name?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  mei: Scalars['String'];
  mei_kana: Scalars['String'];
  reserves?: Maybe<Array<_MyReserve>>;
  role?: Maybe<UsersPermissionsMeRole>;
  rolename: Scalars['String'];
  sei: Scalars['String'];
  sei_kana: Scalars['String'];
  tel: Scalars['String'];
  username: Scalars['String'];
  zipcode: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  address: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
  csrf?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  mei: Scalars['String'];
  meiKana: Scalars['String'];
  password: Scalars['String'];
  sei: Scalars['String'];
  seiKana: Scalars['String'];
  tel: Scalars['String'];
  username: Scalars['String'];
  zipcode: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  address?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  buyReserves?: Maybe<ReserveRelationResponseCollection>;
  companyName?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  faceauthUserId?: Maybe<Scalars['Int']>;
  isSendRegistedMail: Scalars['Boolean'];
  mei: Scalars['String'];
  meiKana: Scalars['String'];
  provider?: Maybe<Scalars['String']>;
  reserves?: Maybe<ReserveRelationResponseCollection>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  sei: Scalars['String'];
  seiKana: Scalars['String'];
  tel: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  zipcode: Scalars['String'];
};


export type UsersPermissionsUserBuyReservesArgs = {
  filters?: InputMaybe<ReserveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserReservesArgs = {
  filters?: InputMaybe<ReserveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  address?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  buyReserves?: InputMaybe<ReserveFiltersInput>;
  companyName?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  faceauthUserId?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isSendRegistedMail?: InputMaybe<BooleanFilterInput>;
  mei?: InputMaybe<StringFilterInput>;
  meiKana?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  reserves?: InputMaybe<ReserveFiltersInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  sei?: InputMaybe<StringFilterInput>;
  seiKana?: InputMaybe<StringFilterInput>;
  tel?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
  zipcode?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  address?: InputMaybe<Scalars['String']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  buyReserves?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companyName?: InputMaybe<Scalars['String']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  faceauthUserId?: InputMaybe<Scalars['Int']>;
  isSendRegistedMail?: InputMaybe<Scalars['Boolean']>;
  mei?: InputMaybe<Scalars['String']>;
  meiKana?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  reserves?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  sei?: InputMaybe<Scalars['String']>;
  seiKana?: InputMaybe<Scalars['String']>;
  tel?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type Visit = {
  __typename?: 'Visit';
  authOn: Scalars['Boolean'];
  calendarId?: Maybe<Scalars['String']>;
  calendarLink?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  isCanceled: Scalars['Boolean'];
  needCalendarUpdate: Scalars['Boolean'];
  parentVisitId?: Maybe<Scalars['Int']>;
  reserveId: Scalars['Int'];
  startAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['Int'];
};

export type VisitEntity = {
  __typename?: 'VisitEntity';
  attributes?: Maybe<Visit>;
  id?: Maybe<Scalars['ID']>;
};

export type VisitEntityResponse = {
  __typename?: 'VisitEntityResponse';
  data?: Maybe<VisitEntity>;
};

export type VisitEntityResponseCollection = {
  __typename?: 'VisitEntityResponseCollection';
  data: Array<VisitEntity>;
  meta: ResponseCollectionMeta;
};

export type VisitFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<VisitFiltersInput>>>;
  authOn?: InputMaybe<BooleanFilterInput>;
  calendarId?: InputMaybe<StringFilterInput>;
  calendarLink?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  endAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isCanceled?: InputMaybe<BooleanFilterInput>;
  needCalendarUpdate?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<VisitFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<VisitFiltersInput>>>;
  parentVisitId?: InputMaybe<IntFilterInput>;
  reserveId?: InputMaybe<IntFilterInput>;
  startAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  userId?: InputMaybe<IntFilterInput>;
};

export type VisitInput = {
  authOn?: InputMaybe<Scalars['Boolean']>;
  calendarId?: InputMaybe<Scalars['String']>;
  calendarLink?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  isCanceled?: InputMaybe<Scalars['Boolean']>;
  needCalendarUpdate?: InputMaybe<Scalars['Boolean']>;
  parentVisitId?: InputMaybe<Scalars['Int']>;
  reserveId?: InputMaybe<Scalars['Int']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
};

export type _AdminVisit = {
  __typename?: '_AdminVisit';
  auth_on: Scalars['Boolean'];
  calendar_link?: Maybe<Scalars['String']>;
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  is_canceled: Scalars['Boolean'];
  parent_visit_id?: Maybe<Scalars['Int']>;
  start_at: Scalars['DateTime'];
  user: _AdminVisitUser;
};

export type _AdminVisitUser = {
  __typename?: '_AdminVisitUser';
  email: Scalars['String'];
  id: Scalars['ID'];
  mei: Scalars['String'];
  sei: Scalars['String'];
};

export type _Category = {
  __typename?: '_Category';
  id: Scalars['ID'];
  is_only_top: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  plans?: Maybe<Array<Plans_Category_Links>>;
  sort: Scalars['Int'];
};

export type _Coupon = {
  __typename?: '_Coupon';
  code: Scalars['String'];
  id: Scalars['ID'];
};

export type _Holiday = {
  __typename?: '_Holiday';
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  is_holiday: Scalars['Boolean'];
  is_stop_using: Scalars['Boolean'];
  name: Scalars['String'];
  start_at: Scalars['DateTime'];
};

export type _Image = {
  __typename?: '_Image';
  caption?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type _Invoice = {
  __typename?: '_Invoice';
  access_code?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  company_name?: Maybe<Scalars['String']>;
  coupon_name?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  email: Scalars['String'];
  end_at: Scalars['DateTime'];
  full_count: Scalars['Int'];
  id: Scalars['ID'];
  invoice_address?: Maybe<Scalars['String']>;
  is_forstop?: Maybe<Scalars['Boolean']>;
  is_used: Scalars['Boolean'];
  mei: Scalars['String'];
  mei_kana: Scalars['String'];
  membercount: Scalars['Int'];
  memo?: Maybe<Scalars['String']>;
  payment_type: PaymentType;
  plan_name: Scalars['String'];
  price: Scalars['Int'];
  sei: Scalars['String'];
  sei_kana: Scalars['String'];
  start_at: Scalars['DateTime'];
  status: ReserveStatus;
  tel: Scalars['String'];
  user_id: Scalars['String'];
  visit_id?: Maybe<Scalars['Int']>;
  with_visit?: Maybe<Scalars['Boolean']>;
  zipcode: Scalars['String'];
};

export type _Item = {
  __typename?: '_Item';
  amount?: Maybe<Scalars['Int']>;
  dept_id?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Files_Related_Morphs>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type _MyReserve = {
  __typename?: '_MyReserve';
  buyer_user_id?: Maybe<Scalars['Int']>;
  cardPaymentDetail?: Maybe<CardPaymentDetail>;
  created_at: Scalars['DateTime'];
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  is_authed?: Maybe<Scalars['Boolean']>;
  item_id: Scalars['Int'];
  payment_type?: Maybe<PaymentType>;
  plan: _Plan;
  price: Scalars['Int'];
  start_at: Scalars['DateTime'];
  status: ReserveStatus;
  visits?: Maybe<Array<_Visit>>;
};

export type _Plan = {
  __typename?: '_Plan';
  categories?: Maybe<Array<Plans_Category_Links>>;
  created_at?: Maybe<Scalars['DateTime']>;
  created_by_id?: Maybe<Scalars['Int']>;
  deadline_days: Scalars['Int'];
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice_detail_name?: Maybe<Scalars['String']>;
  is_need_parent_visit: Scalars['Boolean'];
  items?: Maybe<Array<Plans_Items_Links>>;
  limit_price?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  unit: Unit;
  unit_amount: Scalars['Float'];
  unit_price: Scalars['Int'];
  updated_at?: Maybe<Scalars['DateTime']>;
  updated_by_id?: Maybe<Scalars['Int']>;
  with_visit: Scalars['Boolean'];
};

export type _Reserve = {
  __typename?: '_Reserve';
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  is_forstop?: Maybe<Scalars['Boolean']>;
  item_id: Scalars['Int'];
  start_at: Scalars['DateTime'];
  visits?: Maybe<Array<_Visit>>;
};

export type _ReserveVisit = {
  __typename?: '_ReserveVisit';
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  start_at: Scalars['DateTime'];
};

export type _SocialLoginSite = {
  __typename?: '_SocialLoginSite';
  auth_redirect_url: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type _Subscription = {
  __typename?: '_Subscription';
  deadline_days: Scalars['Int'];
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  is_touzitsu_yoyaku?: Maybe<Scalars['Boolean']>;
  plan_id: Scalars['ID'];
  plan_name: Scalars['String'];
  start_at: Scalars['DateTime'];
};

export type _Visit = {
  __typename?: '_Visit';
  auth_on: Scalars['Boolean'];
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  is_canceled: Scalars['Boolean'];
  parent_visit_id?: Maybe<Scalars['Int']>;
  reserve: _Reserve;
  start_at: Scalars['DateTime'];
};

export type _VisitsCsv = {
  __typename?: '_VisitsCsv';
  buyer_id: Scalars['ID'];
  buyer_name: Scalars['String'];
  company_name?: Maybe<Scalars['String']>;
  coupon_name?: Maybe<Scalars['String']>;
  end_at: Scalars['DateTime'];
  id: Scalars['ID'];
  invoice_address?: Maybe<Scalars['String']>;
  is_canceled?: Maybe<Scalars['Boolean']>;
  is_forstop?: Maybe<Scalars['Boolean']>;
  plan_name: Scalars['String'];
  reserve_id: Scalars['ID'];
  start_at: Scalars['DateTime'];
  user_id: Scalars['ID'];
  user_name: Scalars['String'];
};

export type DayVisitsInput = {
  day: Scalars['DateTime'];
  reserve_id: Scalars['ID'];
};

export type Files_Related_Morphs = {
  __typename?: 'files_related_morphs';
  field?: Maybe<Scalars['String']>;
  file?: Maybe<_Image>;
  item?: Maybe<Item>;
  order?: Maybe<Scalars['Int']>;
  related_type: Scalars['String'];
};

export type Plans_Category_Links = {
  __typename?: 'plans_category_links';
  category: _Category;
  plan: _Plan;
};

export type Plans_Items_Links = {
  __typename?: 'plans_items_links';
  item: _Item;
  plan: _Plan;
};

export type ReservesCancelInput = {
  id: Scalars['ID'];
};

export type ReservesCreateInput = {
  cardToken?: InputMaybe<Scalars['String']>;
  coupon_code?: InputMaybe<Scalars['String']>;
  end_at: Scalars['DateTime'];
  invoice_address?: InputMaybe<Scalars['String']>;
  is_forstop?: InputMaybe<Scalars['Boolean']>;
  parent_visit_id?: InputMaybe<Scalars['Int']>;
  payment_type: PaymentType;
  plan_id: Scalars['ID'];
  price?: InputMaybe<Scalars['Int']>;
  purpose?: InputMaybe<Scalars['String']>;
  start_at: Scalars['DateTime'];
  user_id?: InputMaybe<Scalars['ID']>;
};

export type VisitCancelInput = {
  id: Scalars['ID'];
};

export type VisitCreateInput = {
  end_at: Scalars['DateTime'];
  reserve_id: Scalars['ID'];
  start_at: Scalars['DateTime'];
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null | undefined, user: { __typename?: 'UsersPermissionsMe', role?: { __typename?: 'UsersPermissionsMeRole', name: string } | null | undefined } } };

export type ReserveMutationVariables = Exact<{
  input: ReservesCreateInput;
}>;


export type ReserveMutation = { __typename?: 'Mutation', _createReserve?: { __typename?: '_MyReserve', id: string } | null | undefined };

export type VisitMutationVariables = Exact<{
  input: VisitCreateInput;
}>;


export type VisitMutation = { __typename?: 'Mutation', _createVisit?: boolean | null | undefined };

export type CancelReserveMutationVariables = Exact<{
  input: ReservesCancelInput;
}>;


export type CancelReserveMutation = { __typename?: 'Mutation', _cancelReserve?: boolean | null | undefined };

export type CancelVisitMutationVariables = Exact<{
  input: VisitCancelInput;
}>;


export type CancelVisitMutation = { __typename?: 'Mutation', _cancelVisit?: boolean | null | undefined };

export type RegisterMutationVariables = Exact<{
  user: UsersPermissionsRegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null | undefined } };

export type UpdateMeMutationVariables = Exact<{
  user: UpdateUserInput;
  user_id?: InputMaybe<Scalars['ID']>;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', _updateMe?: boolean | null | undefined };

export type MailConfirmMutationVariables = Exact<{
  confirmation: Scalars['String'];
}>;


export type MailConfirmMutation = { __typename?: 'Mutation', emailConfirmation?: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null | undefined } | null | undefined };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword?: { __typename?: 'UsersPermissionsPasswordPayload', ok: boolean } | null | undefined };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  passwordConfirm: Scalars['String'];
  code: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'UsersPermissionsLoginPayload', jwt?: string | null | undefined } | null | undefined };

export type AdminAuthSubscriptionMutationVariables = Exact<{
  input: AuthSubscriptionInput;
}>;


export type AdminAuthSubscriptionMutation = { __typename?: 'Mutation', _authSubscription?: boolean | null | undefined };

export type AddSubscriptionMutationVariables = Exact<{
  input: AddSubscriptionInput;
}>;


export type AddSubscriptionMutation = { __typename?: 'Mutation', _addSubscription?: boolean | null | undefined };

export type AdminUpdateReserveMutationVariables = Exact<{
  id: Scalars['ID'];
  data: ReserveInput;
}>;


export type AdminUpdateReserveMutation = { __typename?: 'Mutation', updateReserve?: { __typename?: 'ReserveEntityResponse', data?: { __typename?: 'ReserveEntity', id?: string | null | undefined } | null | undefined } | null | undefined };

export type AdminUpdateReserveAndVisitMutationVariables = Exact<{
  id: Scalars['ID'];
  data: ReserveInput;
  visit_id: Scalars['ID'];
  visitData: VisitInput;
}>;


export type AdminUpdateReserveAndVisitMutation = { __typename?: 'Mutation', updateReserve?: { __typename?: 'ReserveEntityResponse', data?: { __typename?: 'ReserveEntity', id?: string | null | undefined } | null | undefined } | null | undefined, updateVisit?: { __typename?: 'VisitEntityResponse', data?: { __typename?: 'VisitEntity', id?: string | null | undefined } | null | undefined } | null | undefined };

export type AdminCreateVisitMutationVariables = Exact<{
  data: VisitInput;
}>;


export type AdminCreateVisitMutation = { __typename?: 'Mutation', createVisit?: { __typename?: 'VisitEntityResponse', data?: { __typename?: 'VisitEntity', id?: string | null | undefined } | null | undefined } | null | undefined };

export type AdminUpdateVisitMutationVariables = Exact<{
  id: Scalars['ID'];
  data: VisitInput;
}>;


export type AdminUpdateVisitMutation = { __typename?: 'Mutation', updateVisit?: { __typename?: 'VisitEntityResponse', data?: { __typename?: 'VisitEntity', id?: string | null | undefined } | null | undefined } | null | undefined };

export type AdminUpdateHolidayMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: HolidayInput;
}>;


export type AdminUpdateHolidayMutation = { __typename?: 'Mutation', _updateHoliday?: { __typename?: '_Holiday', id: string } | null | undefined };

export type AdminDeleteHolidayMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AdminDeleteHolidayMutation = { __typename?: 'Mutation', _deleteHoliday?: { __typename?: '_Holiday', id: string } | null | undefined };

export type SocialLoginMutationVariables = Exact<{
  input: SocialLoginInput;
}>;


export type SocialLoginMutation = { __typename?: 'Mutation', _socialLogin?: { __typename?: '_SocialLoginSite', id: string, auth_redirect_url: string } | null | undefined };

export type OnetimeLoginMutationVariables = Exact<{
  input: OnetimeLoginInput;
}>;


export type OnetimeLoginMutation = { __typename?: 'Mutation', _onetimeLogin?: { __typename?: 'OnetimeLogin', jwt: string, site: { __typename?: '_SocialLoginSite', id: string, name: string, auth_redirect_url: string } } | null | undefined };

export type PlanQueryVariables = Exact<{ [key: string]: never; }>;


export type PlanQuery = { __typename?: 'Query', _Plans?: Array<{ __typename?: '_Category', id: string, name: string, message?: string | null | undefined, is_only_top: boolean, plans?: Array<{ __typename?: 'plans_category_links', plan: { __typename?: '_Plan', id: string, name: string, detail?: string | null | undefined, unit: Unit, unit_amount: number, unit_price: number, deadline_days: number, is_need_parent_visit: boolean, limit_price?: number | null | undefined, items?: Array<{ __typename?: 'plans_items_links', item: { __typename?: '_Item', id: string, name?: string | null | undefined, files?: Array<{ __typename?: 'files_related_morphs', file?: { __typename?: '_Image', url?: string | null | undefined, caption?: string | null | undefined } | null | undefined }> | null | undefined } }> | null | undefined } }> | null | undefined }> | null | undefined, _holidays?: Array<{ __typename?: '_Holiday', id: string, name: string, start_at: any, end_at: any, is_holiday: boolean, is_stop_using: boolean }> | null | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', _faceauthed?: boolean | null | undefined, _me?: { __typename?: 'UsersPermissionsMe', id: string, email: string, zipcode: string, address: string, sei: string, mei: string, sei_kana: string, mei_kana: string, tel: string, company_name?: string | null | undefined, rolename: string, reserves?: Array<{ __typename?: '_MyReserve', id: string, start_at: any, end_at: any, status: ReserveStatus, price: number, payment_type?: PaymentType | null | undefined, is_authed?: boolean | null | undefined, created_at: any, buyer_user_id?: number | null | undefined, cardPaymentDetail?: { __typename?: 'CardPaymentDetail', id: string, settle: boolean } | null | undefined, plan: { __typename?: '_Plan', id: string, name: string, with_visit: boolean, unit: Unit, is_need_parent_visit: boolean }, visits?: Array<{ __typename?: '_Visit', id: string, start_at: any, end_at: any, is_canceled: boolean }> | null | undefined }> | null | undefined } | null | undefined, _subscriptions?: Array<{ __typename?: '_Subscription', id: string, start_at: any, end_at: any, plan_name: string, deadline_days: number, is_touzitsu_yoyaku?: boolean | null | undefined }> | null | undefined };

export type ServiceQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceQuery = { __typename?: 'Query', service?: { __typename?: 'ServiceEntityResponse', data?: { __typename?: 'ServiceEntity', id?: string | null | undefined, attributes?: { __typename?: 'Service', name: string, address?: string | null | undefined, tel?: string | null | undefined, mapUrl?: string | null | undefined, detail?: string | null | undefined, tokuteisho?: string | null | undefined, kiyaku?: string | null | undefined, aboutTeiki?: string | null | undefined, images?: { __typename?: 'UploadFileRelationResponseCollection', data: Array<{ __typename?: 'UploadFileEntity', id?: string | null | undefined, attributes?: { __typename?: 'UploadFile', url: string } | null | undefined }> } | null | undefined } | null | undefined } | null | undefined } | null | undefined };

export type ReservesQueryVariables = Exact<{
  plan_id: Scalars['String'];
}>;


export type ReservesQuery = { __typename?: 'Query', _planItemCount: number, _reserves?: Array<{ __typename?: '_Reserve', id: string, item_id: number, start_at: any, end_at: any, is_forstop?: boolean | null | undefined }> | null | undefined };

export type AdminReservesQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminReservesQuery = { __typename?: 'Query', reserves?: { __typename?: 'ReserveEntityResponseCollection', data: Array<{ __typename?: 'ReserveEntity', id?: string | null | undefined, attributes?: { __typename?: 'Reserve', paymentType: Enum_Reserve_Paymenttype, price: number, startAt: any, endAt: any, createdAt?: any | null | undefined, status: Enum_Reserve_Status, users?: { __typename?: 'UsersPermissionsUserRelationResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null | undefined, attributes?: { __typename?: 'UsersPermissionsUser', sei: string, mei: string, address?: string | null | undefined, email: string } | null | undefined }> } | null | undefined, plan?: { __typename?: 'PlanEntityResponse', data?: { __typename?: 'PlanEntity', id?: string | null | undefined, attributes?: { __typename?: 'Plan', name: string } | null | undefined } | null | undefined } | null | undefined } | null | undefined }> } | null | undefined };

export type InvoicesQueryVariables = Exact<{
  input?: InputMaybe<SearchInvoiceInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type InvoicesQuery = { __typename?: 'Query', _invoices?: Array<{ __typename?: '_Invoice', id: string, created_at: any, user_id: string, email: string, zipcode: string, address: string, sei: string, mei: string, sei_kana: string, mei_kana: string, tel: string, company_name?: string | null | undefined, plan_name: string, membercount: number, start_at: any, end_at: any, price: number, status: ReserveStatus, invoice_address?: string | null | undefined, payment_type: PaymentType, memo?: string | null | undefined, is_used: boolean, access_code?: string | null | undefined, with_visit?: boolean | null | undefined, is_forstop?: boolean | null | undefined, coupon_name?: string | null | undefined, visit_id?: number | null | undefined, full_count: number }> | null | undefined };

export type AdminWaitingSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminWaitingSubscriptionsQuery = { __typename?: 'Query', reserves?: { __typename?: 'ReserveEntityResponseCollection', data: Array<{ __typename?: 'ReserveEntity', id?: string | null | undefined, attributes?: { __typename?: 'Reserve', isAuthed?: boolean | null | undefined, startAt: any, endAt: any, purpose?: string | null | undefined, createdAt?: any | null | undefined, users?: { __typename?: 'UsersPermissionsUserRelationResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null | undefined, attributes?: { __typename?: 'UsersPermissionsUser', sei: string, mei: string, email: string, companyName?: string | null | undefined } | null | undefined }> } | null | undefined, plan?: { __typename?: 'PlanEntityResponse', data?: { __typename?: 'PlanEntity', id?: string | null | undefined, attributes?: { __typename?: 'Plan', name: string } | null | undefined } | null | undefined } | null | undefined } | null | undefined }> } | null | undefined };

export type AdminVisitsQueryVariables = Exact<{
  reserve_id: Scalars['ID'];
}>;


export type AdminVisitsQuery = { __typename?: 'Query', _adminVisits?: Array<{ __typename?: '_AdminVisit', id: string, start_at: any, end_at: any, is_canceled: boolean, calendar_link?: string | null | undefined, user: { __typename?: '_AdminVisitUser', id: string, email: string, sei: string, mei: string } }> | null | undefined, reserves?: { __typename?: 'ReserveEntityResponseCollection', data: Array<{ __typename?: 'ReserveEntity', id?: string | null | undefined, attributes?: { __typename?: 'Reserve', accessCode?: string | null | undefined, invoiceAddress?: string | null | undefined, users?: { __typename?: 'UsersPermissionsUserRelationResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null | undefined, attributes?: { __typename?: 'UsersPermissionsUser', email: string, sei: string, mei: string, tel: string } | null | undefined }> } | null | undefined, plan?: { __typename?: 'PlanEntityResponse', data?: { __typename?: 'PlanEntity', attributes?: { __typename?: 'Plan', name: string } | null | undefined } | null | undefined } | null | undefined, buyer?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null | undefined, attributes?: { __typename?: 'UsersPermissionsUser', sei: string, mei: string, companyName?: string | null | undefined, email: string } | null | undefined } | null | undefined } | null | undefined } | null | undefined }> } | null | undefined };

export type DayVisitsQueryVariables = Exact<{
  input: DayVisitsInput;
}>;


export type DayVisitsQuery = { __typename?: 'Query', _dayVisits?: Array<{ __typename?: '_ReserveVisit', id: string, start_at: any, end_at: any }> | null | undefined };

export type AdminAllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminAllUsersQuery = { __typename?: 'Query', usersPermissionsUsers?: { __typename?: 'UsersPermissionsUserEntityResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null | undefined, attributes?: { __typename?: 'UsersPermissionsUser', email: string, sei: string, mei: string, seiKana: string, meiKana: string, address?: string | null | undefined, tel: string, zipcode: string, companyName?: string | null | undefined, createdAt?: any | null | undefined } | null | undefined }> } | null | undefined };

export type AdminVisitCsvQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminVisitCsvQuery = { __typename?: 'Query', _adminVisitsCsv?: Array<{ __typename?: '_VisitsCsv', id: string, is_canceled?: boolean | null | undefined, reserve_id: string, is_forstop?: boolean | null | undefined, buyer_id: string, buyer_name: string, company_name?: string | null | undefined, invoice_address?: string | null | undefined, plan_name: string, start_at: any, end_at: any, user_id: string, user_name: string, coupon_name?: string | null | undefined }> | null | undefined };

export type HolidaysQueryVariables = Exact<{
  input?: InputMaybe<Scalars['DateTime']>;
}>;


export type HolidaysQuery = { __typename?: 'Query', _holidays?: Array<{ __typename?: '_Holiday', id: string, name: string, start_at: any, end_at: any, is_holiday: boolean, is_stop_using: boolean }> | null | undefined };

export type SocialQueryVariables = Exact<{
  site_token: Scalars['String'];
}>;


export type SocialQuery = { __typename?: 'Query', _social?: { __typename?: '_SocialLoginSite', id: string, name: string, auth_redirect_url: string } | null | undefined };

export type CouponQueryVariables = Exact<{
  coupon_code: Scalars['String'];
}>;


export type CouponQuery = { __typename?: 'Query', _coupon?: { __typename?: '_Coupon', id: string, code: string } | null | undefined };


export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(input: {identifier: $email, password: $password}) {
    jwt
    user {
      role {
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ReserveDocument = gql`
    mutation reserve($input: reservesCreateInput!) {
  _createReserve(reserve: $input) {
    id
  }
}
    `;
export type ReserveMutationFn = Apollo.MutationFunction<ReserveMutation, ReserveMutationVariables>;

/**
 * __useReserveMutation__
 *
 * To run a mutation, you first call `useReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reserveMutation, { data, loading, error }] = useReserveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReserveMutation(baseOptions?: Apollo.MutationHookOptions<ReserveMutation, ReserveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReserveMutation, ReserveMutationVariables>(ReserveDocument, options);
      }
export type ReserveMutationHookResult = ReturnType<typeof useReserveMutation>;
export type ReserveMutationOptions = Apollo.BaseMutationOptions<ReserveMutation, ReserveMutationVariables>;
export const VisitDocument = gql`
    mutation visit($input: visitCreateInput!) {
  _createVisit(visit: $input)
}
    `;
export type VisitMutationFn = Apollo.MutationFunction<VisitMutation, VisitMutationVariables>;

/**
 * __useVisitMutation__
 *
 * To run a mutation, you first call `useVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [visitMutation, { data, loading, error }] = useVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVisitMutation(baseOptions?: Apollo.MutationHookOptions<VisitMutation, VisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VisitMutation, VisitMutationVariables>(VisitDocument, options);
      }
export type VisitMutationHookResult = ReturnType<typeof useVisitMutation>;
export type VisitMutationOptions = Apollo.BaseMutationOptions<VisitMutation, VisitMutationVariables>;
export const CancelReserveDocument = gql`
    mutation cancelReserve($input: reservesCancelInput!) {
  _cancelReserve(reserve: $input)
}
    `;
export type CancelReserveMutationFn = Apollo.MutationFunction<CancelReserveMutation, CancelReserveMutationVariables>;

/**
 * __useCancelReserveMutation__
 *
 * To run a mutation, you first call `useCancelReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelReserveMutation, { data, loading, error }] = useCancelReserveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelReserveMutation(baseOptions?: Apollo.MutationHookOptions<CancelReserveMutation, CancelReserveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelReserveMutation, CancelReserveMutationVariables>(CancelReserveDocument, options);
      }
export type CancelReserveMutationHookResult = ReturnType<typeof useCancelReserveMutation>;
export type CancelReserveMutationOptions = Apollo.BaseMutationOptions<CancelReserveMutation, CancelReserveMutationVariables>;
export const CancelVisitDocument = gql`
    mutation cancelVisit($input: visitCancelInput!) {
  _cancelVisit(visit: $input)
}
    `;
export type CancelVisitMutationFn = Apollo.MutationFunction<CancelVisitMutation, CancelVisitMutationVariables>;

/**
 * __useCancelVisitMutation__
 *
 * To run a mutation, you first call `useCancelVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelVisitMutation, { data, loading, error }] = useCancelVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelVisitMutation(baseOptions?: Apollo.MutationHookOptions<CancelVisitMutation, CancelVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelVisitMutation, CancelVisitMutationVariables>(CancelVisitDocument, options);
      }
export type CancelVisitMutationHookResult = ReturnType<typeof useCancelVisitMutation>;
export type CancelVisitMutationOptions = Apollo.BaseMutationOptions<CancelVisitMutation, CancelVisitMutationVariables>;
export const RegisterDocument = gql`
    mutation register($user: UsersPermissionsRegisterInput!) {
  register(input: $user) {
    jwt
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdateMeDocument = gql`
    mutation updateMe($user: UpdateUserInput!, $user_id: ID) {
  _updateMe(input: $user, user_id: $user_id)
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      user: // value for 'user'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const MailConfirmDocument = gql`
    mutation mailConfirm($confirmation: String!) {
  emailConfirmation(confirmation: $confirmation) {
    jwt
  }
}
    `;
export type MailConfirmMutationFn = Apollo.MutationFunction<MailConfirmMutation, MailConfirmMutationVariables>;

/**
 * __useMailConfirmMutation__
 *
 * To run a mutation, you first call `useMailConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailConfirmMutation, { data, loading, error }] = useMailConfirmMutation({
 *   variables: {
 *      confirmation: // value for 'confirmation'
 *   },
 * });
 */
export function useMailConfirmMutation(baseOptions?: Apollo.MutationHookOptions<MailConfirmMutation, MailConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailConfirmMutation, MailConfirmMutationVariables>(MailConfirmDocument, options);
      }
export type MailConfirmMutationHookResult = ReturnType<typeof useMailConfirmMutation>;
export type MailConfirmMutationOptions = Apollo.BaseMutationOptions<MailConfirmMutation, MailConfirmMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $passwordConfirm: String!, $code: String!) {
  resetPassword(
    password: $password
    passwordConfirmation: $passwordConfirm
    code: $code
  ) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirm: // value for 'passwordConfirm'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const AdminAuthSubscriptionDocument = gql`
    mutation adminAuthSubscription($input: AuthSubscriptionInput!) {
  _authSubscription(input: $input)
}
    `;
export type AdminAuthSubscriptionMutationFn = Apollo.MutationFunction<AdminAuthSubscriptionMutation, AdminAuthSubscriptionMutationVariables>;

/**
 * __useAdminAuthSubscriptionMutation__
 *
 * To run a mutation, you first call `useAdminAuthSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAuthSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAuthSubscriptionMutation, { data, loading, error }] = useAdminAuthSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminAuthSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AdminAuthSubscriptionMutation, AdminAuthSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminAuthSubscriptionMutation, AdminAuthSubscriptionMutationVariables>(AdminAuthSubscriptionDocument, options);
      }
export type AdminAuthSubscriptionMutationHookResult = ReturnType<typeof useAdminAuthSubscriptionMutation>;
export type AdminAuthSubscriptionMutationOptions = Apollo.BaseMutationOptions<AdminAuthSubscriptionMutation, AdminAuthSubscriptionMutationVariables>;
export const AddSubscriptionDocument = gql`
    mutation addSubscription($input: AddSubscriptionInput!) {
  _addSubscription(input: $input)
}
    `;
export type AddSubscriptionMutationFn = Apollo.MutationFunction<AddSubscriptionMutation, AddSubscriptionMutationVariables>;

/**
 * __useAddSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubscriptionMutation, { data, loading, error }] = useAddSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AddSubscriptionMutation, AddSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubscriptionMutation, AddSubscriptionMutationVariables>(AddSubscriptionDocument, options);
      }
export type AddSubscriptionMutationHookResult = ReturnType<typeof useAddSubscriptionMutation>;
export type AddSubscriptionMutationOptions = Apollo.BaseMutationOptions<AddSubscriptionMutation, AddSubscriptionMutationVariables>;
export const AdminUpdateReserveDocument = gql`
    mutation adminUpdateReserve($id: ID!, $data: ReserveInput!) {
  updateReserve(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type AdminUpdateReserveMutationFn = Apollo.MutationFunction<AdminUpdateReserveMutation, AdminUpdateReserveMutationVariables>;

/**
 * __useAdminUpdateReserveMutation__
 *
 * To run a mutation, you first call `useAdminUpdateReserveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateReserveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateReserveMutation, { data, loading, error }] = useAdminUpdateReserveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateReserveMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateReserveMutation, AdminUpdateReserveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateReserveMutation, AdminUpdateReserveMutationVariables>(AdminUpdateReserveDocument, options);
      }
export type AdminUpdateReserveMutationHookResult = ReturnType<typeof useAdminUpdateReserveMutation>;
export type AdminUpdateReserveMutationOptions = Apollo.BaseMutationOptions<AdminUpdateReserveMutation, AdminUpdateReserveMutationVariables>;
export const AdminUpdateReserveAndVisitDocument = gql`
    mutation adminUpdateReserveAndVisit($id: ID!, $data: ReserveInput!, $visit_id: ID!, $visitData: VisitInput!) {
  updateReserve(id: $id, data: $data) {
    data {
      id
    }
  }
  updateVisit(id: $visit_id, data: $visitData) {
    data {
      id
    }
  }
}
    `;
export type AdminUpdateReserveAndVisitMutationFn = Apollo.MutationFunction<AdminUpdateReserveAndVisitMutation, AdminUpdateReserveAndVisitMutationVariables>;

/**
 * __useAdminUpdateReserveAndVisitMutation__
 *
 * To run a mutation, you first call `useAdminUpdateReserveAndVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateReserveAndVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateReserveAndVisitMutation, { data, loading, error }] = useAdminUpdateReserveAndVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      visit_id: // value for 'visit_id'
 *      visitData: // value for 'visitData'
 *   },
 * });
 */
export function useAdminUpdateReserveAndVisitMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateReserveAndVisitMutation, AdminUpdateReserveAndVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateReserveAndVisitMutation, AdminUpdateReserveAndVisitMutationVariables>(AdminUpdateReserveAndVisitDocument, options);
      }
export type AdminUpdateReserveAndVisitMutationHookResult = ReturnType<typeof useAdminUpdateReserveAndVisitMutation>;
export type AdminUpdateReserveAndVisitMutationOptions = Apollo.BaseMutationOptions<AdminUpdateReserveAndVisitMutation, AdminUpdateReserveAndVisitMutationVariables>;
export const AdminCreateVisitDocument = gql`
    mutation adminCreateVisit($data: VisitInput!) {
  createVisit(data: $data) {
    data {
      id
    }
  }
}
    `;
export type AdminCreateVisitMutationFn = Apollo.MutationFunction<AdminCreateVisitMutation, AdminCreateVisitMutationVariables>;

/**
 * __useAdminCreateVisitMutation__
 *
 * To run a mutation, you first call `useAdminCreateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateVisitMutation, { data, loading, error }] = useAdminCreateVisitMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminCreateVisitMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateVisitMutation, AdminCreateVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateVisitMutation, AdminCreateVisitMutationVariables>(AdminCreateVisitDocument, options);
      }
export type AdminCreateVisitMutationHookResult = ReturnType<typeof useAdminCreateVisitMutation>;
export type AdminCreateVisitMutationOptions = Apollo.BaseMutationOptions<AdminCreateVisitMutation, AdminCreateVisitMutationVariables>;
export const AdminUpdateVisitDocument = gql`
    mutation adminUpdateVisit($id: ID!, $data: VisitInput!) {
  updateVisit(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type AdminUpdateVisitMutationFn = Apollo.MutationFunction<AdminUpdateVisitMutation, AdminUpdateVisitMutationVariables>;

/**
 * __useAdminUpdateVisitMutation__
 *
 * To run a mutation, you first call `useAdminUpdateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateVisitMutation, { data, loading, error }] = useAdminUpdateVisitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminUpdateVisitMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateVisitMutation, AdminUpdateVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateVisitMutation, AdminUpdateVisitMutationVariables>(AdminUpdateVisitDocument, options);
      }
export type AdminUpdateVisitMutationHookResult = ReturnType<typeof useAdminUpdateVisitMutation>;
export type AdminUpdateVisitMutationOptions = Apollo.BaseMutationOptions<AdminUpdateVisitMutation, AdminUpdateVisitMutationVariables>;
export const AdminUpdateHolidayDocument = gql`
    mutation adminUpdateHoliday($id: ID, $input: HolidayInput!) {
  _updateHoliday(id: $id, input: $input) {
    id
  }
}
    `;
export type AdminUpdateHolidayMutationFn = Apollo.MutationFunction<AdminUpdateHolidayMutation, AdminUpdateHolidayMutationVariables>;

/**
 * __useAdminUpdateHolidayMutation__
 *
 * To run a mutation, you first call `useAdminUpdateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateHolidayMutation, { data, loading, error }] = useAdminUpdateHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateHolidayMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateHolidayMutation, AdminUpdateHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateHolidayMutation, AdminUpdateHolidayMutationVariables>(AdminUpdateHolidayDocument, options);
      }
export type AdminUpdateHolidayMutationHookResult = ReturnType<typeof useAdminUpdateHolidayMutation>;
export type AdminUpdateHolidayMutationOptions = Apollo.BaseMutationOptions<AdminUpdateHolidayMutation, AdminUpdateHolidayMutationVariables>;
export const AdminDeleteHolidayDocument = gql`
    mutation adminDeleteHoliday($id: ID!) {
  _deleteHoliday(id: $id) {
    id
  }
}
    `;
export type AdminDeleteHolidayMutationFn = Apollo.MutationFunction<AdminDeleteHolidayMutation, AdminDeleteHolidayMutationVariables>;

/**
 * __useAdminDeleteHolidayMutation__
 *
 * To run a mutation, you first call `useAdminDeleteHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteHolidayMutation, { data, loading, error }] = useAdminDeleteHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminDeleteHolidayMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteHolidayMutation, AdminDeleteHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteHolidayMutation, AdminDeleteHolidayMutationVariables>(AdminDeleteHolidayDocument, options);
      }
export type AdminDeleteHolidayMutationHookResult = ReturnType<typeof useAdminDeleteHolidayMutation>;
export type AdminDeleteHolidayMutationOptions = Apollo.BaseMutationOptions<AdminDeleteHolidayMutation, AdminDeleteHolidayMutationVariables>;
export const SocialLoginDocument = gql`
    mutation socialLogin($input: SocialLoginInput!) {
  _socialLogin(input: $input) {
    id
    auth_redirect_url
  }
}
    `;
export type SocialLoginMutationFn = Apollo.MutationFunction<SocialLoginMutation, SocialLoginMutationVariables>;

/**
 * __useSocialLoginMutation__
 *
 * To run a mutation, you first call `useSocialLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialLoginMutation, { data, loading, error }] = useSocialLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSocialLoginMutation(baseOptions?: Apollo.MutationHookOptions<SocialLoginMutation, SocialLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SocialLoginMutation, SocialLoginMutationVariables>(SocialLoginDocument, options);
      }
export type SocialLoginMutationHookResult = ReturnType<typeof useSocialLoginMutation>;
export type SocialLoginMutationOptions = Apollo.BaseMutationOptions<SocialLoginMutation, SocialLoginMutationVariables>;
export const OnetimeLoginDocument = gql`
    mutation onetimeLogin($input: OnetimeLoginInput!) {
  _onetimeLogin(input: $input) {
    jwt
    site {
      id
      name
      auth_redirect_url
    }
  }
}
    `;
export type OnetimeLoginMutationFn = Apollo.MutationFunction<OnetimeLoginMutation, OnetimeLoginMutationVariables>;

/**
 * __useOnetimeLoginMutation__
 *
 * To run a mutation, you first call `useOnetimeLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnetimeLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onetimeLoginMutation, { data, loading, error }] = useOnetimeLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnetimeLoginMutation(baseOptions?: Apollo.MutationHookOptions<OnetimeLoginMutation, OnetimeLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnetimeLoginMutation, OnetimeLoginMutationVariables>(OnetimeLoginDocument, options);
      }
export type OnetimeLoginMutationHookResult = ReturnType<typeof useOnetimeLoginMutation>;
export type OnetimeLoginMutationOptions = Apollo.BaseMutationOptions<OnetimeLoginMutation, OnetimeLoginMutationVariables>;
export const PlanDocument = gql`
    query plan {
  _Plans {
    id
    name
    message
    is_only_top
    plans {
      plan {
        id
        name
        detail
        unit
        unit_amount
        unit_price
        deadline_days
        is_need_parent_visit
        limit_price
        items {
          item {
            id
            name
            files {
              file {
                url
                caption
              }
            }
          }
        }
      }
    }
  }
  _holidays {
    id
    name
    start_at
    end_at
    is_holiday
    is_stop_using
  }
}
    `;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanQuery(baseOptions?: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export const MeDocument = gql`
    query me {
  _faceauthed
  _me {
    id
    email
    zipcode
    address
    sei
    mei
    sei_kana
    mei_kana
    tel
    company_name
    reserves {
      id
      start_at
      end_at
      status
      price
      payment_type
      is_authed
      cardPaymentDetail {
        id
        settle
      }
      plan {
        id
        name
        with_visit
        unit
        is_need_parent_visit
      }
      created_at
      visits {
        id
        start_at
        end_at
        is_canceled
      }
      buyer_user_id
    }
    rolename
  }
  _subscriptions {
    id
    start_at
    end_at
    plan_name
    deadline_days
    is_touzitsu_yoyaku
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export const ServiceDocument = gql`
    query service {
  service {
    data {
      id
      attributes {
        name
        address
        tel
        mapUrl
        detail
        images {
          data {
            id
            attributes {
              url
            }
          }
        }
        tokuteisho
        kiyaku
        aboutTeiki
      }
    }
  }
}
    `;

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *   },
 * });
 */
export function useServiceQuery(baseOptions?: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export const ReservesDocument = gql`
    query reserves($plan_id: String!) {
  _reserves(plan_id: $plan_id) {
    id
    item_id
    start_at
    end_at
    is_forstop
  }
  _planItemCount(plan_id: $plan_id)
}
    `;

/**
 * __useReservesQuery__
 *
 * To run a query within a React component, call `useReservesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservesQuery({
 *   variables: {
 *      plan_id: // value for 'plan_id'
 *   },
 * });
 */
export function useReservesQuery(baseOptions: Apollo.QueryHookOptions<ReservesQuery, ReservesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservesQuery, ReservesQueryVariables>(ReservesDocument, options);
      }
export function useReservesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservesQuery, ReservesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservesQuery, ReservesQueryVariables>(ReservesDocument, options);
        }
export type ReservesQueryHookResult = ReturnType<typeof useReservesQuery>;
export type ReservesLazyQueryHookResult = ReturnType<typeof useReservesLazyQuery>;
export const AdminReservesDocument = gql`
    query adminReserves {
  reserves(pagination: {start: 0, limit: 1000}) {
    data {
      id
      attributes {
        paymentType
        price
        startAt
        endAt
        createdAt
        status
        users(pagination: {start: 0, limit: 1000}) {
          data {
            id
            attributes {
              sei
              mei
              address
              email
            }
          }
        }
        plan {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdminReservesQuery__
 *
 * To run a query within a React component, call `useAdminReservesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReservesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReservesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminReservesQuery(baseOptions?: Apollo.QueryHookOptions<AdminReservesQuery, AdminReservesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminReservesQuery, AdminReservesQueryVariables>(AdminReservesDocument, options);
      }
export function useAdminReservesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminReservesQuery, AdminReservesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminReservesQuery, AdminReservesQueryVariables>(AdminReservesDocument, options);
        }
export type AdminReservesQueryHookResult = ReturnType<typeof useAdminReservesQuery>;
export type AdminReservesLazyQueryHookResult = ReturnType<typeof useAdminReservesLazyQuery>;
export const InvoicesDocument = gql`
    query invoices($input: SearchInvoiceInput, $pagination: PaginationInput) {
  _invoices(input: $input, pagination: $pagination) {
    id
    created_at
    user_id
    email
    zipcode
    address
    sei
    mei
    sei_kana
    mei_kana
    tel
    company_name
    plan_name
    membercount
    start_at
    end_at
    price
    status
    invoice_address
    payment_type
    memo
    is_used
    access_code
    with_visit
    is_forstop
    coupon_name
    visit_id
    full_count
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export const AdminWaitingSubscriptionsDocument = gql`
    query adminWaitingSubscriptions {
  reserves(
    filters: {isAuthed: null, status: {ne: "canceled"}, users: {confirmed: {eq: true}}}
    sort: ["createdAt"]
    pagination: {start: 0, limit: 1000}
  ) {
    data {
      id
      attributes {
        isAuthed
        startAt
        endAt
        purpose
        createdAt
        users {
          data {
            id
            attributes {
              sei
              mei
              email
              companyName
            }
          }
        }
        plan {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdminWaitingSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAdminWaitingSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWaitingSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWaitingSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminWaitingSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminWaitingSubscriptionsQuery, AdminWaitingSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminWaitingSubscriptionsQuery, AdminWaitingSubscriptionsQueryVariables>(AdminWaitingSubscriptionsDocument, options);
      }
export function useAdminWaitingSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminWaitingSubscriptionsQuery, AdminWaitingSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminWaitingSubscriptionsQuery, AdminWaitingSubscriptionsQueryVariables>(AdminWaitingSubscriptionsDocument, options);
        }
export type AdminWaitingSubscriptionsQueryHookResult = ReturnType<typeof useAdminWaitingSubscriptionsQuery>;
export type AdminWaitingSubscriptionsLazyQueryHookResult = ReturnType<typeof useAdminWaitingSubscriptionsLazyQuery>;
export const AdminVisitsDocument = gql`
    query adminVisits($reserve_id: ID!) {
  _adminVisits(reserve_id: $reserve_id) {
    id
    start_at
    end_at
    is_canceled
    calendar_link
    user {
      id
      email
      sei
      mei
    }
  }
  reserves(filters: {id: {eq: $reserve_id}}) {
    data {
      id
      attributes {
        users(pagination: {start: 0, limit: 1000}) {
          data {
            id
            attributes {
              email
              sei
              mei
              tel
            }
          }
        }
        accessCode
        invoiceAddress
        plan {
          data {
            attributes {
              name
            }
          }
        }
        buyer {
          data {
            id
            attributes {
              sei
              mei
              companyName
              email
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdminVisitsQuery__
 *
 * To run a query within a React component, call `useAdminVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVisitsQuery({
 *   variables: {
 *      reserve_id: // value for 'reserve_id'
 *   },
 * });
 */
export function useAdminVisitsQuery(baseOptions: Apollo.QueryHookOptions<AdminVisitsQuery, AdminVisitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminVisitsQuery, AdminVisitsQueryVariables>(AdminVisitsDocument, options);
      }
export function useAdminVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminVisitsQuery, AdminVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminVisitsQuery, AdminVisitsQueryVariables>(AdminVisitsDocument, options);
        }
export type AdminVisitsQueryHookResult = ReturnType<typeof useAdminVisitsQuery>;
export type AdminVisitsLazyQueryHookResult = ReturnType<typeof useAdminVisitsLazyQuery>;
export const DayVisitsDocument = gql`
    query dayVisits($input: dayVisitsInput!) {
  _dayVisits(input: $input) {
    id
    start_at
    end_at
  }
}
    `;

/**
 * __useDayVisitsQuery__
 *
 * To run a query within a React component, call `useDayVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayVisitsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDayVisitsQuery(baseOptions: Apollo.QueryHookOptions<DayVisitsQuery, DayVisitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DayVisitsQuery, DayVisitsQueryVariables>(DayVisitsDocument, options);
      }
export function useDayVisitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DayVisitsQuery, DayVisitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DayVisitsQuery, DayVisitsQueryVariables>(DayVisitsDocument, options);
        }
export type DayVisitsQueryHookResult = ReturnType<typeof useDayVisitsQuery>;
export type DayVisitsLazyQueryHookResult = ReturnType<typeof useDayVisitsLazyQuery>;
export const AdminAllUsersDocument = gql`
    query adminAllUsers {
  usersPermissionsUsers(
    filters: {blocked: {eq: false}}
    sort: "id"
    pagination: {limit: 100000}
  ) {
    data {
      id
      attributes {
        email
        sei
        mei
        seiKana
        meiKana
        address
        tel
        zipcode
        companyName
        createdAt
      }
    }
  }
}
    `;

/**
 * __useAdminAllUsersQuery__
 *
 * To run a query within a React component, call `useAdminAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AdminAllUsersQuery, AdminAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminAllUsersQuery, AdminAllUsersQueryVariables>(AdminAllUsersDocument, options);
      }
export function useAdminAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminAllUsersQuery, AdminAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminAllUsersQuery, AdminAllUsersQueryVariables>(AdminAllUsersDocument, options);
        }
export type AdminAllUsersQueryHookResult = ReturnType<typeof useAdminAllUsersQuery>;
export type AdminAllUsersLazyQueryHookResult = ReturnType<typeof useAdminAllUsersLazyQuery>;
export const AdminVisitCsvDocument = gql`
    query adminVisitCsv {
  _adminVisitsCsv {
    id
    is_canceled
    reserve_id
    is_forstop
    buyer_id
    buyer_name
    company_name
    invoice_address
    plan_name
    start_at
    end_at
    user_id
    user_name
    coupon_name
  }
}
    `;

/**
 * __useAdminVisitCsvQuery__
 *
 * To run a query within a React component, call `useAdminVisitCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVisitCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVisitCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminVisitCsvQuery(baseOptions?: Apollo.QueryHookOptions<AdminVisitCsvQuery, AdminVisitCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminVisitCsvQuery, AdminVisitCsvQueryVariables>(AdminVisitCsvDocument, options);
      }
export function useAdminVisitCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminVisitCsvQuery, AdminVisitCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminVisitCsvQuery, AdminVisitCsvQueryVariables>(AdminVisitCsvDocument, options);
        }
export type AdminVisitCsvQueryHookResult = ReturnType<typeof useAdminVisitCsvQuery>;
export type AdminVisitCsvLazyQueryHookResult = ReturnType<typeof useAdminVisitCsvLazyQuery>;
export const HolidaysDocument = gql`
    query holidays($input: DateTime) {
  _holidays(from: $input) {
    id
    name
    start_at
    end_at
    is_holiday
    is_stop_using
  }
}
    `;

/**
 * __useHolidaysQuery__
 *
 * To run a query within a React component, call `useHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidaysQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHolidaysQuery(baseOptions?: Apollo.QueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, options);
      }
export function useHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, options);
        }
export type HolidaysQueryHookResult = ReturnType<typeof useHolidaysQuery>;
export type HolidaysLazyQueryHookResult = ReturnType<typeof useHolidaysLazyQuery>;
export const SocialDocument = gql`
    query social($site_token: String!) {
  _social(site_token: $site_token) {
    id
    name
    auth_redirect_url
  }
}
    `;

/**
 * __useSocialQuery__
 *
 * To run a query within a React component, call `useSocialQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialQuery({
 *   variables: {
 *      site_token: // value for 'site_token'
 *   },
 * });
 */
export function useSocialQuery(baseOptions: Apollo.QueryHookOptions<SocialQuery, SocialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SocialQuery, SocialQueryVariables>(SocialDocument, options);
      }
export function useSocialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SocialQuery, SocialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SocialQuery, SocialQueryVariables>(SocialDocument, options);
        }
export type SocialQueryHookResult = ReturnType<typeof useSocialQuery>;
export type SocialLazyQueryHookResult = ReturnType<typeof useSocialLazyQuery>;
export const CouponDocument = gql`
    query coupon($coupon_code: String!) {
  _coupon(coupon_code: $coupon_code) {
    id
    code
  }
}
    `;

/**
 * __useCouponQuery__
 *
 * To run a query within a React component, call `useCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponQuery({
 *   variables: {
 *      coupon_code: // value for 'coupon_code'
 *   },
 * });
 */
export function useCouponQuery(baseOptions: Apollo.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
      }
export function useCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
        }
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;