import dayjs from 'dayjs'
import { ENV } from './env'
import { CardInfo } from './recoil'

declare var EpsilonToken: any

declare global {
  interface Window {
    tokenCb: (e: any) => void
  }
}

const getCardToken = (cardInfo?: CardInfo) => {
  if (!cardInfo) {
    return null
  }

  EpsilonToken.init(ENV.EPSILON_ID)
  return new Promise<{ token: string; time: dayjs.Dayjs }>((resolve, reject) => {
    // epsilon結果受け取りコールバック
    window.tokenCb = (result) => {
      if (result.tokenObject) {
        const { token, toBeExpiredAt } = result.tokenObject
        const time = toBeExpiredAt.replace(/(\d{4}-\d{2}-\d{2})-(\d{2})-(\d{2})-(\d{2})/, '$1 $2:$3:$4')
        resolve({ token: String(token), time: dayjs(time) })
      } else {
        reject('クレジットカード情報に問題がありました')
      }
    }
    EpsilonToken.getToken(cardInfo, 'tokenCb')
  })
}
export { getCardToken }
