import { LocalStorage } from '@util/localStorage'
import { useEffect, useState } from 'react'

const BackTo = ({ warn = false }: { warn?: boolean }) => {
  const [url, setUrl] = useState('')
  useEffect(() => {
    const tmp = LocalStorage.backto.get()
    if (tmp) {
      setUrl(tmp)
    }
  }, [])

  if (!url) {
    return null
  }
  return (
    <a href={url} className={`btn ${warn && 'btn-warning btn-sm'}`} onClick={() => LocalStorage.backto.remove()}>
      スマートパスに戻る
    </a>
  )
}

export { BackTo }
