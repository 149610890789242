import { useServiceQuery } from '@generated/graphql'
import { marked } from 'marked'
import { useMemo } from 'react'
const ServiceView = () => {
  const { data } = useServiceQuery()
  const service = useMemo(() => {
    return data?.service?.data?.attributes
  }, [data])

  if (!service) {
    return null
  }
  return (
    <div className="container mx-auto p-4">
      <div className="my-4 text-2xl font-bold text-base-content">{service.name}</div>
      <div className="bg-white p-2" dangerouslySetInnerHTML={{ __html: service?.mapUrl ?? '' }}></div>
      <div>
        <div className="my-4">
          <div className="my-4 font-bold">特定商取引法に基づく表示</div>
          <div
            className="px-4"
            dangerouslySetInnerHTML={{
              __html: marked.parse(service?.tokuteisho?.split('\n').join('<br />') || ''),
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export { ServiceView }
