import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { RecoilRoot } from 'recoil'
import { Index } from './view'

import { ReserveView } from '@view/reserve'
// import { RegistUser } from '@view/regist'
// import { MypageView } from '@view/mypage/mypage'
// import { MailConfirm } from '@view/regist/confirm'
// import { ForgotPassword } from '@view/regist/forgotPassword'
// import { ResetPassword } from '@view/regist/resetPassword'
// import { ReserveExtend } from '@view/mypage/extend'
import { QueryParamProvider, StringParam, useQueryParam, useQueryParams } from 'use-query-params'
import { Suspense, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

import { setZodMessage } from '@util/zod'
import { ReserveDetailView } from '@view/reserve/detail'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { MypageView } from '@view/mypage/mypage'
import { ServiceView } from '@view/service'
import { UpdateUser } from '@view/regist/update'
import { RegistUser } from '@view/regist'
import { MailConfirm } from '@view/regist/confirm'
import { VisitDetailView } from '@view/reserve/visit'
import { AdminView } from '@view/admin'
import { useMeQuery } from '@generated/graphql'
import { VistView } from '@view/admin/visit'
import { ForgotPassword } from '@view/regist/forgotPassword'
import { ResetPassword } from '@view/regist/resetPassword'
import { KiyakuView } from '@view/service/kiyaku'
import { TeikiView } from '@view/service/teiki'
import { BuyView } from '@view/mypage/buy'
import { AddSubscription } from '@view/mypage/addSubscription'
import { CookiesProvider } from 'react-cookie'
import { AdminHolidaysView } from '@view/admin/Holidays'
import { SocialLogin } from '@view/social'
import { LocalStorage } from '@util/localStorage'
import { LoadingHander } from '@component/LoadingHandle'
import { OnetimeLogin } from '@view/social/OnetimeLogin'

setZodMessage()
const BackToHandler = () => {
  const [{ backto }] = useQueryParams({
    backto: StringParam,
  })
  useEffect(() => {
    if (backto) {
      LocalStorage.backto.set(backto)
    }
  }, [backto])
  return null
}
function App() {
  const { data: { _me } = {}, loading } = useMeQuery({
    onError: (error) => {
      if (error.message === 'Response not successful: Received status code 401') {
        LocalStorage.setLogOut()
      }
    },
  })

  return (
    <RecoilRoot>
      <CookiesProvider />
      <ToastContainer theme="colored" />

      <BrowserRouter>
        <QueryParamProvider>
          <BackToHandler />
          <Suspense
            fallback={
              <div className="w-screen h-screen flex items-center justify-center">
                <div className="text-center">
                  <img src="https://img.icons8.com/fluency/96/000000/loading.png" alt="" className="animate-spin" />
                </div>
              </div>
            }
          >
            {loading ? (
              <LoadingHander />
            ) : (
              <Routes>
                <Route path="/" element={<Index />}>
                  {_me?.rolename === 'Admin' && (
                    <>
                      <Route path="/admin" element={<AdminView />}></Route>
                      <Route path="/admin/reserve/:id" element={<VistView />}></Route>
                      <Route path="/admin/invoice" element={<AdminView />}></Route>
                      <Route path="/admin/holidays" element={<AdminHolidaysView />}></Route>
                    </>
                  )}
                  <Route path="/mypage" element={<MypageView />}></Route>
                  <Route path="/mypage/buy" element={<BuyView />}></Route>
                  <Route path="/mypage/update" element={<UpdateUser />}></Route>
                  <Route path="/mypage/subscription/add" element={<AddSubscription />}></Route>
                  <Route path="/mypage/subscription/add/:id" element={<AddSubscription />}></Route>
                  <Route path="/regist" element={<RegistUser />}></Route>
                  <Route path="/regist/confirm" element={<MailConfirm />}></Route>
                  <Route path="/regist/confirm/:id" element={<MailConfirm />}></Route>
                  <Route path="/password/forgot" element={<ForgotPassword />}></Route>
                  <Route path="/password/reset/:code" element={<ResetPassword />}></Route>
                  {/* <Route path="/mypage/extend/:id" element={<ReserveExtend />}></Route>
                
                
                <Route path="/regist/confirm/:id" element={<MailConfirm />}></Route>*/}
                  <Route path="/reserve/:id" element={<ReserveDetailView />}></Route>
                  <Route path="/reserve/:id/:visit_id" element={<ReserveDetailView />}></Route>
                  <Route path="/visit/:id" element={<VisitDetailView />}></Route>
                  <Route path="/reserve" element={<ReserveView />}></Route>
                  <Route path="/service" element={<ServiceView />}></Route>
                  <Route path="/service/kiyaku" element={<KiyakuView />}></Route>
                  <Route path="/service/teiki" element={<TeikiView />}></Route>
                  <Route path="/social/login" element={<SocialLogin />}></Route>
                  <Route path="/social/start" element={<OnetimeLogin />}></Route>
                  <Route index element={<ReserveView />}></Route>
                </Route>
              </Routes>
            )}
          </Suspense>
        </QueryParamProvider>
      </BrowserRouter>
    </RecoilRoot>
  )
}

export default App
