import { ErrorMsg } from '@component/ErrorMsg'
import {
  useAdminAllUsersQuery,
  useAdminCreateVisitMutation,
  useAdminUpdateReserveMutation,
  useAdminUpdateVisitMutation,
  useAdminVisitsQuery,
} from '@generated/graphql'
import { DevTool } from '@hookform/devtools'
import { zodResolver } from '@hookform/resolvers/zod'
import { formatYMDHM } from '@util/format'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'
import { UserSelect } from './form/UserSelect'

const schema = z.object({
  start_at: z.date(),
  end_at: z.date(),
  user_id: z.string().nullish(),
})
type FieldInput = z.infer<typeof schema>
const VistView = () => {
  const { id } = useParams<{ id: string }>()
  if (!id) {
    return null
  }
  return <VisitBodyView id={id} />
}
const VisitBodyView = ({ id, onClose }: { id: string; onClose?: () => void }) => {
  const [createVisit] = useAdminCreateVisitMutation()
  const [updateVisit] = useAdminUpdateVisitMutation()
  const { data: { usersPermissionsUsers } = {} } = useAdminAllUsersQuery({ fetchPolicy: 'network-only' })
  const { data: { _adminVisits, reserves } = {}, refetch } = useAdminVisitsQuery({
    variables: { reserve_id: id ?? '1' },
    fetchPolicy: 'network-only',
    skip: !id,
  })
  // const [userId, setUserId] = useState('')
  const [filter, setFilter] = useState('')
  const [addUserMutation] = useAdminUpdateReserveMutation()
  const reserve = useMemo(() => {
    const r = reserves?.data.find((i) => true)
    if (r) {
      return { ...r.attributes, id: r.id }
    }
  }, [reserves])
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FieldInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      start_at: dayjs().toDate(),
      end_at: dayjs().toDate(),
    },
  })

  const addUser = useCallback(
    async (userId: string) => {
      if (!reserve?.id) {
        return
      }
      if (window.confirm('メンバーを追加します。よろしいですか？')) {
        try {
          await refetch()
          const users = (reserve.users?.data.map((i) => i.id).filter((i) => i) ?? []) as string[]
          await addUserMutation({
            variables: {
              data: {
                users: Array.from(new Set([...users, userId])),
              },
              id: reserve.id,
            },
          })
          toast.success('ユーザーを追加しました')
          await refetch()
        } catch (e) {
          toast.success(`ユーザー追加に失敗しました ${e}`)
        }
      }
    },
    [addUserMutation, reserve, refetch],
  )

  const onSubmit = handleSubmit(async (form) => {
    const user_id = reserve?.accessCode ? form.user_id : reserve?.buyer?.data?.id
    if (!user_id) {
      setError('user_id', { type: 'required', message: '必須です' })
      return
    } else {
      clearErrors('user_id')
    }
    if (window.confirm('データを更新します。よろしいですか？')) {
      try {
        const { data } = await createVisit({
          variables: {
            data: {
              startAt: dayjs(form.start_at).add(-9, 'hour').toDate(),
              endAt: dayjs(form.end_at).add(-9, 'hour').toDate(),
              isCanceled: false,
              userId: Number(user_id),
              reserveId: Number(id),
            },
          },
        })
        if (data?.createVisit?.data?.id) {
          toast.success('登録しました')
          await refetch()
        } else {
          toast.error(`登録に失敗しましtあ`)
        }
      } catch (errors) {
        toast.error(`登録に失敗しました`)
      }
    }
  })
  const Title = useCallback(() => {
    const buyer = reserve?.buyer?.data?.attributes
    if (!buyer) {
      return null
    }
    const companyName = reserve.invoiceAddress
      ? ` (${reserve.invoiceAddress})`
      : buyer.companyName
      ? ` (${buyer.companyName})`
      : ''
    return (
      <div className="my-4">
        <div className="font-bold text-xl">
          [{id}] {reserve?.plan?.data?.attributes?.name}
        </div>
        <div>
          <span className="">
            {buyer.sei} {buyer.mei}
          </span>{' '}
          <span className="ml-8 text-gray-400">{buyer.email}</span>
        </div>
        {companyName && <div>{companyName}</div>}
      </div>
    )
  }, [reserve, id])
  return (
    <div className="container mx-auto px-2">
      <div className="my-4">
        <Title />
        {reserve?.accessCode && (
          <div className="my-8">
            <div className="font-bold my-2">メンバー （{reserve?.users?.data.length}名)</div>
            <table className="ml-2">
              <tbody>
                {reserve?.users?.data.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.attributes?.email}</td>
                      <td className="italic text-gray-400 text-sm px-8">
                        {user.attributes?.sei} {user.attributes?.mei}
                      </td>
                      <td className="px-4">{user.attributes?.tel}</td>
                      <td>
                        {reserve?.buyer?.data?.id !== user.id ? (
                          <div
                            className="btn btn-xs btn-error"
                            onClick={async () => {
                              if (!reserve.id) {
                                return
                              }
                              if (window.confirm('削除します。よろしいですか？')) {
                                await addUserMutation({
                                  variables: {
                                    id: reserve.id,
                                    data: {
                                      users: (reserve.users?.data.map((i) => i.id).filter((i) => i !== user.id) ??
                                        []) as string[],
                                    },
                                  },
                                })
                                toast.success('ユーザーを削除しました')
                                refetch()
                              }
                            }}
                          >
                            削除
                          </div>
                        ) : (
                          <div>購入者</div>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <div className="flex my-4">
              <div className="form-control">
                <UserSelect
                  label="ユーザー追加"
                  users={
                    usersPermissionsUsers?.data
                      .filter((i) => !reserve?.users?.data.map((i) => i.id).includes(i.id))
                      .map((user) => {
                        if (!user.id || !user.attributes) {
                          throw Error('funny')
                        }
                        const u = { id: user.id, ...user.attributes }
                        return u
                      }) ?? []
                  }
                  className="btn btn-primary btn-sm"
                  onUserSelect={addUser}
                />
              </div>
            </div>
          </div>
        )}
        <div className="my-12">
          <div className="flex items-center gap-4 my-2">
            <div className="font-bold">利用予約一覧</div>
            <input
              className="input input-xs input-bordered"
              placeholder="email 氏名でフィルタ"
              onChange={(e) => {
                setFilter(e.target.value)
              }}
            ></input>
          </div>

          <div className="max-h-96 overflow-auto shadow-md bg-white w-[768px]">
            <table className="table table-compact table-zebra w-full">
              <thead className=" sticky top-0">
                <tr>
                  <td>日時</td>
                  <td>氏名</td>
                  <td>ステータス</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="clearbg">
                {_adminVisits
                  ?.filter((item) => {
                    return `${item.user.email} ${item.user.sei} ${item.user.mei}`.includes(filter)
                  })
                  ?.sort((a, b) => (dayjs(a.start_at).isAfter(b.start_at) ? -1 : 1))

                  .map((visit) => {
                    return (
                      <tr>
                        <td className={`${dayjs(visit.start_at).isSame(dayjs(), 'day') && ''}`}>
                          {visit.calendar_link ? (
                            <a href={visit.calendar_link} className="link" target={'_calendar'}>
                              {formatYMDHM(visit.start_at)} 〜 {formatYMDHM(visit.end_at)}
                            </a>
                          ) : (
                            <div>
                              {formatYMDHM(visit.start_at)} 〜 {formatYMDHM(visit.end_at)}
                            </div>
                          )}
                        </td>
                        <td>
                          <div>
                            {visit.user.sei} {visit.user.mei}
                          </div>
                          <div>{visit.user.email}</div>
                        </td>
                        <td>{visit.is_canceled ? 'キャンセル' : ''}</td>
                        <td>
                          {!visit.is_canceled && (
                            <div
                              className="btn btn-error btn-xs"
                              onClick={async () => {
                                if (!visit.id) {
                                  return
                                }
                                if (window.confirm('利用予約をキャンセルします。よろしいですか？')) {
                                  try {
                                    await updateVisit({
                                      variables: {
                                        id: visit.id,
                                        data: {
                                          isCanceled: true,
                                        },
                                      },
                                    })
                                    toast.success('利用予約をキャンセルしました')
                                    refetch()
                                  } catch (e) {
                                    toast.error(`利用予約をキャンセルに失敗しました ${e}`)
                                  }
                                }
                              }}
                            >
                              キャンセル
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <form className="my-8" onSubmit={onSubmit}>
          <div className="flex gap-2 items-center">
            <div className="form-control">
              <Controller
                name="start_at"
                control={control}
                render={(props) => (
                  <input
                    type="datetime-local"
                    {...register('start_at', { valueAsDate: true })}
                    value={dayjs(props.field.value).format('YYYY-MM-DD HH:mm')}
                    className="input input-bordered input-sm"
                  />
                )}
              />
              <ErrorMsg error={errors.start_at} />
            </div>
            <div className="form-control">
              <Controller
                name="end_at"
                control={control}
                render={(props) => (
                  <input
                    type="datetime-local"
                    {...register('end_at', { valueAsDate: true })}
                    className="input input-bordered input-sm"
                    value={dayjs(props.field.value).format('YYYY-MM-DD HH:mm')}
                  />
                )}
              />
              <ErrorMsg error={errors.end_at} />
            </div>
            {reserve?.accessCode && (
              <div className="form-control">
                <select className="select select-bordered w-full max-w-xs select-sm" {...register('user_id')}>
                  <option value={''}>利用予約ユーザー指定</option>
                  {reserve?.users?.data.map((user) => {
                    return (
                      <option key={user.id} value={user.id ?? ''}>
                        {user.attributes?.email} ({user.attributes?.sei} {user.attributes?.mei})
                      </option>
                    )
                  })}
                </select>
                <ErrorMsg error={errors.user_id} />
              </div>
            )}
            <button type="submit" className="btn btn-primary btn-sm">
              追加
            </button>
          </div>
          <DevTool control={control} />
        </form>
        {!onClose && (
          <Link to={'/admin'} className="btn">
            戻る
          </Link>
        )}
      </div>
      {onClose && (
        <div className="btn btn-circle absolute top-4 right-4" onClick={onClose}>
          Ｘ
        </div>
      )}
    </div>
  )
}
export { VistView, VisitBodyView }
