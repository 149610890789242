import { formatHM } from '@util/format'
import { Dayjs } from 'dayjs'
import { FC, useCallback, useMemo } from 'react'

const TimeBar: FC<{
  start: Dayjs
  end: Dayjs
  interval: number
  unit: number
  reserves: Dayjs[]
  onSelectFrom: (selectTime: Dayjs) => void
  onSelectTo: (selectTime: Dayjs) => void
  onClear: () => void
  from?: Dayjs
  to?: Dayjs
}> = ({ start, end, interval, unit, reserves, onSelectFrom, onSelectTo, onClear, from, to }) => {
  const click = useCallback(
    (time: Dayjs) => {
      if (!from) {
        onSelectFrom(time)
      } else {
        onSelectTo(time)
      }
    },
    [onSelectFrom, onSelectTo, from],
  )

  const times = useMemo(() => {
    const ts: { time: Dayjs; isSelectable: boolean; selected: boolean; reserved: boolean }[] = []
    let newTime = start //from ?? start
    const lastTime = end //to ?? end

    const firstReserve = reserves
      .sort((a, b) => (a.isBefore(b) ? -1 : 1))
      .find((i) => i.isAfter(from) || i.isSame(from))

    while (newTime.isBefore(lastTime) || newTime.isSame(lastTime)) {
      let isSelectable = true
      // 選ばれた時間は選択不可
      if (to) {
        isSelectable = false
      } else if (newTime.isSame(from) || newTime.isSame(to)) {
        isSelectable = false
      } else if (from) {
        if (newTime.isBefore(from)) {
          isSelectable = false
        } else if (firstReserve && newTime?.isAfter(firstReserve)) {
          isSelectable = false
        }
      }

      const fs = newTime
      const reserved = reserves
        .filter((i) => {
          // fromが選ばれている = toの選択 = 後続予約の開始時刻は選択可能にする
          return from ? !i.isSame(firstReserve) : true
        })
        .some((i) => {
          return i.isSame(fs)
        })

      ts.push({
        time: newTime,
        isSelectable: reserved ? false : isSelectable,
        selected: newTime.isSame(from) || newTime.isSame(to),
        reserved,
      })
      newTime = newTime.add(interval, 'minute')
    }

    return ts
  }, [start, end, interval, from, to, reserves])

  return (
    <div className="inline-block">
      <div className="w-56">
        <div className="h-80 overflow-auto bg-white border-2 ">
          {times.map((i) => (
            <div
              className={`text-center border-2 border-collapse text-sm ${
                i.selected && 'font-bold bg-blue-300 text-white sticky top-0 bottom-0'
              } ${!i.selected && !i.isSelectable && 'text-gray-300'}  ${i.reserved && 'bg-red-300'} `}
              onClick={() => {
                if (i.isSelectable) {
                  click(i.time)
                }
              }}
              key={i.time.toISOString()}
            >
              <div className="p-2">
                {formatHM(i.time.toISOString())}
                {from?.isSame(i.time) && <>から</>}
                {to?.isSame(i.time) && <>まで</>}
              </div>
            </div>
          ))}
        </div>
        <div className="py-2 text-right">
          <div className="btn btn-primary btn-xs" onClick={onClear}>
            クリア
          </div>
        </div>
      </div>
    </div>
  )
}

export { TimeBar }
