const ONETIME_KEY = 'onetime'
const SessionStorage = {
  setOnetimeLogin: (id: string) => {
    sessionStorage.setItem(ONETIME_KEY, id)
  },
  isOnetimeLogin: () => {
    return sessionStorage.getItem(ONETIME_KEY)
  },
}
export { SessionStorage }
