import { useForgotPasswordMutation } from '@generated/graphql'

import { Link } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { ErrorMsg } from '@component/ErrorMsg'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { ENV } from '@util/env'
import { LoadingHander } from '@component/LoadingHandle'

const schema = z.object({
  email: z.string().max(150).email('メールアドレス形式で入力してください').nonempty(),
})
type formData = z.infer<typeof schema>
const ForgotPassword = () => {
  const [forgot, { loading }] = useForgotPasswordMutation()
  const [csrf, setCsrf] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formData>({
    resolver: zodResolver(schema),
    defaultValues: { email: '' },
  })

  const onSubmit = async (form: formData) => {
    try {
      const { data } = await forgot({ variables: { email: `${form.email}***${csrf}` } })
      if (data?.forgotPassword?.ok) {
        toast.success('パスワードリセットのメールを送信しました。メールをご確認ください。', {})
      }
    } catch (err) {
      toast.error('メールの送信に失敗しました。メールアドレスをご確認ください。')
    }
  }

  useEffect(() => {
    axios.post<string>(`${ENV.API_HOST_URL}/api/crons/session`).then(({ data }) => {
      setCsrf(data)
    })
  }, [])

  if (!csrf) {
    return <LoadingHander />
  }
  return (
    <div className="p-6">
      <div>
        <div className="my-4 text-lg font-bold">
          メールアドレスを入力してください。パスワード変更のメールを送信します。
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
          <div className="form-control">
            <label className="label">
              <span className="label-text">メールアドレス</span>
            </label>
            <input {...register('email')} className="input input-bordered" maxLength={150} />
            <ErrorMsg error={errors.email} />
          </div>

          <div className="form-control py-4 mt-10">
            <input type="submit" value="メール送信" className={`btn btn-primary ${loading && 'loading'}`} />
          </div>
        </form>
      </div>

      <Link to="/" className="link">
        トップに戻る
      </Link>
    </div>
  )
}
export { ForgotPassword }
