import { z, ZodIssueCode, ZodIssueOptionalMessage } from 'zod'
type ErrorMapCtx = {
  defaultError: string
  data: any
}
const setZodMessage = () => {
  const zodErrorMap = (issue: ZodIssueOptionalMessage, _ctx: ErrorMapCtx): { message: string } => {
    let message: string
    switch (issue.code) {
      case ZodIssueCode.invalid_type:
        if (issue.received === 'undefined') {
          message = '必須です'
        } else {
          if (issue.expected === 'string')
            // message = `String must contain ${issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`
            message = `文字列を入力してください`
          else if (issue.expected === 'number')
            // message = `Number must be less than ${issue.inclusive ? `or equal to ` : ``}${issue.maximum}`
            message = `数字で入力してください`
          else message = '入力データのデータ形式に誤りがあります'
        }
        break
      case ZodIssueCode.unrecognized_keys:
        message = `Unrecognized key(s) in object: ${issue.keys.map((k) => `'${k}'`).join(', ')}`
        break
      case ZodIssueCode.invalid_union:
        message = `Invalid input`
        break
      case ZodIssueCode.invalid_enum_value:
        message = `Invalid enum value. Expected ${issue.options
          .map((val) => (typeof val === 'string' ? `'${val}'` : val))
          .join(' | ')}`
        break
      case ZodIssueCode.invalid_arguments:
        message = `Invalid function arguments`
        break
      case ZodIssueCode.invalid_return_type:
        message = `Invalid function return type`
        break
      case ZodIssueCode.invalid_date:
        // message = `Invalid date`
        message = `日付の書式になっていません`
        break
      case ZodIssueCode.invalid_string:
        // if (issue.validation !== 'regex') message = `Invalid ${issue.validation}`
        // else message = 'Invalid'
        if (issue.validation !== 'regex') message = `書式に誤りがあります`
        else message = '書式に誤りがあります'
        break
      case ZodIssueCode.too_small:
        if (issue.type === 'array')
          message = `Array must contain ${issue.inclusive ? `at least` : `more than`} ${issue.minimum} element(s)`
        else if (issue.type === 'string')
          message =
            issue.minimum === 1
              ? '必須です'
              : // : `String must contain ${issue.inclusive ? `at least` : `over`} ${issue.minimum} character(s)`
                `${issue.minimum}${issue.inclusive ? `文字以上` : `文字を超える`} で入力してください`
        else if (issue.type === 'number')
          // message = `Number must be greater than ${issue.inclusive ? `or equal to ` : ``}${issue.minimum}`
          message = `${issue.minimum}${issue.inclusive ? `以上の` : `を超える`}数値を入力してください`
        else message = 'Invalid input'
        break
      case ZodIssueCode.too_big:
        if (issue.type === 'array')
          message = `Array must contain ${issue.inclusive ? `at most` : `less than`} ${issue.maximum} element(s)`
        else if (issue.type === 'string')
          // message = `String must contain ${issue.inclusive ? `at most` : `under`} ${issue.maximum} character(s)`
          message = `${issue.maximum}文字${issue.inclusive ? `以内` : `未満`}で入力してください`
        else if (issue.type === 'number')
          // message = `Number must be less than ${issue.inclusive ? `or equal to ` : ``}${issue.maximum}`
          message = `${issue.maximum}${issue.inclusive ? `以下` : `未満`}の数値を入力してください`
        else message = 'Invalid input'
        break
      case ZodIssueCode.custom:
        message = `Invalid input`
        break
      case ZodIssueCode.invalid_intersection_types:
        message = `Intersection results could not be merged`
        break
      case ZodIssueCode.not_multiple_of:
        message = `Number must be a multiple of ${issue.multipleOf}`
        break
      default:
        message = _ctx.defaultError
      // util.assertNever(issue);
    }
    return { message }
  }

  z.setErrorMap(zodErrorMap)
}
export { setZodMessage }
