import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'
import { useMeQuery, usePlanQuery, useServiceQuery } from '@generated/graphql'
import { UnitNames } from '@util/env'
import { Link } from 'react-router-dom'

import { LoadingHander } from '@component/LoadingHandle'

import { useMemo } from 'react'
import { SubscriptionView } from '@view/mypage/subscriptions'
import { useOpenLogin } from '@component/LoginForm'

registerLocale('ja', ja)

const Detail = ({ text }: { text?: string | null }) => {
  const txt = text?.split(/\n/) //.filter((i, idx) => idx < 2)
  if (!txt) {
    return null
  }
  return (
    <div className="p-2">
      {txt.map((i, idx) => (
        <div key={idx}>{i}</div>
      ))}
    </div>
  )
}
const ReserveView = () => {
  const { data: { _me: me, _subscriptions } = {}, loading } = useMeQuery()
  const { data: { _Plans: categories } = {} } = usePlanQuery()
  const { data: { service: s } = {} } = useServiceQuery()
  const openLogin = useOpenLogin()
  const service = useMemo(() => s?.data?.attributes, [s])

  if (loading && !service) {
    return <LoadingHander />
  }
  return (
    <>
      {!me ? (
        <div className="container mx-auto px-4">
          <div className="my-4 text-lg font-bold">{service && service.name} 予約サイト</div>
          <div className="w-full text-center p-4">
            <div className="p-4">
              <div className="btn btn-primary btn-wide" onClick={openLogin}>
                ログイン
              </div>
            </div>
            <Link to="/regist" className="p-4">
              <div className="btn btn-primary btn-wide">新規会員登録</div>
            </Link>
          </div>
        </div>
      ) : (
        <div className="container mx-auto px-4">
          {_subscriptions && _subscriptions.length > 0 && (
            <div className="py-4">
              <div className="text-2xl font-bold">月間、年間利用をお持ちの方の利用予約</div>
              <div className="py-4">
                <SubscriptionView />
              </div>
            </div>
          )}
          {service?.aboutTeiki && (
            <div className="py-4">
              <div>
                定期購入の利用方法については
                <Link to="/service/teiki" target="_blank" className="link">
                  こちら
                </Link>
                をご確認ください
              </div>
            </div>
          )}

          {categories?.map((c) => {
            if (!c.plans?.length) {
              return null
            }
            return c.is_only_top ? (
              <div className="py-4 mb-4" key={c.id}>
                <div className="lg:flex items-center">
                  <div className="text-2xl font-bold">{c.name}</div>{' '}
                  {c.message && <div className="text-sm lg:px-2">{c.message}</div>}
                </div>
                <div className="p-4 lg:p-8 rounded-lg my-4 text-neutral flex justify-between items-center bg-white">
                  {c.plans?.map(({ plan }) => {
                    return (
                      <div className="my-2" key={plan.id}>
                        <div className="font-bold text-lg">{plan.name}</div>
                        <Detail text={plan.detail} />
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : (
              <div className="py-4" key={c.id}>
                <div className="lg:flex items-center">
                  <div className="text-2xl font-bold">{c.name}</div>{' '}
                  {c.message && <div className="text-sm lg:px-2">{c.message}</div>}
                </div>
                {c.plans?.map(({ plan }) => {
                  return (
                    <div
                      key={`${c.id}${plan.id}`}
                      className="p-4 lg:p-8 shadow-lg rounded-lg my-4 text-neutral flex justify-between items-center bg-white"
                    >
                      <div className="w-full">
                        <div className="font-bold text-lg link">{plan.name}</div>
                        <Detail text={plan.detail} />
                        <div className="px-2">
                          {plan.unit_amount} {UnitNames[plan.unit]} {plan.unit_price?.toLocaleString()}円
                          {plan.limit_price && <div>1日最大 {plan.limit_price.toLocaleString()}円</div>}
                        </div>
                        <div className="text-center w-full mt-4">
                          <Link
                            to={`/reserve/${plan.id}`}
                            key={`${c.id}${plan.id}`}
                            className="btn btn-primary btn-wide"
                          >
                            予約する
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}

export { ReserveView }
