import dayjs from 'dayjs'

const ENV = {
  API_HOST_URL: process.env.REACT_APP_API_HOST_URL ?? 'http://localhost:1337',
  IMAGE_HOST_URL: process.env.REACT_APP_IMAGE_HOST_URL || '',
  EPSILON_ID: process.env.REACT_APP_EPSILON_ID,
  EPSILON_TOKEN_URL: process.env.REACT_APP_EPSILON_TOKEN_URL ?? '',
  ENV_NAME: process.env.REACT_APP_ENV_NAME ?? '',
}

const ReserveState = {
  reserved: { sort: 0, name: '予約済み' },
  using: { sort: 1, name: '使用中' },
  notified: { sort: 2, name: '使用中' },
  returned: { sort: 3, name: '返却済み' },
  settled: { sort: 4, name: '決済済み' },
  canceled: { sort: 5, name: 'キャンセル' },
}

const UnitNames: { [key: string]: string } = {
  minutes: '分',
  day: '日',
  month: 'ヶ月間',
  year: '年間',
}

const TimePickerInject = () => {
  const injectArr = []
  const now = dayjs()
  for (const t of [9, 10, 11, 12, 13, 14, 15, 16, 17]) {
    const h = now.hour(t)
    injectArr.push(h.minute(15).toDate())
    injectArr.push(h.minute(45).toDate())
  }
  return injectArr
}
export { ENV, ReserveState, UnitNames, TimePickerInject }
