import { useDayVisitsLazyQuery, useHolidaysQuery, useMeQuery, useVisitMutation } from '@generated/graphql'

import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import ReactDatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { toast } from 'react-toastify'

import { TimeBar } from '@component/TimeBar'
import { ApolloError } from '@apollo/client'

const schema = z.object({
  start_at: z.date(),
  end_at: z.date(),
})
type fields = z.infer<typeof schema>

const VisitDetailView = () => {
  const { id: reserve_id = '1' } = useParams<{ id: string }>()

  const { data: { _holidays } = {} } = useHolidaysQuery({ fetchPolicy: 'network-only' })
  const { data: { _subscriptions, _faceauthed } = {} } = useMeQuery({ fetchPolicy: 'network-only' })
  const [visitQuery] = useDayVisitsLazyQuery()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<fields>({
    resolver: zodResolver(schema),
  })

  const [visitMutaion, { loading }] = useVisitMutation()
  const [start_at, end_at] = watch(['start_at', 'end_at'])

  const [selectDate, setSelectDate] = useState<Date>()
  const modalRef = useRef<HTMLInputElement>(null!)

  const reserve = useMemo(() => {
    if (!_subscriptions) {
      return null
    }

    return _subscriptions.find((sub) => sub.id === reserve_id)
  }, [_subscriptions, reserve_id])

  const reservedTimes = useMemo(() => {
    const start_at = dayjs(selectDate).startOf('day').hour(9)
    const end_at = dayjs(selectDate).startOf('day').hour(18)
    let loops = 0
    const times = []

    while (true) {
      const tmp = start_at.add(15 * loops, 'minute')

      if (tmp.isAfter(end_at)) {
        break
      }

      const stop = _holidays?.some((h) => {
        return h.is_stop_using && (tmp.isAfter(h.start_at) || tmp.isSame(h.start_at)) && tmp.isBefore(h.end_at)
      })
      if (stop) {
        times.push(tmp.toDate())
        loops++
        continue
      }
      loops++
    }
    return times.map((t) => dayjs(t))
  }, [selectDate, _holidays])

  const submit = (e: fields) => {
    if (errors) {
      console.log(errors)
    }
    modalRef.current.checked = true
  }

  const reserveMu = async () => {
    try {
      const success = await visitMutaion({
        variables: {
          input: {
            reserve_id,
            start_at,
            end_at,
          },
        },
      })
      if (success) {
        toast.success('予約が完了しました')
        navigate('/mypage')
      }
    } catch (e) {
      if (e instanceof ApolloError) {
        toast.error(e.message)
      } else {
        toast.error(`${e}`)
      }
    }
  }
  const startDate = useMemo(() => {
    const start = dayjs().add(reserve?.is_touzitsu_yoyaku && _faceauthed ? 0 : 1, 'day')
    // const day = reserve?.deadline_days ? 1 : 0
    return start.isAfter(reserve?.start_at) ? start.toDate() : dayjs(reserve?.start_at).toDate()
  }, [_faceauthed, reserve?.is_touzitsu_yoyaku, reserve?.start_at])

  // const startTime = useMemo(() => {
  //   if (dayjs().isSame(selectDate, 'd')) {
  //     // const minute = dayjs(startDate).minute()
  //     // const nextMinute = minute + (15 - (minute % 15))
  //     // return dayjs(startDate).minute(nextMinute)
  //     return dayjs(selectDate).hour(9).startOf('hour')
  //   } else {
  //     return dayjs(selectDate).hour(9).startOf('hour')
  //   }
  // }, [selectDate])

  useEffect(() => {
    if (selectDate) {
      visitQuery({ variables: { input: { reserve_id, day: selectDate } } })
    }
  }, [selectDate, visitQuery, reserve_id])

  if (!reserve) {
    return null
  }

  return (
    <div className="container mx-auto px-2">
      <div className="grid lg:grid-cols-2 gap-2">
        <div className="p-4">
          <form onSubmit={handleSubmit(submit)}>
            <div className="my-4 text-neutral flex justify-between items-center">
              <div>
                <div className="text-2xl font-bold">{reserve.plan_name} 利用予約</div>
              </div>
            </div>
            <div className=" lg:flex gap-4">
              <div className="text-center">
                <div>ご利用日を指定してください</div>
                <ReactDatePicker
                  // selected={selectDate}
                  minDate={startDate}
                  maxDate={dayjs(reserve.end_at).toDate()}
                  onChange={(e) => {
                    // 利用予約からの施設予約の場合 日は変更不可

                    if (e instanceof Date) {
                      setSelectDate(e)
                      reset({ start_at: undefined, end_at: undefined })
                      // const day = dayjs(e)
                      // const start = day.hour(9).minute(0).startOf('minute')
                      // setValue('start_at', start.toDate())
                      // setValue('end_at', start.hour(18).minute(0).add(1, 'day').add(-1, 'day').toDate())
                    }
                  }}
                  inline
                />
              </div>
              <div className="text-center">
                {selectDate && (
                  <TimeBar
                    start={dayjs(selectDate).hour(9).startOf('hour')}
                    end={dayjs(selectDate).hour(18).startOf('hour')}
                    interval={15}
                    unit={60}
                    reserves={reservedTimes}
                    onSelectFrom={(time) => {
                      reset({
                        start_at: time.toDate(),
                        end_at: undefined,
                      })
                    }}
                    onSelectTo={(time) => {
                      setValue('end_at', time.toDate())
                    }}
                    onClear={() => {
                      reset({ start_at: undefined, end_at: undefined })
                    }}
                    from={start_at && dayjs(start_at)}
                    to={end_at && dayjs(end_at)}
                  />
                )}
              </div>
            </div>
            {end_at && (
              <>
                <input type="hidden" {...register('start_at')} />
                <input type="hidden" {...register('end_at')} />
                <button className="btn btn-primary">予約する</button>
              </>
            )}
          </form>
        </div>
      </div>
      <div>
        <Link className="btn" to="/">
          戻る
        </Link>
      </div>

      <div>
        <input type="checkbox" ref={modalRef} className="modal-toggle" />
        <div className="modal modal-bottom sm:modal-middle" onClick={() => (modalRef.current.checked = false)}>
          <div className="modal-box" onClick={(e) => e.stopPropagation()}>
            <h3 className="font-bold text-lg">予約しますか</h3>
            <div>
              <div className="my-2">
                {dayjs(start_at).format('YYYY/MM/DD HH:mm')}から
                <br />
                {dayjs(end_at).format('YYYY/MM/DD HH:mm')}まで
              </div>
            </div>
            <div className="modal-action">
              <label htmlFor="my-modal-6" className={`btn btn-primary ${loading && 'loading'}`} onClick={reserveMu}>
                予約する
              </label>
              <label htmlFor="my-modal-6" className="btn" onClick={() => (modalRef.current.checked = false)}>
                戻る
              </label>
            </div>
          </div>
        </div>
      </div>
      <DevTool control={control} />
    </div>
  )
}

export { VisitDetailView }
