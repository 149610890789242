import { ChangeEvent, FC, FocusEvent, useRef, useState } from 'react'

import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

import { useForm } from 'react-hook-form'
import { ErrorMsg } from '@component/ErrorMsg'
import { useRecoilState } from 'recoil'

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReserveData } from '@util/recoil'

const checkId = `id_${Math.round(Math.random() * 10000)}`

declare global {
  interface Window {
    tokenCb: (e: any) => void
  }
}
const schema = z.object({
  name: z
    .string()
    .regex(/^[a-zA-Z0-9 ]{1,100}$/, { message: 'アルファベットで入力してください' })
    .nonempty(),
  number: z
    .string()
    .regex(/^[0-9]{15,16}$/, { message: '数字で15〜16桁で入力してください' })
    .nonempty(),
  expiry: z
    .string()
    .regex(/^[0-1][0-9][2-3][0-9]$/, { message: '月2桁/年下2桁です' })
    .nonempty(),
  cvc: z
    .string()
    .regex(/^[0-9]{3,4}$/, { message: '数字で4桁以内です' })
    .nonempty(),
})
type CardData = z.infer<typeof schema>
const CardEntry: FC<{ onComplete?: (file: Blob) => void }> = ({ onComplete }) => {
  const [, setReserveInfo] = useRecoilState(ReserveData)
  const [focus, setFocus] = useState('name')
  const [loadingToken, setLoadingToken] = useState(false)
  const switchRef = useRef<HTMLInputElement>(null!)
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<CardData>({
    resolver: zodResolver(schema),
  })

  const watchAllFields = watch()
  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    setFocus(e.target.name.replace(/_mm|_yy/, ''))
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/^([0-9]{2})([0-9])/, '$1 / $2').replace(/(.*) \/ $/, '$1')
  }
  const onSubmit = (form: CardData) => {
    setLoadingToken(true)
    const card = {
      cardno: form.number,
      expire: `20${form.expiry.substring(2, 4)}${form.expiry.substring(0, 2)}`,
      securitycode: form.cvc,
      holdername: form.name,
    }

    setReserveInfo((item) => {
      return {
        ...item,
        card,
      }
    })
    switchRef.current.checked = false
    setLoadingToken(false)
  }
  return (
    <>
      <input type="checkbox" ref={switchRef} id={checkId} className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div id="PaymentForm">
              <Cards
                cvc={watchAllFields.cvc ?? ''}
                expiry={watchAllFields.expiry ? watchAllFields.expiry.replace(' / ', '') : ''}
                focused={focus as any}
                name={watchAllFields.name ?? ''}
                number={watchAllFields.number ?? ''}
                preview={true}
              />
              <div className="form-control">
                <label className="label">
                  <span className="label-text">カード番号</span>
                </label>
                <input
                  type="text"
                  maxLength={16}
                  placeholder="数字で15〜16桁"
                  {...register('number')}
                  onFocus={onFocus}
                  className="input input-bordered"
                  autoComplete="cc-number"
                />
                <ErrorMsg error={errors.number} />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">名義</span>
                </label>
                <input
                  type="text"
                  placeholder="アルファベットで入力してください"
                  maxLength={100}
                  {...register('name')}
                  onFocus={onFocus}
                  className="input input-bordered"
                />
                <ErrorMsg error={errors.name} />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">有効期限</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      maxLength={7}
                      placeholder="MMYY"
                      {...register('expiry', { onChange, setValueAs: (v) => v.replace(' / ', '') })}
                      onFocus={onFocus}
                      className="input input-bordered w-full "
                    />
                  </div>
                  <ErrorMsg error={errors.expiry} />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">cvc</span>
                  </label>
                  <input
                    type="text"
                    maxLength={4}
                    placeholder=""
                    {...register('cvc')}
                    onFocus={onFocus}
                    className="input input-bordered"
                  />
                  <ErrorMsg error={errors.cvc} />
                </div>
              </div>
            </div>

            <div className="modal-action">
              <button type="submit" className={`btn btn-primary ${loadingToken && 'loading'}`}>
                登録
              </button>
              <label
                htmlFor={checkId}
                className="btn"
                onClick={() => {
                  reset()
                }}
              >
                閉じる
              </label>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { CardEntry, checkId }
