import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja'

import { Outlet } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import { SVGServiceName } from '@img/servicename'
import { LoginForm, useOpenLogin } from '@component/LoginForm'
import { LocalStorage } from '@util/localStorage'

import { useEffect, useRef } from 'react'
import { ENV } from '@util/env'
import { useMeQuery, useServiceQuery } from '@generated/graphql'
import { BackTo } from '@component/BackTo'

registerLocale('ja', ja)
const Index = () => {
  const { data: { _me: me } = {} } = useMeQuery()
  const { data: { service } = {} } = useServiceQuery()
  const openLogin = useOpenLogin()
  const epsilonScript = useRef<HTMLDivElement>(null!)
  const location = useLocation()
  const switchRef = useRef<HTMLInputElement>(null!)
  const close = () => {
    if (switchRef) {
      switchRef.current.checked = false
    }
  }

  useEffect(() => {
    const scriptUrl = document.createElement('script')
    scriptUrl.type = 'text/javascript'
    scriptUrl.src = ENV.EPSILON_TOKEN_URL
    epsilonScript.current.appendChild(scriptUrl)
  }, [])

  useEffect(() => {
    document.querySelector('#body')?.scroll({ top: 0, left: 0 })
  }, [location])

  useEffect(() => {
    LocalStorage.showMessage()
  }, [])
  return (
    <div>
      <LoginForm />

      <div className="shadow bg-base-200 drawer">
        <input id="switch" type="checkbox" ref={switchRef} className="drawer-toggle" />
        <div className="flex flex-col drawer-content " id="body">
          <div className="w-full navbar bg-blue-300 text-neutral-content h-16">
            <div className="flex-1 px-2">
              <div className="h-16">
                <a className="text-2xl font-bold font-serif" href="/">
                  <SVGServiceName />
                </a>
              </div>
              {ENV.ENV_NAME && <div className="mx-4 text-2xl">{ENV.ENV_NAME}</div>}
              <BackTo warn />
            </div>
            {me ? (
              <label className="btn btn-circle" htmlFor="switch">
                {me.sei?.charAt(0)}
              </label>
            ) : (
              <label htmlFor="switch" className="btn btn-square btn-ghost">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-6 h-6 stroke-current"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </label>
            )}
          </div>

          <div className="mb-32">
            <Outlet />
          </div>
          <footer className="footer p-10 bg-blue-300 text-white">
            <div>
              {/* <SVGServiceName /> */}
              <p className="font-bold text-lg">{service?.data?.attributes?.name}</p>
            </div>
            <div>
              <span className="footer-title">Services</span>
              <Link
                to="/"
                onClick={(e) => {
                  window.scrollTo(0, 0)
                }}
                className="link link-hover"
              >
                シェアオフィス
              </Link>
            </div>
            {/* <div>
              <span className="footer-title">Company</span>
              <Link to="/service" className="link link-hover">
                About us
              </Link>
            </div> */}
            <div>
              <span className="footer-title">Legal</span>
              <a
                className="link link-hover"
                href="https://www.kamishihoro.work/terms.html"
                target={'_blank'}
                rel="noreferrer"
              >
                利用規約
              </a>
              <a
                className="link link-hover"
                href="https://www.kamishihoro.work/legal-info.html"
                target={'_blank'}
                rel="noreferrer"
              >
                特定商取引法に基づく表示
              </a>
              <a
                className="link link-hover"
                href="https://www.kamishihoro.work/privacy.html"
                target={'_blank'}
                rel="noreferrer"
              >
                プライバシーポリシー
              </a>
            </div>
          </footer>
        </div>
        <div className="drawer-side">
          <label htmlFor="switch" className="drawer-overlay"></label>
          <div className="p-4 overflow-y-auto menu w-80 bg-base-100">
            {me && (
              <div className="text-lg font-bold m-4">
                {me.sei} {me.mei} 様
              </div>
            )}
            <ul className="pl-0">
              {me && (
                <>
                  <li>
                    <Link to="/mypage" onClick={close}>
                      予約一覧
                    </Link>
                  </li>
                  <li>
                    <Link to="/mypage/buy" onClick={close}>
                      購入一覧
                    </Link>
                  </li>
                  <li>
                    <Link to="/mypage/update" onClick={close}>
                      登録情報編集
                    </Link>
                  </li>
                  <li>
                    <Link to="/mypage/subscription/add" onClick={close}>
                      年間利用アクセスコード入力
                    </Link>
                  </li>
                </>
              )}
            </ul>
            {me ? (
              <div
                className="btn btn-secondary w-full mt-4 mb-10"
                onClick={() => {
                  LocalStorage.setLogOut()
                  LocalStorage.setMessage('ログアウトしました')
                  window.location.href = '/'
                }}
              >
                ログアウト
              </div>
            ) : (
              <label
                onClick={() => {
                  openLogin()
                  close()
                }}
                className="btn btn-primary text-white mb-10"
              >
                ログイン
              </label>
            )}
            <div className="text-lg font-bold m-4">シェアオフィスサービス</div>
            <ul>
              <li>
                <Link to="/" onClick={close}>
                  予約
                </Link>
              </li>
              {/* {service && (
                <li>
                  <Link to="/service" onClick={close}>
                    {service.data?.attributes?.name}について
                  </Link>
                </li>
              )} */}
            </ul>
            {me?.rolename === 'Admin' && (
              <div className="mt-8">
                <div className="text-lg font-bold m-4">管理者メニュー</div>

                <ul>
                  <li>
                    <Link to="/admin" onClick={close}>
                      利用者・予約・承認・決済 管理
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/holidays" onClick={close}>
                      祝日 休館設定
                    </Link>
                  </li>
                  <li>
                    <a href={`${ENV.API_HOST_URL}/ksadmin`} target={'_blank'} rel="noreferrer" onClick={close}>
                      コンテンツ管理 (Strapi)
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={epsilonScript} />
    </div>
  )
}

export { Index }
