import { AdminAllUsersQuery } from '@generated/graphql'
import { useMemo, useState, VFC } from 'react'

const parseUsers = (users: AdminAllUsersQuery['usersPermissionsUsers']) => {
  return (
    users?.data.map((u) => {
      if (!u.id || !u.attributes) {
        throw Error('funny')
      }
      return {
        id: u.id,
        ...u.attributes,
      }
    }) ?? []
  )
}
const UserSelect: VFC<
  {
    label?: string
    users: { sei: string; mei: string; email: string; id: string }[]
    userId?: string
    onUserSelect: (id: string) => void
  } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ label, users, userId, onUserSelect, ...props }) => {
  // const { data: { usersPermissionsUsers: users } = {} } = useAdminAllUsersQuery({ fetchPolicy: 'network-only' })
  const [open, setOpen] = useState(false)
  const toggle = () => {
    setOpen((item) => !item)
  }
  const user = useMemo(() => {
    if (userId) {
      return users.find((i) => i.id === userId)
    }
  }, [users, userId])
  const [filter, setFilter] = useState('')
  return (
    <div className="relative">
      <div className="flex items-center">
        <div {...props} onClick={toggle}>
          {label ? label : 'ユーザー選択'}
        </div>
        {user && <div className="mx-2">{`${user.email} (${user.sei} ${user.mei})`}</div>}
      </div>
      {open && (
        <div className="border-2 rounded-md bg-base-100 absolute p-2 drop-shadow-lg mt-2 z-10">
          <input
            placeholder="filter"
            onChange={(e) => setFilter(e.target.value)}
            className="py-2 input input-bordered input-sm w-full"
          />
          <ul className="my-2 max-h-56 overflow-auto">
            {users
              .filter((i) => i.email.includes(filter))
              .map((user) => {
                return (
                  <li
                    className="text-xs p-2 hover:bg-base-300 rounded-md cursor-pointer"
                    onClick={() => {
                      onUserSelect?.(user.id ?? '')
                      setOpen(false)
                    }}
                    key={user.id}
                  >
                    <span className="whitespace-nowrap"> {`${user.email} (${user.sei} ${user.mei})`}</span>
                  </li>
                )
              })}
          </ul>
        </div>
      )}
    </div>
  )
}

export { UserSelect, parseUsers }
