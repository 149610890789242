import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ReserveStatus, useMeQuery } from '@generated/graphql'
import { formatYMDHM } from '@util/format'
import dayjs from 'dayjs'

const SubscriptionView: FC<{ title?: string }> = ({ title }) => {
  const { data: { _me: me } = {} } = useMeQuery()

  const subscriptions = useMemo(() => {
    const now = dayjs()
    return me?.reserves?.filter(
      (i) => i.status !== ReserveStatus.Canceled && !i.plan.with_visit && i.is_authed && dayjs(i.end_at).isAfter(now),
    )
  }, [me])

  return (
    <>
      {subscriptions && subscriptions.length > 0 && (
        <div className="">
          {title && <div className="font-bold text-lg py-2">{title}</div>}
          <div className="w-full overflow-scroll max-h-[60vh] border-2">
            <table className="table table-compact w-full">
              <thead className="sticky top-0 z-20">
                <tr>
                  <td>プラン名</td>
                  <td>利用予約</td>
                </tr>
              </thead>
              <tbody>
                {subscriptions
                  .sort((before, after) => {
                    return before.created_at <= after.created_at ? 1 : -1
                  })
                  .map((reserve) => {
                    return (
                      <tr className={`bg-white`} key={reserve.id}>
                        <td className="bg-transparent">
                          <div className="">
                            {formatYMDHM(reserve.start_at)} 〜 {formatYMDHM(reserve.end_at)}
                            <br />
                            <span className="text-sm text-gray-400">{reserve?.plan?.name}</span>
                          </div>
                        </td>
                        <td className="bg-transparent">
                          {dayjs(reserve.end_at).isAfter(dayjs().endOf('day')) && (
                            <>
                              <Link to={`/visit/${reserve.id}`} className="btn btn-primary btn-xs mx-2">
                                利用予約
                              </Link>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}
export { SubscriptionView }
