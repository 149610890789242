import dayjs from 'dayjs'
import { atom } from 'recoil'

export type TimeLineContent = {
  time: dayjs.Dayjs
  status: TimelineStatus
  isLastOfDay: boolean
}
export enum TimelineStatus {
  before = -3,
  locked = -2,
  reserved = -1,
  over = -4,
  OK = 1,
}

export type ReserveInfo = {
  card?: CardInfo
}
export type CardInfo = {
  cardno: string
  expire: string
  securitycode: string
  holdername: string
}
export type CardToken = {
  token: string
  tokenExpiry: dayjs.Dayjs
}

const ReserveData = atom<ReserveInfo>({
  key: 'CardData',
  default: {},
})

export { ReserveData }
