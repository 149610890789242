import { useResetPasswordMutation } from '@generated/graphql'

import { Link, useNavigate, useParams } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { ErrorMsg } from '@component/ErrorMsg'
import { LocalStorage } from '@util/localStorage'

const schema = z
  .object({
    password: z
      .string()
      .min(8, '8文字以上で設定してください')
      .max(20, '20文字以下で設定してください')
      .nonempty('必須です')
      .regex(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,20}$/, { message: '半角英数字を混ぜてください' }),
    passwordConfirm: z.string().max(20).nonempty(),
  })
  .refine(
    (data) => {
      return data.password === data.passwordConfirm
    },
    {
      message: '異なるパスワードが入力されています',
      path: ['passwordConfirm'],
    },
  )
type formData = z.infer<typeof schema>
const ResetPassword = () => {
  const { code } = useParams()
  const [reset, { loading }] = useResetPasswordMutation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<formData>({
    resolver: zodResolver(schema),
  })
  if (!code) {
    return null
  }

  const onSubmit = (form: formData) => {
    reset({ variables: { ...form, code } })
      .then(({ data }) => {
        const jwt = data?.resetPassword?.jwt
        if (jwt) {
          LocalStorage.setLogin(jwt)
          toast.success('パスワードを変更しました。', {
            onOpen: () => {
              navigate('/')
            },
          })
        }
      })
      .catch((err) => {
        toast.error('パスワード変更に失敗しました。内容をご確認ください。')
      })
  }
  return (
    <div className="p-6">
      <div>
        <div className="my-4 text-lg font-bold">新しいパスワードを入力してください。</div>
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
          <div className="form-control">
            <label className="label">
              <span className="label-text">パスワード</span>
            </label>
            <input type="password" {...register('password')} className="input input-bordered" maxLength={20} />
            <ErrorMsg error={errors.password} />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">パスワード（確認）</span>
            </label>
            <input type="password" {...register('passwordConfirm')} className="input input-bordered" maxLength={20} />
            <ErrorMsg error={errors.passwordConfirm} />
          </div>

          <div className="form-control py-4 mt-10">
            <input type="submit" value="パスワードリセット" className={`btn btn-primary ${loading && 'loading'}`} />
          </div>
        </form>
      </div>

      <Link to="/" className="link">
        トップに戻る
      </Link>
    </div>
  )
}
export { ResetPassword }
