import { useMemo } from 'react'

import { ReserveStatus, useMeQuery } from '@generated/graphql'

import dayjs from 'dayjs'
import { SubscriptionView } from './subscriptions'
import { VisitView } from './visits'

const MypageView = () => {
  const { data: { _me: me } = {} } = useMeQuery({ fetchPolicy: 'network-only' })

  const visits = useMemo(() => {
    const targets = me?.reserves?.filter((r) => r.status !== ReserveStatus.Canceled && r.visits)

    if (!targets || targets.length === 0) {
      return []
    }
    const now = dayjs()
    const newArr = targets.map((i) => {
      if (!i.visits) {
        return []
      }
      return i.visits
        .flat()
        .filter((v) => dayjs(v.start_at).isAfter(now) && !v.is_canceled)
        .map((j) => {
          return { plan_name: i.plan.name, with_visit: i.plan.with_visit, reserve_id: i.id, ...j }
        })
    })

    return newArr.flat()
  }, [me])

  return (
    <div className="container mx-auto px-2">
      <div>
        {visits && (
          <div className="p-4">
            <VisitView title="利用予約" />
          </div>
        )}
        <div className="p-4">
          <SubscriptionView title="契約中の定期利用" />
        </div>
      </div>
    </div>
  )
}
export { MypageView }
