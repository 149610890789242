import { LoadingHander } from '@component/LoadingHandle'
import { useMeQuery, useOnetimeLoginMutation } from '@generated/graphql'
import { LocalStorage } from '@util/localStorage'
import { SessionStorage } from '@util/sessionStorage'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { StringParam, useQueryParams } from 'use-query-params'

const OnetimeLogin = () => {
  const [{ onetime_token, site_token, path }] = useQueryParams({
    onetime_token: StringParam,
    site_token: StringParam,
    path: StringParam,
  })

  if (!onetime_token || !site_token) {
    return <div className="container mx-auto my-4">起動パラメータが不足しています。</div>
  }

  return <Mutation onetime_token={onetime_token} site_token={site_token} path={path ?? undefined} />
}
const Mutation = ({
  onetime_token,
  site_token,
  path,
}: {
  onetime_token: string
  site_token: string
  path?: string
}) => {
  const [login, { loading }] = useOnetimeLoginMutation()
  const { refetch } = useMeQuery()
  const navigate = useNavigate()
  const [msg, setMsg] = useState('')
  useEffect(() => {
    login({
      variables: {
        input: {
          onetime_token,
          site_token,
        },
      },
    })
      .then(({ data }) => {
        const l = data?._onetimeLogin
        if (l) {
          LocalStorage.setLogin(l.jwt)
          toast.success(`${l.site.name}からログインしました`, {
            onOpen: async () => {
              await refetch()
              SessionStorage.setOnetimeLogin(l.site.id)
              navigate(path ?? '/', { replace: true })
            },
          })
        }
      })
      .catch((error) => {
        console.log(error)
        setMsg('起動に失敗しました')
        toast.error(`${error}`)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <LoadingHander />
  }
  return <div className="container mx-auto my-4">{msg}</div>
}
export { OnetimeLogin }
