import { useLoginMutation } from '@generated/graphql'
import { LocalStorage } from '@util/localStorage'

import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { ErrorMsg } from './ErrorMsg'

import { toast } from 'react-toastify'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { atom, useRecoilState, useSetRecoilState } from 'recoil'

const schema = z.object({
  email: z.string().nonempty({ message: '必須です' }),
  password: z.string().nonempty({ message: '必須です' }),
})

const recoilLogin = atom<{ open: boolean; onLogin?: () => void }>({ key: 'recoilLogin', default: { open: false } })

const useOpenLogin = (onLogin?: () => void) => {
  const setRecoil = useSetRecoilState(recoilLogin)
  return () => {
    setRecoil({
      open: true,
      onLogin,
    })
  }
}
// type FieldInput = TypeOf<typeof schema>
type FieldInput = z.infer<typeof schema>
const LoginForm = () => {
  const [recoil, setRecoil] = useRecoilState(recoilLogin)

  const [loginMutation, { loading }] = useLoginMutation()

  const closeMe = () => {
    setRecoil((item) => {
      return { ...item, open: false }
    })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldInput>({
    resolver: zodResolver(schema),
  })

  const onSubmit = handleSubmit(async (form) => {
    try {
      const { data } = await loginMutation({ variables: form })

      if (data?.login.jwt) {
        LocalStorage.setLogin(data.login.jwt)
        LocalStorage.setMessage('ログインしました')
        if (recoil.onLogin) {
          recoil.onLogin()
        } else {
          window.location.href = ''
        }
      } else {
        // toast.error(`ログインに失敗しました`)
      }
    } catch (errors) {
      LocalStorage.setLogOut()
      toast.error(`ログインに失敗しました`)
    }
  })

  return (
    <>
      <input type="checkbox" checked={recoil.open} readOnly className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle" onClick={closeMe}>
        <div className="modal-box p-3" onClick={(e) => e.stopPropagation()}>
          <div className="p-4 rounded-lg bg-yellow-100">
            <div className="text-xl my-2">ログイン</div>
            <form onSubmit={onSubmit}>
              <div className="form-control">
                <label className="label">
                  <span className="label-text">email</span>
                </label>
                <input {...register('email')} className="input input-bordered" />
                <ErrorMsg error={errors.email} />
              </div>

              <div className="form-control">
                <label className="label">
                  <span className="label-text">password</span>
                </label>
                <input {...register('password')} type="password" className="input input-bordered" />
                <ErrorMsg error={errors.password} />
              </div>
              <input
                type="submit"
                className={`btn btn-primary my-10 w-full ${loading && 'loading'}`}
                value="ログイン"
              />
            </form>
            <div className="my-6">
              <Link to="/password/forgot" className="link" onClick={closeMe}>
                パスワードをお忘れの方はこちら
              </Link>
            </div>
            <div className="my-6">
              <Link to="/regist" className="link" onClick={closeMe}>
                新規会員登録
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export { LoginForm, useOpenLogin }
