import { useAddSubscriptionMutation, useMeQuery } from '@generated/graphql'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { useOpenLogin } from '@component/LoginForm'

type formData = { access_code: string }
const AddSubscription = () => {
  const { id } = useParams()
  const [mutation, { loading }] = useAddSubscriptionMutation()
  const { data: { _me: me } = {}, loading: loadMe } = useMeQuery()
  const openLogin = useOpenLogin()
  const { register, handleSubmit, setValue } = useForm<formData>({
    defaultValues: { access_code: '' },
  })

  const onSubmit = async (form: formData) => {
    try {
      const { data } = await mutation({ variables: { input: { access_code: form.access_code } } })
      if (data?._addSubscription) {
        toast.success('年間利用を追加しました')
      } else {
        toast.error('登録が確認できませんでした。')
      }
    } catch (errors) {
      if (errors instanceof ApolloError) {
        toast.error(errors.message)
      } else {
        toast.error('登録に失敗しました')
      }
    }
  }

  useEffect(() => {
    if (id) {
      setValue('access_code', id)
    }
  }, [id, setValue])

  useEffect(() => {
    if (!me) {
      if (loadMe) {
        return
      }

      openLogin()
    }
  }, [me, openLogin, loadMe])

  return (
    <div className="p-6">
      <div>
        <div className="my-4 text-lg font-bold">届いたメールに記載されているアクセスコードを入力してください。</div>
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
          <div className="form-control">
            <label className="label">
              <span className="label-text">アクセスコード</span>
            </label>
            <input {...register('access_code')} className="input input-bordered" maxLength={200} />
          </div>

          <div className="form-control py-4 mt-10">
            <input
              type="submit"
              value="登録"
              className={`btn btn-primary ${loading && 'loading'} ${!me && 'btn-disabled'}`}
            />
          </div>
        </form>
      </div>

      <Link to="/" className="link">
        トップに戻る
      </Link>
    </div>
  )
}
export { AddSubscription }
