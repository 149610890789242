import { useMailConfirmMutation } from '@generated/graphql'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { LocalStorage } from '@util/localStorage'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { ENV } from '@util/env'
import { LoadingHander } from '@component/LoadingHandle'

type formData = { confirmId: string }
const MailConfirm = () => {
  const { id } = useParams()
  const [mut, { loading }] = useMailConfirmMutation()
  const navigate = useNavigate()
  const { register, handleSubmit, setValue } = useForm<formData>({
    defaultValues: { confirmId: '' },
  })
  const [csrf, setCsrf] = useState('')

  useEffect(() => {
    if (id) {
      setValue('confirmId', id)
    }
    axios.post<string>(`${ENV.API_HOST_URL}/api/crons/session`).then(({ data }) => {
      setCsrf(data)
    })
  }, [id, setValue])

  if (!csrf) {
    return <LoadingHander />
  }

  const onSubmit = (form: formData) => {
    mut({ variables: { confirmation: `${form.confirmId}***${csrf}` } })
      .then(({ data }) => {
        const jwt = data?.emailConfirmation?.jwt
        if (jwt) {
          LocalStorage.setLogin(jwt)
          toast.success('会員登録が完了しました。', {
            onOpen: () => {
              navigate('/')
            },
          })
        }
      })
      .catch((err) => {
        toast.error('登録が確認できませんでした。')
      })
  }

  return (
    <div className="p-6">
      <div>
        <div className="my-4 text-lg font-bold">届いたメールにあるリンクの確認コードを入力してください。</div>
        <form onSubmit={handleSubmit(onSubmit)} className="p-2">
          <div className="form-control">
            <label className="label">
              <span className="label-text">確認コード</span>
            </label>
            <input {...register('confirmId')} className="input input-bordered" maxLength={200} />
          </div>

          <div className="form-control py-4 mt-10">
            <input type="submit" value="登録確認" className={`btn btn-primary ${loading && 'loading'}`} />
          </div>
        </form>
      </div>

      <Link to="/" className="link">
        トップに戻る
      </Link>
    </div>
  )
}
export { MailConfirm }
