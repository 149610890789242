import { useEffect, useMemo, useRef } from 'react'

const LoadingHander = () => {
  const resolve = useRef<() => void>()
  const promise = useMemo(
    () =>
      new Promise<void>((res) => {
        resolve.current = res
      }),
    [],
  )

  useEffect(() => {
    return () => {
      resolve.current?.()
    }
  })

  throw promise
}
export { LoadingHander }
