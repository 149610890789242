import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ReserveStatus, useMeQuery } from '@generated/graphql'

import { formatHM, formatYMD } from '@util/format'
import dayjs from 'dayjs'
import { ReserveCancelButton, VisitCancelButton } from './buttons'

const VisitView: FC<{ title?: string; forReserve?: boolean }> = ({ title, forReserve }) => {
  const { data: { _me: me } = {}, refetch } = useMeQuery()

  const visits = useMemo(() => {
    const targets = me?.reserves?.filter((r) => r.status !== ReserveStatus.Canceled && r.visits)

    if (!targets || targets.length === 0) {
      return []
    }
    const now = dayjs()
    const newArr = targets.map((i) => {
      if (!i.visits) {
        return []
      }
      return i.visits
        .flat()
        .filter((v) => dayjs(v.start_at).isAfter(now.startOf('day')) && !v.is_canceled)
        .map((j) => {
          return {
            plan_name: i.plan.name,
            with_visit: i.plan.with_visit,
            reserve_id: i.id,
            is_need_parent: i.plan.is_need_parent_visit,
            ...j,
          }
        })
    })

    return newArr
      .flat()
      .sort((a, b) => (dayjs(a.start_at).isBefore(b.start_at) ? -1 : 1))
      .filter((i) => {
        if (forReserve) {
          // 予約画面表示（会議室などの予約用)の場合は、ベースにならない利用予約を表示しない
          return !i.is_need_parent
        } else {
          return true
        }
      })
  }, [me, forReserve])

  return (
    <>
      {visits && (
        <div>
          {title && <div className="font-bold text-lg py-2">{title}</div>}
          <div className="w-full overflow-scroll max-h-[60vh] border-2">
            <table className="table table-compact w-full">
              <thead className="sticky top-0 z-20">
                <tr>
                  <td>来店内容</td>
                  <td>{!forReserve && '※キャンセルは前日まで'}</td>
                </tr>
              </thead>
              {visits.length > 0 && (
                <tbody>
                  {visits.map((visit) => {
                    const start = dayjs(visit.start_at)
                    const end = dayjs(visit.end_at)
                    return (
                      <tr key={visit.id}>
                        <td>
                          {formatYMD(visit.start_at)} {formatHM(visit.start_at)}〜{formatHM(visit.end_at)}
                          <br />
                          <span className="text-sm text-gray-400">{visit.plan_name}</span>
                        </td>
                        <td>
                          {!forReserve && (
                            <div
                              className="btn btn-success btn-xs"
                              onClick={() => {
                                const tmp = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//hacksw/handcal//NONSGML v1.0//EN
BEGIN:VEVENT
DTSTART:${start.add(-1 * start.utcOffset(), 'minute').format('YYYYMMDDTHHmmss')}Z
DTEND:${end.add(-1 * end.utcOffset(), 'minute').format('YYYYMMDDTHHmmss')}Z
SUMMARY:${visit.plan_name}
END:VEVENT
END:VCALENDAR`
                                const b = new Blob(
                                  [
                                    // `https://www.google.com/calendar/render?action=TEMPLATE&text=${
                                    //   reserve.plan_name
                                    // }&dates=${start.format('YYYYMMDDTHHmmss')}/${end.format('YYYYMMDDTHHmmss')}`,
                                    tmp,
                                  ],
                                  { type: 'text/calendar' },
                                )

                                const link = document.createElement('a')
                                link.download = 'shareoffice.ics' // ダウンロードファイル名称
                                link.href = URL.createObjectURL(b) // オブジェクト URL を生成
                                link.click() // クリックイベントを発生させる
                                URL.revokeObjectURL(link.href) //
                              }}
                            >
                              カレンダーに追加
                            </div>
                          )}
                          {start.isAfter(dayjs().endOf('day')) && (
                            <>
                              {!forReserve ? (
                                <>
                                  {!visit.with_visit ? (
                                    <VisitCancelButton visit_id={visit.id} onFinish={refetch} />
                                  ) : (
                                    <ReserveCancelButton reserve_id={visit.reserve_id} onFinish={refetch} />
                                  )}
                                </>
                              ) : (
                                // <Link to={`${visit.id}`} className="btn btn-primary btn-xs mx-2">
                                //   設備予約
                                // </Link>
                                <></>
                              )}
                            </>
                          )}
                          <>
                            {forReserve && (
                              <Link to={`${visit.id}`} className="btn btn-primary btn-xs mx-2">
                                設備予約
                              </Link>
                            )}
                          </>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              )}
              {visits.length === 0 && (
                <tbody>
                  <tr>
                    <td className="" colSpan={3}>
                      利用予約がありません
                    </td>
                  </tr>
                </tbody>
              )}
              <tfoot className="sticky bottom-0 z-20">
                <tr>
                  <th className="text-xs" colSpan={3}>
                    {visits.length}件
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  )
}
export { VisitView }
