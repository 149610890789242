import { UserDataForm, UserFormData } from './UserDataForm'
import { toast } from 'react-toastify'
import { useMeQuery, useUpdateMeMutation } from '@generated/graphql'

const UpdateUser = () => {
  const { data: { _me: me } = {} } = useMeQuery()
  if (!me) {
    return null
  }
  return <UpdateUserBody data={{ ...me, password: '', password_confirm: '', csrf: '' }} />
}

const UpdateUserBody = ({ data, user_id }: { data: UserFormData; user_id?: string }) => {
  const [updateMutation, { loading }] = useUpdateMeMutation()
  return (
    <div className="container mx-auto px-2">
      <UserDataForm
        me={{ ...data, password: '', password_confirm: '' }}
        loading={loading}
        onSubmit={async (form) => {
          const { password_confirm, password, email, ...sendData } = form
          try {
            await updateMutation({
              variables: { user: { ...sendData }, user_id },
            })
            toast.success('更新しました')
          } catch (e) {
            toast.error(`${e}`)
          }
        }}
      />
    </div>
  )
}

export { UpdateUser, UpdateUserBody }
