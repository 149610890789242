import { toast } from 'react-toastify'

const LocalStorage = {
  setLogin: (token: string) => {
    localStorage.setItem('kcs_token', token)
  },
  setMessage: (msg: string) => {
    localStorage.setItem('kcs_msg', msg)
  },
  setLogOut: () => {
    localStorage.removeItem('kcs_token')
    // window.location.href = '/'
  },
  getToken: () => {
    return localStorage.getItem('kcs_token')
  },
  showMessage: () => {
    const msg = localStorage.getItem('kcs_msg')
    if (msg) {
      toast.success(msg)
      localStorage.removeItem('kcs_msg')
    }
  },
  backto: {
    set: (url: string) => {
      sessionStorage.setItem('backto', url)
    },
    get: () => {
      return sessionStorage.getItem('backto')
    },
    remove: () => {
      sessionStorage.removeItem('backto')
    },
  },
}

export { LocalStorage }
