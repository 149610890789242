import {
  Enum_Reserve_Status,
  PaymentType,
  ReserveStatus,
  useAdminAllUsersLazyQuery,
  useAdminVisitCsvLazyQuery,
  useMeQuery,
  _Invoice,
  _VisitsCsv,
  useInvoicesQuery,
} from '@generated/graphql'
import { parse } from 'json2csv'
import { formatYMDHM, PaymentTypeLabel, ReserveStatusLabel } from '@util/format'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { UpdateReserveForm, useOpenUpdateForm } from './form/Reserve'
import { AdminWaitingSubscriptionView } from './waitingSubs'
import { useCookies } from 'react-cookie'

import { CreateReserveForm, useOpenCreateForm } from './form/CreateReserve'
import { toast } from 'react-toastify'
import { VisitBodyView } from './visit'

const expires = dayjs().add(2, 'year').toDate()
const pageAmount = 30
const AdminView = () => {
  const { data: { _me } = {} } = useMeQuery()
  const { data: { _invoices } = {}, refetch } = useInvoicesQuery({
    variables: { pagination: { offset: 0, limit: 10000 } },
  })
  // const [searchInvoice, { data, refetch }] = useInvoicesLazyQuery({ fetchPolicy: 'network-only' })
  // const [searchInvoiceAll] = useInvoicesLazyQuery({
  //   fetchPolicy: 'network-only',
  // })

  const [csvQuery] = useAdminVisitCsvLazyQuery({ fetchPolicy: 'network-only' })
  const [alluserQuery] = useAdminAllUsersLazyQuery({ fetchPolicy: 'network-only' })
  const [reserveId, setReserveId] = useState<string>()
  const openForm = useOpenUpdateForm()
  const openCreateForm = useOpenCreateForm()
  const [cookies, setCookie] = useCookies(['filters', 'sort'])
  const [filter, setFilter] = useState<{
    reserved: boolean
    charged: boolean
    canceled: boolean
    settled: boolean
    used: boolean
    text: string
  }>(
    cookies.filters || {
      reserved: true,
      charged: true,
      canceled: false,
      settled: false,
      used: false,
      text: '',
    },
  )
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState<{ name: keyof _Invoice; asc: boolean }>(
    cookies.sort ?? { name: 'created_at', asc: false },
  )
  const filters = useMemo(() => {
    setCookie('filters', { ...filter, text: '' }, { expires })
    const f: ReserveStatus[] = []

    if (filter.reserved) {
      f.push(ReserveStatus.Reserved)
    }
    if (filter.charged) {
      f.push(ReserveStatus.Charged)
    }
    if (filter.used) {
      f.push(ReserveStatus.Used)
    }
    if (filter.canceled) {
      f.push(ReserveStatus.Canceled)
    }
    if (filter.settled) {
      f.push(ReserveStatus.Settled)
    }
    return f
  }, [filter, setCookie])

  const sortedList = useMemo(() => {
    setCookie('sort', sort, { expires })
    return _invoices
      ?.filter((i) => filters.includes(i.status))
      .filter((i) => {
        for (const [name, value] of Object.entries(i)) {
          const text = name.endsWith('at') ? formatYMDHM(value) : value
          if (String(text).includes(filter.text)) {
            return true
          }
        }
        return false
      })
      .sort((a, b) => {
        if (sort.asc) {
          return a[sort.name] < b[sort.name] ? -1 : 1
        } else {
          return a[sort.name] < b[sort.name] ? 1 : -1
        }
      })

    // return data?._invoices
  }, [setCookie, sort, _invoices, filters, filter.text])

  // useEffect(() => {
  //   searchInvoice({
  //     variables: {
  //       input: { status: filters },
  //       pagination: {
  //         offset: pageAmount * page,
  //         limit: pageAmount,
  //         direction: sort.asc ? OrderDirection.Asc : OrderDirection.Desc,
  //         order: sort.name,
  //       },
  //     },
  //   })
  // }, [filters, page, searchInvoice, sort.asc, sort.name])

  const downloadAllUser = useCallback(async () => {
    const { data } = await alluserQuery()
    const users = data?.usersPermissionsUsers?.data.map((i) => {
      return { ...i.attributes, id: i.id }
    })
    if (users) {
      type U = typeof users[number]
      const csv = parse(users, {
        excelStrings: true,
        fields: [
          {
            label: 'ID',
            value: 'id',
            default: '',
          },
          {
            label: '氏名',
            value: (row: U) => row.sei + ' ' + row.mei,
            default: '',
          },
          {
            label: 'メールアドレス',
            value: (row: U) => row.email,
            default: '',
          },
          {
            label: '郵便番号',
            value: (row: U) => row.zipcode,
            default: '',
          },
          {
            label: '住所',
            value: (row: U) => row.address,
            default: '',
          },
          {
            label: '電話番号',
            value: (row: U) => row.tel,
            default: '',
          },
          {
            label: '登録日',
            value: (row: U) => row.createdAt,
            default: '',
          },
          {
            label: '会社名',
            value: (row: U) => row.companyName,
            default: '',
          },
        ],
      })

      const element = document.createElement('a')
      const file = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = `${dayjs().format('YYYYMMDDHHmmss')}-userlist.csv`
      element.click()
      URL.revokeObjectURL(element.href)
    }
  }, [alluserQuery])

  const downloadCsv = useCallback(async () => {
    if (sortedList) {
      const csv = parse(sortedList, {
        excelStrings: true,
        fields: [
          {
            label: '予約ID',
            value: 'id',
            default: '',
          },
          {
            label: '氏名',
            value: (row: _Invoice) => row.sei + ' ' + row.mei,
            default: '',
          },
          {
            label: '会社名',
            value: (row: _Invoice) => row.company_name,
            default: '',
          },
          {
            label: '請求書宛名',
            value: (row: _Invoice) => row.invoice_address,
            default: '',
          },
          {
            label: '郵便番号',
            value: (row: _Invoice) => row.zipcode,
            default: '',
          },
          {
            label: '住所',
            value: (row: _Invoice) => row.address,
            default: '',
          },
          {
            label: '電話番号',
            value: (row: _Invoice) => row.tel,
            default: '',
          },
          {
            label: 'メールアドレス',
            value: (row: _Invoice) => row.email,
            default: '',
          },
          {
            label: '購入日',
            value: (row: _Invoice) => formatYMDHM(row.created_at),
            default: '',
          },
          {
            label: 'プラン名',
            value: (row: _Invoice) => row.plan_name,
            default: '',
          },
          {
            label: 'アクセスコード',
            value: (row: _Invoice) => row.access_code,
            default: '',
          },

          {
            label: '開始',
            value: (row: _Invoice) => formatYMDHM(row.start_at),
            default: '',
          },
          {
            label: '終了',
            value: (row: _Invoice) => formatYMDHM(row.end_at),
            default: '',
          },
          {
            label: '請求金額',
            value: (row: _Invoice) => row.price,
            default: '',
          },
          {
            label: '決済方法',
            value: (row: _Invoice) => PaymentTypeLabel[row.payment_type],
            default: '',
          },
          {
            label: 'クーポン名',
            value: (row: _Invoice) => row.coupon_name,
            default: '',
          },
          {
            label: 'ステータス',
            value: (row: _Invoice) => ReserveStatusLabel[row.status],
            default: '',
          },
          {
            label: 'メモ',
            value: (row: _Invoice) => row.memo?.replace('\n', '\\n'),
            default: '',
          },
        ],
      })

      const element = document.createElement('a')
      const file = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], { type: 'text/plain' })
      element.href = URL.createObjectURL(file)
      element.download = `${dayjs().format('YYYYMMDDHHmmss')}-list.csv`
      element.click()
      URL.revokeObjectURL(element.href)
    }
  }, [sortedList])

  const downloadVisits = useCallback(async () => {
    const { data: { _adminVisitsCsv } = {} } = await csvQuery()
    if (!_adminVisitsCsv) {
      toast.error('csvの作成に失敗しました')
      return
    }
    const csv = parse(_adminVisitsCsv, {
      excelStrings: true,
      fields: [
        {
          label: '利用予約ID',
          value: 'id',
          default: '',
        },
        {
          label: '開始',
          value: (row: _VisitsCsv) => formatYMDHM(row.start_at),
          default: '',
        },
        {
          label: '終了',
          value: (row: _VisitsCsv) => formatYMDHM(row.end_at),
          default: '',
        },
        {
          label: '利用者氏名',
          value: (row: _VisitsCsv) => row.user_name,
          default: '',
        },
        {
          label: 'ステータス',
          value: (row: _VisitsCsv) => {
            if (row.is_canceled) {
              return 'キャンセル'
            }
            if (row.is_forstop) {
              return '予約停止用'
            }
            return ''
          },
          default: '',
        },
        {
          label: '予約ID',
          value: (row: _VisitsCsv) => row.reserve_id,
          default: '',
        },
        {
          label: 'プラン名',
          value: (row: _VisitsCsv) => row.plan_name,
          default: '',
        },
        {
          label: '請求書宛名',
          value: (row: _VisitsCsv) => row.invoice_address,
          default: '',
        },
        {
          label: '購入者氏名',
          value: (row: _VisitsCsv) => row.buyer_name,
          default: '',
        },
        {
          label: '購入者会社名',
          value: (row: _VisitsCsv) => row.company_name,
          default: '',
        },
        {
          label: 'クーポン名',
          value: (row: _VisitsCsv) => row.coupon_name,
          default: '',
        },
      ],
    })

    const element = document.createElement('a')
    const file = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csv], { type: 'text/plain' })
    element.href = URL.createObjectURL(file)
    element.download = `${dayjs().format('YYYYMMDDHHmmss')}-visitlist.csv`
    element.click()
    URL.revokeObjectURL(element.href)
  }, [csvQuery])

  const SortHeader = useCallback(
    ({ label, name }: { label: string; name: keyof _Invoice }) => {
      return (
        <td className={`${sort.name === name && 'italic'}`}>
          <span
            onClick={() =>
              setSort((item) => {
                return { ...item, name }
              })
            }
            className="link"
          >
            {label}
          </span>{' '}
          {sort.name === name && (
            <span
              onClick={() =>
                setSort((item) => {
                  return { ...item, asc: !item.asc }
                })
              }
              className="link"
            >
              {sort.asc ? '↓' : '↑'}
            </span>
          )}
        </td>
      )
    },
    [sort],
  )

  useEffect(() => {
    if (page * pageAmount > (sortedList?.length ?? 0)) {
      setPage(0)
    }
  }, [page, sortedList?.length])

  if (!_me) {
    return null
  }

  return (
    <div className="container mx-auto">
      {/* <AdminBuyView /> */}
      <div className="p-4 mb-4">
        <div className="font-bold">定期利用承認待ち一覧</div>
        <AdminWaitingSubscriptionView />
      </div>

      <div className="p-4">
        <div className="font-bold my-2">顧客一覧</div>
        <div className="btn btn-primary" onClick={downloadAllUser}>
          顧客一覧ダウンロード
        </div>
      </div>

      <div className="p-4">
        <div className="items-center my-4">
          <div className="font-bold flex-1">購入一覧</div>
          <div className="flex flex-col items-start lg:flex-row lg:items-center m-4 gap-2">
            <div className="form-control">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filter.reserved}
                  readOnly
                  className="checkbox"
                  onChange={(e) => {
                    setFilter((item) => {
                      return { ...item, reserved: e.target.checked }
                    })
                  }}
                />
                <div className="mx-2">予約</div>
              </label>
            </div>
            <div className="form-control">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filter.used}
                  readOnly
                  className="checkbox"
                  onChange={(e) => {
                    setFilter((item) => {
                      return { ...item, used: e.target.checked }
                    })
                  }}
                />
                <div className="mx-2">来店済み</div>
              </label>
            </div>
            <div className="form-control">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filter.charged}
                  readOnly
                  className="checkbox"
                  onChange={(e) => {
                    setFilter((item) => {
                      return { ...item, charged: e.target.checked }
                    })
                  }}
                />
                <div className="mx-2">請求済み</div>
              </label>
            </div>
            <div className="form-control">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filter.settled}
                  readOnly
                  className="checkbox"
                  onChange={(e) => {
                    setFilter((item) => {
                      return { ...item, settled: e.target.checked }
                    })
                  }}
                />
                <div className="mx-2">決済済み</div>
              </label>
            </div>
            <div className="form-control ">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filter.canceled}
                  readOnly
                  className="checkbox"
                  onChange={(e) => {
                    setFilter((item) => {
                      return { ...item, canceled: e.target.checked }
                    })
                  }}
                />
                <div className="mx-2">キャンセル</div>
              </label>
            </div>
            <div className="form-control">
              <label className="flex items-center">
                <input
                  type="text"
                  className="input input-sm input-"
                  placeholder="フリーテキストフィルタ"
                  onChange={(e) => {
                    setFilter((item) => {
                      return { ...item, text: e.target.value }
                    })
                  }}
                />
              </label>
            </div>

            <div className="form-control">
              <div className="btn btn-xs btn-primary" onClick={downloadCsv}>
                購入一覧csvダウンロード
              </div>
            </div>
            <div className="form-control">
              <div className="btn btn-xs btn-primary" onClick={downloadVisits}>
                定期利用履歴 csvダウンロード
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4 py-2 justify-between">
          <div
            className="btn btn-primary my-4 btn-sm"
            onClick={() => {
              openCreateForm(async () => {
                await refetch()
              })
            }}
          >
            予約追加
          </div>

          <div className="flex items-center">
            {sortedList ? (
              <div className="flex items-center gap-4">
                <div>{sortedList.length.toLocaleString()} 件</div>
                <div className="btn-group">
                  {[...Array(Math.floor(sortedList.length / pageAmount) + 1)]
                    .map((_, i) => i)
                    .map((p) => {
                      return (
                        <button
                          key={p}
                          onClick={() => setPage(p)}
                          className={`btn btn-sm ${p === page ? 'btn-active' : ''}`}
                        >
                          {p + 1}
                        </button>
                      )
                    })}
                </div>
              </div>
            ) : (
              <div>0 件</div>
            )}
          </div>
        </div>
        <table className="table w-full table-compact">
          <thead>
            <tr>
              <td>ID</td>
              <SortHeader label="購入日" name="created_at" />
              {/* <td onClick={() => setSort('created_at')} className={`link ${sort === 'created_at' && 'italic'}`}>
                購入日 <SortOrder name="購入日"></SortOrder>
              </td> */}
              <td>氏名</td>
              <td>プラン名</td>
              <SortHeader label="開始" name="start_at" />
              <SortHeader label="終了" name="end_at" />
              {/* <td>金額</td> */}
              <td>決済方法 金額</td>
              <td>ステータス</td>
              {/* <td></td> */}
              <td>メモ</td>
            </tr>
          </thead>
          <tbody className="clearbg">
            {sortedList?.slice(page * pageAmount, (page + 1) * pageAmount).map((invoice) => {
              return (
                <tr
                  className={`border-2 py-2 text-xs bg-white ${
                    invoice.status === ReserveStatus.Canceled && 'bg-gray-100'
                  } ${invoice.status === ReserveStatus.Settled && 'bg-green-100'}`}
                  key={invoice.id}
                >
                  <td>{invoice.id}</td>
                  <td>{formatYMDHM(invoice.created_at)}</td>
                  <td>
                    {invoice.sei} {invoice.mei}{' '}
                    <div className="dropdown dropdown-hover dropdown-right">
                      <label tabIndex={0} className="badge badge-xs">
                        info
                      </label>
                      <div className="dropdown-content p-4 drop-shadow-xl rounded-md bg-white border-2 gap-2 flex flex-col">
                        {invoice.company_name && <div>会社名: {invoice.company_name}</div>}
                        <div>
                          {invoice.zipcode} {invoice.address}
                        </div>
                        <div>
                          📩{' '}
                          <a className="link" href={`mailto:${invoice.email}`} target={'_blank'} rel="noreferrer">
                            {invoice.email}
                          </a>
                        </div>
                        <div>☎ {invoice.tel}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {!invoice.with_visit ? (
                      <span
                        className="link"
                        onClick={() => {
                          setReserveId(invoice.id)
                        }}
                      >
                        {invoice.plan_name}
                      </span>
                    ) : (
                      <>{invoice.plan_name}</>
                    )}

                    <br />
                    {invoice.access_code}
                  </td>

                  <td className={`${dayjs(invoice.start_at).isBefore(dayjs()) && 'font-bold'}`}>
                    {formatYMDHM(invoice.start_at)}
                  </td>
                  <td className={`${dayjs(invoice.end_at).isBefore(dayjs()) && 'font-bold'}`}>
                    {formatYMDHM(invoice.end_at)}
                  </td>
                  {/* <td className="text-right">{invoice.price.toLocaleString()}</td> */}
                  <td>
                    {PaymentTypeLabel[invoice.payment_type]}{' '}
                    {invoice.payment_type === PaymentType.Invoice && (
                      <div className="dropdown dropdown-hover dropdown-right">
                        <label tabIndex={0} className="badge badge-xs">
                          宛名
                        </label>
                        <div className="dropdown-content p-2 drop-shadow-xl rounded-md bg-white border-2 gap-2 flex flex-col">
                          <div>{invoice.invoice_address || invoice.sei + ' ' + invoice.mei}</div>
                        </div>
                      </div>
                    )}
                    {invoice.payment_type === PaymentType.Coupon && <div>{invoice.coupon_name}</div>}
                    <div>{invoice.price.toLocaleString()}</div>
                  </td>
                  <td
                    className="link"
                    onClick={() => {
                      openForm({
                        reserve_id: invoice.id,
                        is_used: invoice.is_used,
                        status: invoice.status as unknown as Enum_Reserve_Status,
                        payment_type: invoice.payment_type,
                        start_at: dayjs(invoice.start_at).toDate(),
                        end_at: dayjs(invoice.end_at).toDate(),
                        price: invoice.price,
                        memo: invoice.memo ?? undefined,
                        visit_id: invoice.visit_id ?? undefined,
                        onClose: async () => {
                          await refetch()
                        },
                      })
                    }}
                  >
                    {ReserveStatusLabel[invoice.status]}
                    {invoice.is_forstop && ' [予約停止用]'}
                  </td>
                  {/* <td>
                    {!invoice.with_visit && (
                      // <Link className="link" to={`/admin/visit/${invoice.id}`}>
                      //   利用詳細
                      // </Link>
                      <div
                        className="link"
                        onClick={() => {
                          setVisitId(invoice.id)
                        }}
                      >
                        利用詳細
                      </div>
                    )}
                  </td> */}
                  <td>
                    {invoice.memo && (
                      <div className="dropdown dropdown-end dropdown-hover">
                        <label tabIndex={0} className="badge badge-xs">
                          ○
                        </label>
                        <div className="dropdown-content p-2 rounded-md bg-white border-2">{invoice.memo}</div>
                      </div>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {reserveId && (
        <Drawer>
          <VisitBodyView
            id={reserveId}
            onClose={() => {
              setReserveId(undefined)
            }}
          />
        </Drawer>
      )}
      <UpdateReserveForm />
      <CreateReserveForm user_id={_me.id} />
    </div>
  )
}
const Drawer = ({ children }: { children: React.ReactChild }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [setLoaded])
  return (
    <div
      className={`fixed overflow-auto bg-white drop-shadow-2xl h-full top-0 right-0 p-4 transition-transform translate-x-full duration-300 ease-in-out ${
        loaded && 'translate-x-0'
      }`}
    >
      {children}
    </div>
  )
}
export { AdminView }
