import { FC } from 'react'
import { FieldError } from 'react-hook-form'

const ErrorMsg: FC<{ error?: FieldError }> = ({ error }) => {
  if (!error) {
    return null
  }

  return <span className="p-2 text-red-500 label-text">{error.message}</span>
}
export { ErrorMsg }
