import { useServiceQuery } from '@generated/graphql'
import { ENV } from '@util/env'
import { useMemo } from 'react'

const SVGServiceName = () => {
  const { data: { service } = {} } = useServiceQuery()

  const imgPath = useMemo(() => {
    return service?.data?.attributes?.images?.data[0].attributes?.url
  }, [service])
  return (
    <div className="w-full h-full">
      <div className="h-full p-2">
        {imgPath && <img src={`${ENV.IMAGE_HOST_URL}${imgPath}`} alt="" className="h-full" />}
      </div>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="w-48"
        viewBox="0 0 21437 2804"
      >
        <title>上士幌カーシェア</title>
        <g fill="white" transform="matrix(1,0,0,1,0,2112)">
          <path
            transform="matrix(1,0,0,1,-128,0)"
            d="M 130 -64 L 143 -192 L 919 -192 L 1042 -1643 L 1584 -1643 L 1547 -1216 L 2344 -1216 L 2331 -1088 L 1534 -1088 L 1461 -192 L 2396 -192 L 2383 -64 L 1448 -64 L 906 -64 L 130 -64 Z"
          />
          <path
            transform="matrix(1,0,0,1,2587,0)"
            d="M 180 -1152 L 193 -1280 L 1068 -1280 L 1099 -1624 L 1683 -1624 L 1651 -1280 L 2532 -1280 L 2519 -1152 L 1638 -1152 L 1557 -192 L 2240 -192 L 2227 -64 L 1544 -64 L 961 -64 L 273 -64 L 286 -192 L 974 -192 L 1055 -1152 L 180 -1152 Z"
          />
          <path
            transform="matrix(1,0,0,1,5302,0)"
            d="M 1089 -512 L 1102 -640 L 1206 -640 L 1136 -872 L 1448 -872 L 1518 -640 L 1581 -640 L 1604 -960 L 1565 -960 L 1185 -960 L 1149 -960 L 1208 -1635 L 1248 -1635 L 1625 -1635 L 2110 -1635 L 2492 -1635 L 2526 -1635 L 2466 -960 L 2430 -960 L 2050 -960 L 2021 -960 L 1998 -640 L 2063 -640 L 2177 -872 L 2490 -872 L 2375 -640 L 2477 -640 L 2464 -512 L 2214 -512 L 2188 -192 L 2438 -192 L 2425 -64 L 2172 -64 L 1972 -64 L 1776 -64 L 1818 -512 L 1724 -512 L 1477 -64 L 1060 -64 L 1307 -512 L 1089 -512 M 333 -64 L 440 -1280 L 401 -1280 L 323 -392 L 115 -392 L 206 -1408 L 414 -1408 L 453 -1408 L 471 -1604 L 846 -1604 L 828 -1408 L 873 -1408 L 1070 -1408 L 1102 -1408 L 1011 -392 L 781 -392 L 859 -1280 L 815 -1280 L 708 -64 L 333 -64 M 1602 -1344 L 2086 -1344 L 2097 -1450 L 1612 -1450 L 1602 -1344 M 1578 -1088 L 2063 -1088 L 2073 -1216 L 1589 -1216 L 1578 -1088 Z"
          />
          <path
            transform="matrix(1,0,0,1,8017,0)"
            d="M 284 -1280 L 297 -1408 L 693 -1408 L 758 -1596 L 1341 -1596 L 1276 -1408 L 2472 -1408 L 2339 -61 L 1758 -61 L 1873 -1280 L 1222 -1280 L 797 -61 L 216 -61 L 638 -1280 L 284 -1280 Z"
          />
          <path transform="matrix(1,0,0,1,10732,0)" d="M 193 -635 L 206 -792 L 2446 -792 L 2433 -635 L 193 -635 Z" />
          <path
            transform="matrix(1,0,0,1,13446,0)"
            d="M 276 -1472 L 289 -1600 L 1102 -1600 L 1089 -1472 L 276 -1472 M 1094 -52 L 1883 -1459 L 2550 -1459 L 1761 -52 L 1094 -52 M 224 -896 L 237 -1024 L 1050 -1024 L 1037 -896 L 224 -896 Z"
          />
          <path
            transform="matrix(1,0,0,1,16161,0)"
            d="M 393 -1152 L 406 -1280 L 2334 -1280 L 2321 -1152 L 1643 -1152 L 1560 -192 L 2344 -192 L 2331 -64 L 177 -64 L 190 -192 L 979 -192 L 1060 -1152 L 393 -1152 Z"
          />
          <path
            transform="matrix(1,0,0,1,18876,0)"
            d="M 1761 -688 L 1912 -1472 L 240 -1472 L 253 -1600 L 2524 -1600 L 2344 -688 L 1761 -688 M 646 -52 L 1003 -1227 L 1586 -1227 L 1229 -52 L 646 -52 Z"
          />
        </g>
      </svg> */}
      {/* <svg className="w-full" viewBox="0 0 572 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.34375 29.0625L0.578125 26.25H14.5469L16.75 0.9375H26.5L25.8438 8.625H40.1875L39.9531 11.4375H25.6094L24.2969 26.25H41.125L40.8906 29.0625H24.0625H14.3125H0.34375Z"
          fill="white"
        />
        <path
          d="M49.2344 9.9375L49.4688 7.125H65.2188L65.7812 0.9375H76.2812L75.7188 7.125H91.5625L91.3281 9.9375H75.4844L74.0312 26.25H86.3125L86.0781 29.0625H73.7969H63.2969H50.9219L51.1562 26.25H63.5312L64.9844 9.9375H49.2344Z"
          fill="white"
        />
        <path
          d="M113.594 21.0469L113.828 18.2344H115.703L114.438 14.0625H120.062L121.328 18.2344H122.453L122.875 13.3125H122.172H115.328H114.672L115.75 1.17188H116.453H123.25H131.969H138.859H139.469L138.391 13.3125H137.734H130.891H130.375L129.953 18.2344H131.125L133.188 14.0625H138.812L136.75 18.2344H138.578L138.344 21.0469H133.844L133.375 26.25H137.875L137.641 29.0625H133.094H129.484H125.969L126.719 21.0469H125.031L120.578 29.0625H113.078L117.531 21.0469H113.594ZM100 29.0625L101.922 7.26562H101.219L99.8125 23.25H96.0625L97.7031 4.45312H101.453H102.156L102.484 0.9375H109.234L108.906 4.45312H109.703H113.266H113.828L112.188 23.25H108.062L109.469 7.26562H108.672L106.75 29.0625H100ZM122.828 5.90625H131.547L131.734 3.98438H123.016L122.828 5.90625ZM122.406 10.5H131.125L131.312 8.71875H122.594L122.406 10.5Z"
          fill="white"
        />
        <path
          d="M147.109 7.125L147.344 4.3125H154.469L155.641 0.9375H166.141L164.969 4.3125H186.484L184.094 29.0625H173.641L175.703 7.125H163.984L156.344 29.0625H145.891L153.484 7.125H147.109Z"
          fill="white"
        />
        <path d="M193.469 18.5625L193.703 15.75H234.016L233.781 18.5625H193.469Z" fill="white" />
        <path
          d="M242.969 4.5L243.203 1.6875H257.828L257.594 4.5H242.969ZM257.688 29.0625L271.891 3.75H283.891L269.688 29.0625H257.688ZM242.031 14.625L242.266 11.8125H256.891L256.656 14.625H242.031Z"
          fill="white"
        />
        <path
          d="M293.078 9.375L293.312 6.5625H328L327.766 9.375H315.578L314.078 26.25H328.188L327.953 29.0625H289.188L289.422 26.25H303.625L305.078 9.375H293.078Z"
          fill="white"
        />
        <path
          d="M365.688 18.5625L368.406 4.45312H338.312L338.547 1.64062H379.422L376.188 18.5625H365.688ZM345.625 29.0625L352.047 7.92188H362.547L356.125 29.0625H345.625Z"
          fill="white"
        />
        <path
          d="M386.078 7.3125L386.312 4.5H391.75L392.031 0.9375H401.031L400.703 4.5H412.281L413.078 0.9375H423.578L422.781 4.5H427L426.766 7.3125H422.172L417.438 29.0625H406.938L411.672 7.3125H400.469L399.625 17.25H390.672L391.516 7.3125H386.078Z"
          fill="white"
        />
        <path d="M433.469 18.5625L433.703 15.75H474.016L473.781 18.5625H433.469Z" fill="white" />
        <path
          d="M481.188 29.0625L483.625 0.9375H494.125L493.375 9.70312L521.875 7.3125L521.641 10.125L493.094 12.5156L491.875 26.25H520.188L519.953 29.0625H481.188ZM523.656 5.625H521.594L522.016 0.9375H524.078L523.656 5.625ZM520.094 5.625H518.031L518.453 0.9375H520.516L520.094 5.625Z"
          fill="white"
        />
        <path
          d="M528.25 29.0625L555.953 4.5H530.594L530.828 1.6875H571.141L555.719 15.3281L568.188 29.0625H556.938L549.484 20.8594L540.25 29.0625H528.25Z"
          fill="white"
        />
      </svg> */}
    </div>
  )
}

export { SVGServiceName }
