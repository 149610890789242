import { BackTo } from '@component/BackTo'
import { LoadingHander } from '@component/LoadingHandle'
import { useOpenLogin } from '@component/LoginForm'
import { SocialQuery, useMeQuery, useSocialLoginMutation, useSocialQuery } from '@generated/graphql'
import { LocalStorage } from '@util/localStorage'
import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { StringParam, useQueryParams } from 'use-query-params'

const Login = () => {
  const login = useOpenLogin()

  // useEffect(() => {
  //   if (!me) {
  //     login()
  //   }
  // }, [login, me])
  return (
    <div className="btn btn-sm" onClick={login}>
      ログインしてください
    </div>
  )
}
const SocialLogin = () => {
  const [{ request_id, site_token }] = useQueryParams({
    request_id: StringParam,
    site_token: StringParam,
  })
  const {
    data: { _social: social } = {},
    error,
    loading,
  } = useSocialQuery({
    variables: {
      site_token: site_token ?? '',
    },
  })
  const { data: { _me: me } = {}, loading: loginLoading } = useMeQuery()

  if (!site_token || !request_id) {
    return <div className="container mx-auto my-4">起動パラメータが不足しています。</div>
  }
  if (loading || loginLoading) {
    return <LoadingHander />
  }
  if (!social) {
    return <div className="container mx-auto my-4">起動パラメータで連携先が見つかりません。</div>
  }
  return (
    <div className="container mx-auto my-4">
      <div className="text-xl my-2">データ連携</div>

      {me ? (
        <ConfirmForm site={social} request_id={request_id} site_token={site_token} />
      ) : (
        <div>
          <div className="my-2">{social.name}と予約情報の連携を行います。</div>
          <Login />
        </div>
      )}
    </div>
  )
}

const ConfirmForm = ({
  site,
  request_id,
  site_token,
}: {
  site: SocialQuery['_social']
  request_id: string
  site_token: string
}) => {
  const { data: { _me: me } = {}, loading: loginLoading } = useMeQuery()

  const loginForm = useOpenLogin()
  const { data: { _social } = {} } = useSocialQuery({ variables: { site_token } })
  const [social] = useSocialLoginMutation()
  const [backUrl, setBackUrl] = useState('')
  const login = useCallback(async () => {
    const { data } = await social({ variables: { input: { request_id, site_token } } })
    if (data?._socialLogin) {
      toast.success('連携に成功しました')
      setBackUrl(data._socialLogin.auth_redirect_url)
    }
  }, [request_id, social, site_token])

  if (!_social) {
    return null
  }
  return (
    <div>
      {!backUrl ? (
        <>
          <div className="my-2">
            {me?.sei} {me?.mei}様<br /> {site?.name}と予約情報の連携を行います。よろしいですか
          </div>
          <div className="flex gap-2">
            <button type="button" className="btn btn-warning" onClick={login}>
              連携を許可
            </button>
            <button type="button" className="btn btn-primary" onClick={loginForm}>
              別のアカウントでログインする
            </button>
          </div>
        </>
      ) : (
        <>
          <Link to={'/'} className="btn btn-success">
            シェアオフィス予約を進める
          </Link>
        </>
      )}
    </div>
  )
}
export { SocialLogin }
