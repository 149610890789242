import { PaymentType, useAdminAllUsersQuery, usePlanQuery, useReserveMutation } from '@generated/graphql'

import { Controller, useForm } from 'react-hook-form'

import { toast } from 'react-toastify'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { ErrorMsg } from '@component/ErrorMsg'

import dayjs from 'dayjs'
import { DevTool } from '@hookform/devtools'
import { parseUsers, UserSelect } from './UserSelect'

const schema = z.object({
  start_at: z.date(),
  end_at: z.date(),
  plan_id: z.string().nonempty(),
  memo: z.string().nullish(),
  user_id: z.string().nonempty(),
  is_forstop: z.boolean(),
})

const recoilCreateReserve = atom<{
  open: boolean
  onClose?: () => void
}>({
  key: 'recoilCreateReserve',
  default: {
    open: false,
  },
})

const useOpenCreateForm = () => {
  const setRecoil = useSetRecoilState(recoilCreateReserve)
  return (onClose?: () => void) => {
    setRecoil({
      open: true,
      onClose,
    })
  }
}

type FieldInput = z.infer<typeof schema>
const CreateReserveForm = ({ user_id }: { user_id?: string }) => {
  const {
    register,
    handleSubmit,
    control,
    reset: resetForm,
    formState: { errors },
  } = useForm<FieldInput>({
    resolver: zodResolver(schema),
    defaultValues: { user_id, start_at: dayjs().toDate(), end_at: dayjs().toDate() },
  })

  const recoil = useRecoilValue(recoilCreateReserve)
  const reset = useResetRecoilState(recoilCreateReserve)
  const { data: { usersPermissionsUsers: users } = {} } = useAdminAllUsersQuery({ fetchPolicy: 'network-only' })
  const { data: { _Plans: plans } = {} } = usePlanQuery({ fetchPolicy: 'network-only' })
  const [createReserve] = useReserveMutation()

  const closeMe = async () => {
    recoil.onClose?.()
    resetForm()
    reset()
  }

  const onSubmit = handleSubmit(async (form) => {
    if (window.confirm('予約を追加します。よろしいですか？')) {
      try {
        const { data } = await createReserve({
          variables: {
            input: {
              start_at: dayjs(form.start_at).toDate(),
              end_at: dayjs(form.end_at).toDate(),
              plan_id: form.plan_id,
              payment_type: PaymentType.Invoice,
              user_id: form.user_id,
              is_forstop: form.is_forstop,
            },
          },
        })
        if (data?._createReserve) {
          toast.success('追加しました')

          closeMe()
        } else {
          toast.error(`追加に失敗しました`)
        }
      } catch (errors) {
        toast.error(`追加に失敗しました ${errors}`)
      }
    }
  })

  return (
    <>
      <input type="checkbox" checked={recoil.open} readOnly className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle" onClick={closeMe}>
        <div className="modal-box p-3" onClick={(e) => e.stopPropagation()}>
          <div className="p-4 rounded-lg">
            <div className="text-xl my-2">新規予約追加</div>
            <form onSubmit={onSubmit}>
              <div className="form-control my-4">
                <label className="label">
                  <span className="label-text">プラン</span>
                </label>

                <select className="select select-bordered w-full" {...register('plan_id')}>
                  <option value="">プランを選択</option>
                  {plans
                    ?.filter((item) => !item.is_only_top)
                    .map((cat) => {
                      return cat.plans?.map((plan) => {
                        return (
                          <option value={plan.plan.id} key={plan.plan.id}>
                            {cat.name} - {plan.plan.name}
                          </option>
                        )
                      })
                    })}
                </select>
                <ErrorMsg error={errors.plan_id} />
              </div>
              <div className="form-control w-full my-4">
                <label className="label">
                  <span className="label-text">ユーザー</span>
                </label>

                <Controller
                  name="user_id"
                  control={control}
                  render={(props) => (
                    <UserSelect
                      users={parseUsers(users)}
                      className="btn btn-primary btn-sm"
                      userId={props.field.value}
                      onUserSelect={props.field.onChange}
                    />
                  )}
                />
                <ErrorMsg error={errors.user_id} />
              </div>

              <div className="flex gap-2 justify-evenly">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">開始</span>
                  </label>
                  <Controller
                    name="start_at"
                    control={control}
                    render={(props) => (
                      <input
                        type="datetime-local"
                        {...register('start_at', { valueAsDate: true })}
                        value={dayjs(props.field.value).format('YYYY-MM-DD HH:mm')}
                        className="input input-bordered"
                      />
                    )}
                  />
                  <ErrorMsg error={errors.start_at} />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">終了</span>
                  </label>
                  <Controller
                    name="end_at"
                    control={control}
                    render={(props) => (
                      <input
                        type="datetime-local"
                        {...register('end_at', { valueAsDate: true })}
                        className="input input-bordered"
                        value={dayjs(props.field.value).format('YYYY-MM-DD HH:mm')}
                      />
                    )}
                  />
                  <ErrorMsg error={errors.end_at} />
                </div>
              </div>
              <div className="form-control">
                <div className="flex items-center my-4">
                  <input type="checkbox" {...register('is_forstop')} className="checkbox" />
                  <div className="mx-4">予約停止用</div>
                </div>
              </div>

              {/* <div className="form-control">
                <label className="label">
                  <span className="label-text">メモ</span>
                </label>
                <textarea {...register('memo')} className="textarea textarea-bordered" />
                <ErrorMsg error={errors.memo} />
              </div> */}
              <span className="text-gray-500">※請求書による決済で登録されます</span>
              <input type="submit" className={`btn btn-warning my-4 w-full`} value="保存" />
              <div className={`btn my-4 w-full`} onClick={closeMe}>
                キャンセル
              </div>
            </form>
          </div>
        </div>
        <DevTool control={control} />
      </div>
    </>
  )
}
export { CreateReserveForm, useOpenCreateForm }
