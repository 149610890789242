import { ReserveStatus } from '@generated/graphql'
import dayjs from 'dayjs'

const formatYMDHM = (date: string) => {
  return dayjs(date).format('YYYY/MM/DD HH:mm')
}
const formatHM = (date: string) => {
  return dayjs(date).format('HH:mm')
}
const formatYMD = (date: string) => {
  return dayjs(date).format('YYYY/MM/DD')
}
/**
 * ReserveStatus に合わせた日本語表記
 */
const ReserveStatusLabel = {
  reserved: '予約済み',
  charged: '請求済み',
  used: '来店済み',
  settled: '決済済み',
  canceled: 'キャンセル',
}
const PaymentTypeLabel = {
  invoice: '請求書',
  card: 'クレジットカード',
  coupon: 'クーポン',
}

export { formatYMD, formatHM, formatYMDHM, ReserveStatusLabel, PaymentTypeLabel }
