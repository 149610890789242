import {
  CouponDocument,
  PaymentType,
  Unit,
  useMeQuery,
  usePlanQuery,
  useReserveMutation,
  useReservesQuery,
} from '@generated/graphql'
import { ENV, UnitNames } from '@util/env'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import ReactDatePicker from 'react-datepicker'
import dayjs from 'dayjs'

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
import { toast } from 'react-toastify'

import { CardEntry, checkId } from '@view/component/CardEntry'
import { getCardToken } from '@util/epsilon'
import { useRecoilState } from 'recoil'
import { ReserveData } from '@util/recoil'
import { formatYMD, formatYMDHM, formatHM } from '@util/format'
import { ErrorMsg } from '@component/ErrorMsg'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { VisitView } from '@view/mypage/visits'
import { TimeBar } from '@component/TimeBar'
import { client } from '../../../index'

const timeFilter = (
  time: Date,
  start: number,
  end: number,
  filter?: { start_at: Date; unit_amount: number; unit: any },
) => {
  const targetTime = time.getHours() * 100 + time.getMinutes()

  if (targetTime === end) {
    return ''
  }

  if (filter) {
    if (dayjs(filter.start_at).diff(time, filter.unit) % filter.unit_amount !== 0) return 'hidden'
  }

  return targetTime >= start && targetTime <= end ? '' : 'hidden'
}

const schema = z
  .object({
    start_at: z.date(),
    end_at: z.date(),
    payment_type: z.nativeEnum(PaymentType).nullish(),
    invoice_address: z.string().max(60).nullish(),
    purpose: z.string().max(60).nullish(),
    is_forstop: z.boolean().nullish(),
    user_id: z.string().nullish(),
    coupon_code: z.string().nullish(),
  })
  .refine(
    (data) => {
      if (data.payment_type === PaymentType.Coupon) {
        return Boolean(data.coupon_code)
      } else {
        return true
      }
    },
    { message: 'クーポンコードを入力してください。', path: ['coupon_code'] },
  )
type fields = z.infer<typeof schema>

const ReserveDetailView = () => {
  const [reserving, setReserving] = useState(false)
  const [reserveInfo] = useRecoilState(ReserveData)

  const { id: plan_id = '', visit_id } = useParams<{ id: string; visit_id?: string }>()
  const { data: { _me } = {} } = useMeQuery({ fetchPolicy: 'network-only' })

  const visit = useMemo(() => {
    return _me?.reserves
      ?.map((i) => i.visits)
      .flat()
      .find((i) => i?.id === visit_id)
  }, [_me, visit_id])
  const { data: { _Plans: categories, _holidays } = {} } = usePlanQuery()
  const plan = useMemo(() => {
    if (!categories) {
      return null
    }

    for (const c of categories) {
      const plan = c.plans?.find(({ plan }) => plan.id === plan_id)
      if (plan) {
        return plan.plan
      }
    }
  }, [categories, plan_id])

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<fields>({
    resolver: zodResolver(schema),
  })

  const { data } = useReservesQuery({
    variables: { plan_id },
    skip: plan_id === undefined,
    fetchPolicy: 'network-only',
  })

  const navigate = useNavigate()
  const [reserveMutaion] = useReserveMutation()
  const [kiyaku, setKiyaku] = useState(false)

  const [start_at, end_at, payment_type, invoice_address, purpose, is_forstop, user_id, coupon_code] = watch([
    'start_at',
    'end_at',
    'payment_type',
    'invoice_address',
    'purpose',
    'is_forstop',
    'user_id',
    'coupon_code',
  ])
  const [selectDate, setSelectDate] = useState<Date>(null!)
  const modalRef = useRef<HTMLInputElement>(null!)

  const todaysReserves = useMemo(() => {
    if (!selectDate) {
      return []
    }
    const day = dayjs(selectDate)
    const reserves = data?._reserves?.filter((r) => day.isSame(r.start_at, 'day'))

    return reserves || []
  }, [selectDate, data?._reserves])

  const reservedTimes = useCallback(
    (beforeMinute = 0) => {
      const start_at = dayjs(selectDate).startOf('day').hour(9)
      const end_at = dayjs(selectDate).startOf('day').hour(18)
      let loops = 0
      const maxcount = data?._planItemCount ?? 0
      const times = []

      while (true) {
        const tmp = start_at.add(15 * loops, 'minute')

        if (tmp.isAfter(end_at)) {
          break
        }

        const stop = _holidays?.some((h) => {
          return h.is_stop_using && (tmp.isAfter(h.start_at) || tmp.isSame(h.start_at)) && tmp.isBefore(h.end_at)
        })
        if (stop) {
          times.push(tmp.toDate())
          loops++
          continue
        }
        const reserved =
          todaysReserves
            .filter((r) => {
              const targetTime = dayjs(r.start_at).add(-1 * beforeMinute, 'minute')
              return (tmp.isAfter(targetTime) || tmp.isSame(targetTime)) && tmp.isBefore(r.end_at)
            })
            .reduce((pre, curr) => {
              return pre + (curr.is_forstop ? maxcount : 1)
            }, 0) ?? 0

        if (reserved >= maxcount) {
          times.push(tmp.toDate())
        }
        loops++
      }
      return times
    },
    [todaysReserves, data?._planItemCount, selectDate, _holidays],
  )

  const price = useMemo(() => {
    if (!end_at) {
      return
    }
    if (plan?.unit === Unit.Minutes) {
      const diff = dayjs(end_at).add(-1, 'second').diff(start_at, 'minute')
      const p = Math.floor(diff / plan.unit_amount + 1) * plan.unit_price
      return plan.limit_price ? Math.min(p, plan.limit_price) : p
    } else {
      return plan?.unit_price
    }
  }, [start_at, end_at, plan])

  const photos = useMemo(() => {
    return plan?.items?.[0].item.files?.filter(({ file }) => file?.url)
  }, [plan?.items])

  /**
   * 予約確認ダイアログを出す前のバリデーション
   */
  const confirm = useCallback(
    async (e: fields) => {
      if (plan && [Unit.Month, Unit.Year].includes(plan.unit)) {
        if (purpose) {
          clearErrors('purpose')
        } else {
          setError('purpose', { message: '利用目的を入力してください' })
          return
        }
      }

      if (e.coupon_code) {
        const { data } = await client.query({ query: CouponDocument, variables: { coupon_code: e.coupon_code } })
        if (!data?._coupon?.id) {
          setError('coupon_code', { message: 'クーポンコードが存在しません' })
          return
        }
      }
      modalRef.current.checked = true
    },
    [clearErrors, plan, purpose, setError],
  )

  /**
   * 予約実行
   */
  const submit = useCallback(async () => {
    if (reserving) {
      return
    }

    try {
      setReserving(true)
      if (plan) {
        const token = await getCardToken(reserveInfo.card)

        await reserveMutaion({
          variables: {
            input: {
              start_at: start_at,
              end_at: end_at,
              plan_id: plan.id,
              payment_type: payment_type ?? PaymentType.Invoice,
              cardToken: token?.token,
              invoice_address,
              parent_visit_id: Number(visit_id),
              purpose,
              is_forstop: is_forstop ?? false,
              user_id,
              coupon_code: coupon_code,
            },
          },
        })

        toast.success('予約が完了しました')

        navigate('/mypage')
      }
    } catch (e) {
      toast.error(`${e}`)
    } finally {
      setReserving(false)
    }
  }, [
    coupon_code,
    end_at,
    invoice_address,
    is_forstop,
    navigate,
    payment_type,
    plan,
    purpose,
    reserveInfo.card,
    reserveMutaion,
    reserving,
    start_at,
    user_id,
    visit_id,
  ])

  const clickDay = useCallback(
    (e: any) => {
      setSelectDate(e)
      if (plan?.unit !== Unit.Minutes) {
        const day = dayjs(e)
        const start = day.hour(9).minute(0).startOf('minute')
        setValue('start_at', start.toDate())
        setValue(
          'end_at',
          start
            .hour(18)
            .minute(0)
            .add(plan?.unit_amount ?? 1, plan?.unit ?? 'day')
            .add(-1, 'day')
            .toDate(),
        )
      } else {
        reset({ start_at: undefined, end_at: undefined })
      }
    },
    [plan, reset, setValue],
  )
  useEffect(() => {
    if (visit) {
      clickDay(dayjs(visit.start_at).toDate())
    }
  }, [visit, clickDay])

  if (!plan) {
    return null
  }

  return (
    <div className="container mx-auto px-2">
      <div className="lg:flex gap-2 justify-between">
        <div className="p-4 w-full">
          <form onSubmit={handleSubmit(confirm)}>
            <div className="my-4 text-neutral flex justify-between items-center">
              <div>
                <div className="text-2xl font-bold">{plan.name}</div>
                <div className="my-2">
                  {plan.detail?.split(/\n/).map((txt, i) => {
                    return <div key={i}>{txt}</div>
                  })}
                </div>
                <div className="my-2">
                  {plan.unit_amount}
                  {UnitNames[plan.unit]} {plan.unit_price?.toLocaleString()}円
                  {plan.limit_price && <div>1日最大 {plan.limit_price.toLocaleString()}円</div>}
                </div>
              </div>
            </div>

            {plan.is_need_parent_visit && !visit_id ? (
              <div className="w-full">
                <div className="py-2 font-bold">ご来店予定</div>
                <VisitView forReserve />
              </div>
            ) : (
              <>
                <div className="lg:flex gap-4">
                  <div className="text-center">
                    <div>ご利用日</div>
                    <ReactDatePicker
                      selected={visit && dayjs(visit.start_at).toDate()}
                      minDate={visit ? dayjs(visit.start_at).toDate() : dayjs().add(plan.deadline_days, 'day').toDate()}
                      maxDate={visit ? dayjs(visit.start_at).toDate() : dayjs().add(90, 'day').toDate()}
                      onChange={(e) => {
                        if (!visit) {
                          clickDay(e)
                        }
                      }}
                      inline
                    />
                  </div>
                  {plan.unit === Unit.Minutes && (
                    <div className="text-center">
                      {selectDate && (
                        <TimeBar
                          start={visit ? dayjs(visit.start_at) : dayjs(selectDate).hour(9).startOf('hour')}
                          end={visit ? dayjs(visit.end_at) : dayjs(selectDate).hour(18).startOf('hour')}
                          interval={15}
                          unit={plan.unit_amount}
                          reserves={reservedTimes(0).map((i) => dayjs(i))}
                          onSelectFrom={(time) => {
                            reset({
                              start_at: time.toDate(),
                              end_at: undefined,
                            })
                          }}
                          onSelectTo={(time) => {
                            setValue('end_at', time.toDate())
                          }}
                          onClear={() => {
                            reset({ start_at: undefined, end_at: undefined })
                          }}
                          from={start_at && dayjs(start_at)}
                          to={end_at && dayjs(end_at)}
                        />
                      )}
                    </div>
                  )}
                </div>

                <div className="my-4 font-bold">
                  <div>
                    {selectDate && !start_at && <div>{formatYMD(selectDate.toISOString())}</div>}
                    {start_at && <span>{formatYMDHM(start_at.toISOString())} から</span>}
                    {end_at && (
                      <span>
                        {' '}
                        {dayjs(start_at).isSame(end_at, 'day')
                          ? formatHM(end_at.toISOString())
                          : formatYMDHM(end_at.toISOString())}
                        まで
                      </span>
                    )}
                  </div>
                  {plan.unit_price > 0 && price && <div>{price.toLocaleString()} 円</div>}
                </div>

                {end_at && (
                  <div>
                    {plan?.unit_price > 0 && (
                      <div className="my-2">
                        <div className="my-2">お支払い方法</div>
                        <div>
                          <select className="select w-full max-w-xs" {...register('payment_type')}>
                            <option>選択してください</option>
                            <option value={'card'}>クレジットカード</option>
                            <option value={'invoice'}>請求書</option>
                            <option value={'coupon'}>クーポン</option>
                          </select>
                        </div>
                        {payment_type === 'card' && (
                          <div className="my-4">
                            {reserveInfo.card ? (
                              <div className="btn btn-success rounded-btn btn-wide">カード情報入力済み</div>
                            ) : (
                              <label htmlFor={checkId} className="btn btn-warning rounded-btn btn-wide">
                                クレジットカード情報入力
                              </label>
                            )}
                          </div>
                        )}
                        {payment_type === 'invoice' && (
                          <div className="form-control my-4">
                            <label className="label">
                              <span className="label-text">
                                請求書宛名 (指定がない場合はご登録氏名で作成いたします)
                              </span>
                            </label>
                            <input {...register('invoice_address')} className="input input-bordered" maxLength={60} />
                            <ErrorMsg error={errors.invoice_address} />
                          </div>
                        )}
                        {payment_type === 'coupon' && (
                          <div className="form-control my-4">
                            <label className="label">
                              <span className="label-text">クーポンコード</span>
                            </label>
                            <input {...register('coupon_code')} className="input input-bordered" maxLength={60} />
                            <ErrorMsg error={errors.coupon_code} />
                          </div>
                        )}
                      </div>
                    )}
                    {[Unit.Month, Unit.Year].includes(plan.unit) && (
                      <div>
                        <div className="form-control my-4">
                          <label className="label">
                            <span className="label-text">利用目的</span>
                          </label>
                          <input {...register('purpose')} className="input input-bordered" maxLength={60} />
                          <ErrorMsg error={errors.purpose} />
                        </div>
                      </div>
                    )}
                    <input type="hidden" {...register('start_at')} />
                    <input type="hidden" {...register('end_at')} />

                    <div className="flex items-center">
                      {plan.unit_price === 0 ||
                      payment_type === 'invoice' ||
                      (payment_type === 'card' && reserveInfo.card) ||
                      payment_type === 'coupon' ? (
                        <button className="btn btn-primary my-4">予約する</button>
                      ) : (
                        <button className="btn btn-primary my-4 disabled btn-disabled">予約する</button>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </form>
          <CardEntry />
        </div>
        {photos && (
          <div className="p-4 lg:p-10 w-full">
            <Carousel infiniteLoop showThumbs={false}>
              {photos.map(({ file }, idx) => {
                return (
                  <div key={idx}>
                    <img src={`${ENV.IMAGE_HOST_URL}${file?.url}`} alt="" />
                  </div>
                )
              })}
            </Carousel>
          </div>
        )}
      </div>
      <div>
        <Link className="btn" to="/">
          戻る
        </Link>
      </div>
      <div>
        <input type="checkbox" ref={modalRef} className="modal-toggle" />
        <div className="modal modal-bottom sm:modal-middle" onClick={() => (modalRef.current.checked = false)}>
          <div className="modal-box" onClick={(e) => e.stopPropagation()}>
            <h3 className="font-bold text-lg">予約しますか</h3>
            <div>
              <div className="my-2">{plan.name}</div>
              <div className="my-2">
                {dayjs(start_at).format('YYYY/MM/DD HH:mm')}から
                <br />
                {dayjs(end_at).format('YYYY/MM/DD HH:mm')}まで
              </div>
              {coupon_code && <div className="my-2">クーポンコード {coupon_code}</div>}
              {plan.unit_price !== 0 && (
                <div>
                  <div className={`my-2`}>
                    <span className={`${coupon_code && 'line-through text-gray-400'}`}>
                      {price?.toLocaleString()} 円
                    </span>
                    {coupon_code && <span className="mx-4">0 円</span>}
                  </div>
                </div>
              )}
            </div>
            <div className="m-8">
              <div className="form-control">
                <label className="label cursor-pointer justify-center">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-primary"
                    checked={kiyaku}
                    onChange={(e) => setKiyaku(e.target.checked)}
                  />
                  <span className="label-text text-lg px-2">
                    <a href="/service/kiyaku" className="link" target="_blank">
                      利用規約
                    </a>
                    に同意する
                  </span>
                </label>
              </div>
            </div>
            <div className="modal-action">
              <label
                htmlFor="my-modal-6"
                className={`btn btn-primary btn- ${reserving && 'loading'} ${!kiyaku && 'btn-disabled'}`}
                onClick={submit}
              >
                予約する
              </label>
              {!reserving && (
                <label htmlFor="my-modal-6" className="btn" onClick={() => (modalRef.current.checked = false)}>
                  戻る
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
      <DevTool control={control} />
    </div>
  )
}

export { ReserveDetailView, timeFilter }
