import { useAdminAuthSubscriptionMutation, useAdminWaitingSubscriptionsQuery } from '@generated/graphql'
import { formatYMD } from '@util/format'
import { toast } from 'react-toastify'

const AdminWaitingSubscriptionView = () => {
  const { data: { reserves } = {}, refetch } = useAdminWaitingSubscriptionsQuery({ fetchPolicy: 'network-only' })
  const [mutation] = useAdminAuthSubscriptionMutation()
  if (!reserves) {
    return null
  }
  const submit = async (id: string, auth: boolean, is_Company: boolean = false) => {
    if (!id) {
      return
    }
    if (window.confirm(auth ? '承認します' : '却下します')) {
      await mutation({ variables: { input: { id, auth, is_Company } } })
      toast.success('更新しました。')
      await refetch()
    }
  }
  return (
    <div>
      {reserves.data.length > 0 ? (
        <table className="table w-full table-compact">
          <thead>
            <tr>
              <td>購入日</td>
              <td>氏名</td>
              <td>プラン名</td>
              <td>利用目的</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {reserves.data.map((reserve) => {
              const plan = reserve.attributes?.plan?.data?.attributes
              const users = reserve.attributes?.users?.data.map((u) => u.attributes)
              if (!plan || !users) {
                return null
              }
              const reserve_id = reserve.id
              if (!reserve_id) {
                return null
              }
              return (
                <tr className="border-2 py-2" key={reserve_id}>
                  <td className="text-xs ">{formatYMD(reserve.attributes?.createdAt)}</td>
                  <td>
                    {users.map((user, i) => {
                      return (
                        <div key={i}>
                          {user?.sei} {user?.mei}
                          <br />
                          {user?.companyName ?? '会社名なし'}
                          <br />
                          {user?.email}
                        </div>
                      )
                    })}
                  </td>
                  <td>
                    {plan.name}
                    <br />
                    {formatYMD(reserve.attributes?.startAt)} 〜 {formatYMD(reserve.attributes?.endAt)}
                  </td>
                  <td>{reserve.attributes?.purpose}</td>
                  <td>
                    <div
                      className="btn btn-primary btn-sm mx-2"
                      onClick={() => {
                        submit(reserve_id, true)
                      }}
                    >
                      個人として承認
                    </div>
                    <div
                      className="btn btn-primary btn-sm mx-2"
                      onClick={() => {
                        submit(reserve_id, true, true)
                      }}
                    >
                      法人として承認
                    </div>
                    <div
                      className="btn btn-error btn-sm  mx-2"
                      onClick={() => {
                        submit(reserve_id, false)
                      }}
                    >
                      却下
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div>承認待ちなし</div>
      )}
    </div>
  )
}
export { AdminWaitingSubscriptionView }
